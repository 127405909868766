import React, { useState, useEffect } from 'react'
import meta from '../invest/config'
import { Pagination } from 'antd';

import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'antd';
import { trackClick } from '@/utils';
import { trackMap } from '../../config/trackMap'

function GovernanceDocuments(props) {
  const { t, i18n } = useTranslation();
  const pageSize = 10;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  const handlePageChange = function(page, pageSize) {
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    setStart(start);
    setEnd(end);
  }
  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    }
    const anchorName = props.location.state?.anchorName;
    if (!anchorName) {
      window.scrollTo({ top: 0 })
    }
  }, [i18n, props.location.state?.anchorName])

  const toPage = path => {
    trackClick({
      'toptab': trackMap[path]
    });
    props.history.push(path);
  }

  return (
    <div className="governanceDocuments-container">
      <div className="inner-container">
        <div className="breadcrumb-title">
          <Breadcrumb separator="-">
            <Breadcrumb.Item onClick={() => { toPage('/investor') }} className="breadcrumb-first-title">{t('header.menu.name.investor')}</Breadcrumb.Item>
            <Breadcrumb.Item>{t('investor.governance.title')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="g-content">
          {meta['governanceDocuments'].slice(start, end).map((v, i) => (
            <a href={v[`href_${i18n.language}`]} target="_blank" rel="noopener noreferrer" key={`${v.title}-${i}`}>
              <div className="g-content-item" key={`${v.title}-${i}`}>
                <div className="g-content-desc">
                  <div className="g-content-title">{t(v.title)}</div>
                  <div className="g-content-subtitle">{v.subtitle}</div>
                </div>
                <div className="item-download"></div>
              </div>
            </a>
          ))}
        </div>
        <div className="pagi">
          <Pagination hideOnSinglePage={true} defaultCurrent={1} pageSize={pageSize} total={meta.length} onChange={handlePageChange} />
        </div>
      </div>
    </div>
  )

}

export default GovernanceDocuments;;require('./index.less')