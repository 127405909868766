const trackMap = { // 埋点路由map
    '/': 'brief',
    '/business': 'business',
    '/employee': 'employee',
    '/investor': 'invest',
    '/contacts': 'topconcat',
    '/brief':'comprofile',
    '/news':'news',
    '/investor/governanceDocuments':'governanceDocuments',
    '/investor/pressRelease':'pressRelease',
    '/investor/brief':'comprofile',// 投资者页进入简介
}
const sectabMap = {
    'brief': 'comprofile', // 简介
    'vision': 'vision', // 愿景同上
    'milestone': 'milestone', // 里程碑
    'team': 'team', // 管理团队
    'culture': 'culture', // 企业文化
    'news': 'information', // 新闻动态
    'responsibility':'responsibility',// 社会责任
    'k-12Education': 'k12',
    'collegeEducation': 'college',
    'preSchoolEducation': 'preschool',
    'institutionalCustomers': 'agency',
    'associatedCompanies': 'venture',
    'relatedBrands': 'relate',
    'IRContacts': 'secconcat',
    'cooperation': 'cooperate', // 合作洽谈
    'talentDevelopment': 'development', // 人才发展
    'workEnvironment': 'environment', // 办公环境
    'employeeActivities': 'activities', // 员工活动
    'joinUs': 'joinus', // 加入我们
    'StockInformation': 'stockinfo', // 股价信息
    'CorporateGovernance': 'governance', // 公司管制
    'GovernanceDocuments': 'document', // 治理文件
    'FinancialReport': 'report',
    'Announcements': 'circulars',
    'PressRelease': 'release',
    'AnalystCoverage': 'analyst',
    'E-mailAlert': 'subscribe'
};
export {
    trackMap,
    sectabMap
}