import React from 'react';
import bannerImg from '../../img/contact-banner.png';
import Banner from '../../components/banner';

import {useTranslation} from 'react-i18next'

function TopConcat(){
    const {t} = useTranslation();
    return(
        <div className="concat-container">
            <Banner img={bannerImg} alt="联系我们"/>
            <section>
                <div className="c-item-wrap">
                    <div className="c-item">
                        <div className="c-item-title">{t('contact.investor.title')}</div>
                        <div className="c-item-subtitle">{t('contact.investor.name')}</div>
                        <div className="c-item-desc">{t('contact.investor.position')}<br/>{t('contact.investor.company')}</div>
                        <div className="c-item-desc c-item-adress">{t('contact.investor.address')}<br/>{t('contact.investor.tel')}<br/>{t('contact.investor.fax')}<br/>{t('contact.investor.email')}</div>
                    </div>
                    <div className="c-item">
                        <div className="c-item-title">{t('contact.media.title')}</div>
                        <div className="c-item-subtitle">{t('contact.media.name')}</div>
                        <div className="c-item-desc">{t('contact.media.position')}<br/>{t('contact.media.company')}</div>
                        <div className="c-item-desc c-item-adress">{t('contact.media.address')}<br/>{t('contact.media.tel')}<br/>{t('contact.media.fax')}<br/>{t('contact.media.email')}</div>
                    </div>
                </div>
            </section>
            <div className='c-advertiseMessage-wrap'>
                <div className='c-advertiseMessage-title'>{t('advertiseMessage.title')}</div>
                <div className='advertiseMessage-one'>
                    <div className='c-advertiseMessage-desc'>{t('advertiseMessage20240118.desc')}</div>
                    {t("advertiseMessage20240118.content",{returnObjects:true}).map((item,index)=>{
                        const info=item.info
                        return <div key={index} className='c-advertiseMessage-content'>
                            <div className='c-advertiseMessage-info' dangerouslySetInnerHTML={{__html:item.desc}}></div>
                            {(info||[]).map((child)=>{
                                return <div className='c-advertiseMessage-pra' dangerouslySetInnerHTML={{__html:child}}></div>
                            })}
                        </div>
                    })}
                </div>
                <div className='c-advertiseMessage-desc'>{t('advertiseMessage.desc')}</div>
                {t("advertiseMessage.content",{returnObjects:true}).map((item,index)=>{
                    const info=item.info
                    return <div key={index} className='c-advertiseMessage-content'>
                        <div className='c-advertiseMessage-info'>{item.desc}</div>
                        {(info||[]).map((child)=>{
                            return <p className='c-advertiseMessage-pra' dangerouslySetInnerHTML={{__html:child}}></p>
                        })}
                    </div>
                })}
            </div>
        </div>
    )
}
export default TopConcat;;require('./index.less')