import React from 'react';
import Header from '../header'
import Footer from '../footer'
import { Switch, Route, Redirect } from 'react-router-dom'
import {routes} from '../../routes'

function Layout() {
    return (
        <div>
            <Header></Header>
            <Switch>
                {routes.map(route => {
                    return <Route exact key={route.path} path={route.path} component={route.component} />
                })}
                <Redirect to="/404" />
            </Switch>
            <Footer/>
        </div>
    )
}
export default Layout;;require('./index.less')