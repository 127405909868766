import React, { useEffect } from 'react';
import bannerImg from '../../img/brief-banner.png';
import Banner from '../../components/banner';
import { Breadcrumb } from 'antd';
import { trackClick } from '@/utils';
import { trackMap } from '../../config/trackMap'
import { useTranslation } from 'react-i18next'

function Brief(props) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    }
    const anchorName = props.location.state?.anchorName;
    if (!anchorName) {
      window.scrollTo({ top: 0 })
    }
  }, [i18n, props.location.state?.anchorName])
  const toPage = path => {
    trackClick({
      'toptab': trackMap[path]
    });
    props.history.push(path);
  }
  return (
    <div className="brief-container">
      <div className="inner-container">
        <div className="breadcrumb-title">
          <Breadcrumb separator="-">
            <Breadcrumb.Item onClick={() => { toPage('/investor') }} className="breadcrumb-first-title">{t('header.menu.name.investor')}</Breadcrumb.Item>
            <Breadcrumb.Item>{t('home.brief.title')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="brief-container-main">
          <div className="brief-banner">
            <Banner img={bannerImg} alt="公司介绍" />
          </div>
          <section>
            <div className="brief-title">{t('brief.title')}</div>
            <div className="brief-content">
              <p>{t('brief.paragragh1')}</p>
              <p>{t('brief.paragragh2')}</p>
              <p>{t('brief.paragragh3')}</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
export default Brief;;require('./index.less')