import React, { useState, useEffect } from 'react';
import { Button, Tabs } from 'antd';
import { subMenu } from '../../config/menu';
import { trackClick } from '@/utils';
import { anchorTo } from '@/utils/common.js'
import SubNav from '../../components/subNav';
import Modal from '../../components/modal'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'


import meta from './config'
import { trackMap } from '../../config/trackMap'

console.log(meta,'meta=====')

const { TabPane } = Tabs;
const menu = subMenu['invest'];
const langSuffixMap = {
  'en': 'en-GB',
  'zh': 'zh-CN',
  'hk': 'zh-TW'
}
const iframeIdArr = ['iframe_stock', 'iframe_financialReport', 'iframe_announcements', 'iframe_email'];
const titleMap = {
  "firm": {
    'en': 'Firm',
    'zh': '机构',
    'hk': '機構'
  },
  "analyst": {
    'en': 'Analyst',
    'zh': '分析师',
    'hk': '分析師'
  },
  "email": {
    'en': 'E-Mail',
    'zh': '邮箱',
    'hk': '郵箱'
  }
}
function Invest(props) {
  const { t, i18n } = useTranslation();
  const langSuffix = langSuffixMap[i18n.language];
  const lang = i18n.language||'zh';
  const [fixed, setFixed] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [frameLoadingState, setFrameLoadingState] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);


  const handleClickPop = (data) => {
    setVisible(true);
    setModalInfo(data);
  }
  const handleCanclePop = () => {
    setVisible(false);
  }

  function changeFixed(bool) {
    setFixed(bool);
  }
  function toPage({ path, target }) {
    if (target !== '_blank') {
      props.history.push(path);
    } else {
      const url = `${window.location.origin}/#${path}`;
      window.open(url);
    }
    trackClick({
      'toptab': trackMap[path]
    });
  }
  function iframeLoaded(iframeId) {
    const iframe = document.getElementById(iframeId);
    if (iframe.attachEvent) { // 兼容ie
      iframe.attachEvent("onload", function() {
        window.EurolandToolIntegrationObject.set(iframeId);
        //iframe加载完成后你需要进行的操作
        setFrameLoadingState(iframeId);
      });
    } else {
      iframe.onload = function() {
        window.EurolandToolIntegrationObject.set(iframeId);
        //iframe加载完成后你需要进行的操作
        setFrameLoadingState(iframeId);

      };
    }
  }
  useEffect(() => {
    // 第三方工具嵌入及监听加载完成事件
    iframeIdArr.forEach(iframeId => {
      iframeLoaded(iframeId);
    })
    const anchorName = props.location.state?.anchorName;
    const timer = setTimeout(() => {
      setIsLoaded(true);
      if (anchorName) {
        const hrefName = anchorName.split("+")[0];
        anchorTo({ elementId: hrefName });
      } else {
        window.scrollTo({ top: 0 })
      }
    }, 500)
    return function cleanTimout() {
      clearTimeout(timer);
    }
  }, [props.location.state?.anchorName])

  useEffect(() => {
    const timer = setInterval(() => {
      setCount(count => count + 1)
    }, 1000)
    return function cleanInterval() {
      clearInterval(timer);
    }
  }, [])

  return (
    <div className="invest-container">
      <div className="i-banner">
        <div className="inner-container">
          <div className="i-banner-content">
            <div className="i-banner-title">{t('global.company.name')}</div>
            <div className="i-banner-desc">{t('investor.company.desc')}</div>
            <div className="i-banner-link"><Button ghost onClick={() => toPage({ path: '/investor/brief', target: '_blank' })}>{t('global.more')} &gt;</Button></div>
          </div>
        </div>
      </div>
      <SubNav menu={menu} changeFixed={changeFixed} isLoaded={isLoaded} frameLoadingState={frameLoadingState} canSwitch={lang === 'en'} />
      <div style={{ height: `${fixed ? `58px` : '0px'}` }}></div>
      <section id="StockInformation">
        <div className="inner-container">
          <div className="title">{t('investor.stock.title')}</div>
          <iframe title="iframe_stock" allowtransparency="" frameBorder="0" id="iframe_stock" scrolling="no" src={`https://asia.tools.euroland.com/tools/ticker/html/?companycode=cn-kool&v=r2023&lang=${langSuffix}`} />
        </div>
      </section>
      <section id="CorporateGovernance">
        <div className="inner-container">
          <div className="title">{t('investor.corporate.title')}</div>
          <Tabs className="c-tabs">
            <TabPane tab={`${t('investor.chairman.title')}`} key="1">
              <div className="c-gallery-container">
                {meta.directors.map(item => {
                  return (
                    <div className="c-gallery-item" key={item.name} onClick={() => { handleClickPop(item) }}>
                      <div className="c-gallery-img">
                        <img src={item.img} alt="" />
                      </div>
                      <div className={classnames("c-gallery-figure", { "en": lang === 'en' })}>
                        <div className="c-figure-title">{t(`global.${item.name}.name`)}</div>
                        <div className="c-figure-subtitle">{t(`global.${item.name}.position`)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </TabPane>
            <TabPane tab={`${t('investor.audit.title')}`} key="2">
              <div className="c-gallery-container">
                {meta.auditCommittee.map(item => {
                  return (
                    <div className="c-gallery-item" key={item.name} onClick={() => { handleClickPop(item) }}>
                      <div className="c-gallery-img">
                        <img src={item.img} alt="" />
                      </div>
                      <div className={classnames("c-gallery-figure", { "en": lang === 'en' })}>
                        <div className="c-figure-title">{t(`global.${item.name}.name`)}</div>
                        <div className="c-figure-subtitle">{t(`investor.audit.${item.name}.position`)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </TabPane>
            <TabPane tab={`${t('investor.remuneration.title')}`} key="3">
              <div className="c-gallery-container">
                {meta.remunerationCommittee.map(item => {
                  return (
                    <div className="c-gallery-item" key={item.name} onClick={() => { handleClickPop(item) }}>
                      <div className="c-gallery-img">
                        <img src={item.img} alt="" />
                      </div>
                      <div className={classnames("c-gallery-figure", { "en": lang === 'en' })}>
                        <div className="c-figure-title">{t(`global.${item.name}.name`)}</div>
                        <div className="c-figure-subtitle">{t(`investor.remuneration.${item.name}.position`)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </TabPane>
            <TabPane tab={`${t('investor.nomination.title')}`} key="4">
              <div className="c-gallery-container">
                {meta.nominationCommittee.map(item => {
                  return (
                    <div className="c-gallery-item" key={item.name} onClick={() => { handleClickPop(item) }}>
                      <div className="c-gallery-img">
                        <img src={item.img} alt="" />
                      </div>
                      <div className={classnames("c-gallery-figure", { "en": lang === 'en' })}>
                        <div className="c-figure-title">{t(`global.${item.name}.name`)}</div>
                        <div className="c-figure-subtitle">{t(`investor.nomination.${item.name}.position`)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </section>
      <section id="GovernanceDocuments">
        <div className="inner-container">
          <div className="title">{t('investor.governance.title')}</div>
          <div className="g-content">
            {meta.governanceDocuments.slice(0, 6).map((meta, index) => (
              <a href={meta[`href_${i18n.language}`]} target="_blank" rel="noopener noreferrer" key={`${meta.title}-${index}`}>
                <div className="g-content-item">
                  <div className="g-content-desc">
                    <div className="g-content-title">{t(meta.title)}</div>
                    <div className="g-content-subtitle">{meta.subtitle}</div>
                  </div>
                  <div className="item-download"></div>
                </div>
              </a>

            ))}
          </div>
          <div className="g-more">
            <Button ghost onClick={() => toPage({ path: '/investor/governanceDocuments', target: '_blank' })}>{t('global.all')} &gt;</Button>
          </div>
        </div>
      </section>
      <section id="FinancialReport">
        <div className="inner-container">
          <div className="title">{t('investor.financial.title')} <span className="subtitle">{t('investor.financial.subtitle')}</span></div>
          <iframe title="iframe_financialReport" allowtransparency="" frameBorder="0" id="iframe_financialReport" scrolling="no" src={`https://asia.tools.euroland.com/tools/pressreleases/?companycode=cn-kool&v=reports_r2023&lang=${langSuffix}`} />
        </div>
      </section>
      <section id="Announcements">
        <div className="inner-container">
          <div className="title">{t('investor.announcements.title')} <span className="subtitle">{t('investor.announcements.subtitle')}</span></div>
          <iframe title="iframe_announcements" allowtransparency="" frameBorder="0" id="iframe_announcements" scrolling="no" src={`https://asia.tools.euroland.com/tools/pressreleases/?companycode=cn-kool&v=r2023&lang=${langSuffix}`} />
        </div>
      </section>
      <section id="PressRelease">
        <div className="inner-container">
          <div className="title">{t('investor.pressrelease.title')}</div>
          <div className="p-content">
            {meta.pressRelease.slice(0, 6).map((meta, index) => (
              <a href={meta[`href_${i18n.language}`]} target="_blank" rel="noopener noreferrer" key={`${meta.title}-${index}`}>
                <div className="p-list-item">
                  <div className="p-item-desc">
                    <div className="p-item-title">{t(meta.title)}</div>
                    <div className="p-item-subtitle">
                      <div className="p-subtitle-date">{meta.time}</div>
                    </div>
                  </div>
                  <div className="item-download"></div>
                </div>
              </a>
            ))}
          </div>
          <div className="g-more">
            <Button ghost onClick={() => toPage({ path: '/investor/pressRelease', target: '_blank' })}>{t('global.all')} &gt;</Button>
          </div>
        </div>
      </section>
      <section id="AnalystCoverage">
        <div className="inner-container">
          <div className="title">{t('investor.analyst.title')}</div>
          <div className="analystCoverage-container">
            <div className="inner-container">
              <div className="content">
                <table className="analyst_table">
                  <thead>
                    <tr>
                      <th><span>{titleMap['firm'][lang]}</span></th>
                      <th><span>{titleMap['analyst'][lang]}</span></th>
                      <th><span>{titleMap['email'][lang]}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      meta['analysts'][lang].map(data=>{
                          return <tr key={data.analyst}>
                              <td>{data.firm}</td>
                              <td>{data.analyst}</td>
                              <td>{data.email}</td>
                          </tr>
                      })
                    }
                  </tbody>
   
                  {/* <tbody>
                    <tr>
                      <td>BOCI Research Limited11111</td>
                      <td>Tina Li</td>
                      <td>tina.li@bocigroup.com</td>
                    </tr>
                    <tr>
                      <td>CaiTong Securities</td>
                      <td>Yuebo Li</td>
                      <td>liyb@ctsec.com</td>
                    </tr>
                    <tr>
                      <td>CaiTong Securities</td>
                      <td>Yang Liu</td>
                      <td>liuyang01@ctsec.com</td>
                    </tr>
                    <tr>
                      <td>ChangJiang Securities</td>
                      <td>Gang Zhao</td>
                      <td>zhaogang@cjsc.com.cn</td>
                    </tr>
                    <tr>
                      <td>China Galaxy International</td>
                      <td>Lei Yang</td>
                      <td>yanglei@chinastock.com.hk</td>
                    </tr>
                    <tr>
                      <td>China International Capital Corporation</td>
                      <td>Liping Zhao</td>
                      <td>liping.zhao@cicc.com.cn</td>
                    </tr>
                    <tr>
                      <td>China Merchants Securities</td>
                      <td>Xiaoyan Xie</td>
                      <td>xiexiaoyan1@cmschina.com.cn</td>
                    </tr>
                    <tr>
                      <td>China Merchants Securities</td>
                      <td>Jia Gu</td>
                      <td>gujia@cmschina.com.cn</td>
                    </tr>
                    <tr>
                      <td>China Merchants Securities (HK)</td>
                      <td>Tommy Wong</td>
                      <td>tommywong@cmschina.com.hk</td>
                    </tr>
                    <tr>
                      <td>China Renaissance Securities (HK)</td>
                      <td>Yiwen  Zhang</td>
                      <td>yiwenzhang@chinarenaissance.com</td>
                    </tr>
                    <tr>
                      <td>China Securities</td>
                      <td>Le Ye</td>
                      <td>Yele@csc.com.cn</td>
                    </tr>
                    <tr>
                      <td>CINDA SECURITIES</td>
                      <td>Cuiting Feng</td>
                      <td>fengcuiting@cindasc.com</td>
                    </tr>
                    <tr>
                      <td>Citi Research</td>
                      <td>Michelle Fang</td>
                      <td>Michelle.fang@citi.com</td>
                    </tr>
                    <tr>
                      <td>Citic Securities</td>
                      <td>Mandy Jiang</td>
                      <td>jiangya@citics.com</td>
                    </tr>
                    <tr>
                      <td>Citic Securities</td>
                      <td>Chongguang Feng</td>
                      <td>fengchongguang@citics.com</td>
                    </tr>
                    <tr>
                      <td>Daiwa Capital Markets</td>
                      <td>Candis Chan</td>
                      <td>candis.chan@hk.daiwacm.com</td>
                    </tr>
                    <tr>
                      <td>Everbright Securities</td>
                      <td>Tianzi Fu</td>
                      <td>futz@ebscn.com</td>
                    </tr>
                    <tr>
                      <td>GF Securities</td>
                      <td>Shi Kuang</td>
                      <td>Kuangshi@gf.com.cn</td>
                    </tr>
                    <tr>
                      <td>GuoLian Securities</td>
                      <td>Mengyao Chen</td>
                      <td>cmy@glsc.com.cn</td>
                    </tr>
                    <tr>
                      <td>Guosen Securities</td>
                      <td>Guang Zeng</td>
                      <td>zengguang@guosen.com.cn</td>
                    </tr>
                    <tr>
                      <td>GuoTai JunAn</td>
                      <td>Ying Su</td>
                      <td>suying@gtjas.com</td>
                    </tr>
                    <tr>
                      <td>HaiTong Securities</td>
                      <td>Liting Wang</td>
                      <td>wanglt@htsec.com</td>
                    </tr>
                    <tr>
                      <td>HaiTong Securities</td>
                      <td>Yingzhi Xu</td>
                      <td>xyz11630@htsec.com</td>
                    </tr>
                    <tr>
                      <td>HuaAn Securities</td>
                      <td>Rong Jin</td>
                      <td>jinrong@hazq.com</td>
                    </tr>
                    <tr>
                      <td>HuaTai Securities</td>
                      <td>Lian Duan</td>
                      <td>duanlian@htsc.com</td>
                    </tr>
                    <tr>
                      <td>Kaiyuan Securities</td>
                      <td>Guangzhao Fang</td>
                      <td>fangguangzhao@kysec.cn</td>
                    </tr>
                    <tr>
                      <td>Northeast Securities</td>
                      <td>Yuxiang Song</td>
                      <td>songyx@nesc.cn</td>
                    </tr>
                    <tr>
                      <td>Orient Securities</td>
                      <td>WenQian Xiang</td>
                      <td>xiangwenqian@orientsec.com.cn</td>
                    </tr>
                    <tr>
                      <td>Sealand Securities</td>
                      <td>Lei Yao</td>
                      <td>yaol02@ghzq.com.cn</td>
                    </tr>
                    <tr>
                      <td>Sealand Securities</td>
                      <td>Ruiqiao Tan</td>
                      <td>tanrq@ghzq.com.cn</td>
                    </tr>
                    <tr>
                      <td>SOOCHOW Securities</td>
                      <td>Liangwei Zhang</td>
                      <td>zhanglw@dwzq.com.cn</td>
                    </tr>
                    <tr>
                      <td>SOOCHOW Securities</td>
                      <td>Liangjiu Zhou</td>
                      <td>zhoulj@dwzq.com.cn</td>
                    </tr>
                    <tr>
                      <td>SOOCHOW Securities (HK)</td>
                      <td>Ruibin Chen</td>
                      <td>chenrobin@dwzq.com.hk</td>
                    </tr>
                    <tr>
                      <td>Southwest Securities</td>
                      <td>Yan Liu</td>
                      <td>liuyan@swsc.com.cn</td>
                    </tr>
                    <tr>
                      <td>Southwest Securities</td>
                      <td>Xiangjie Wang</td>
                      <td>wxj@swsc.com.cn</td>
                    </tr>
                    <tr>
                      <td>SWS Research</td>
                      <td>Zhe Huang</td>
                      <td>huangzhe@swsresearch.com</td>
                    </tr>
                    <tr>
                      <td>TF Securities</td>
                      <td>Haiyang Sun</td>
                      <td>sunhaiyang@tfzq.com</td>
                    </tr>
                    <tr>
                      <td>ZheShang Securities</td>
                      <td>Li Ma</td>
                      <td>mali@stocke.com.cn</td>
                    </tr>
                    <tr>
                      <td>ZheShang Securities</td>
                      <td>TengXi Chen</td>
                      <td>chentengxi@stocke.com.cn</td>
                    </tr>
                    <tr>
                      <td>Zhongtai Securities</td>
                      <td>Xiaochen Han</td>
                      <td>hanxc@zts.com.cn</td>
                    </tr>
                  </tbody> */}

                </table>
                <div className="a-footer">
                  <div className="a-title">{t('investor.analyst.disclaimer')}</div>
                  <div className="a-desc">{t('investor.analyst.disclaimer.content')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="E-mailAlert">
        <div className="inner-container">
          <div className="title">{t('investor.email.title')}</div>
          <iframe title="iframe_email" allowtransparency="" frameBorder="0" id="iframe_email" scrolling="no" src={`https://asia.tools.euroland.com/tools/SubscriptionCentre2/?companycode=cn-kool&v=r2023&lang=${langSuffix}`} />
        </div>
      </section>
      <Modal info={modalInfo} visible={visible} onCancle={handleCanclePop}></Modal>
    </div>
  )
}
export default Invest;;require('./index.less')