var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _track = _interopRequireDefault(require("./track"));

/*
 * @Author: your name
 * @Date: 2020-08-10 15:32:02
 * @LastEditTime: 2020-12-29 10:51:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /track/src/index.js
 */
var _default = _track["default"];
exports["default"] = _default;