import React, { useEffect } from 'react';
import AnchorBar from '../../components/anchor'
import meta from './meta'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Breadcrumb } from 'antd';
import { trackClick } from '@/utils';
import { trackMap } from '../../config/trackMap'


function News(props) {
  function toLink(href) {
    window.open(href);
  }
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'hk' ? 'hk' : 'zh';
  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    }
    const anchorName = props.location.state?.anchorName;
    if (!anchorName) {
      window.scrollTo({ top: 0 })
    }
  }, [i18n, props.location.state?.anchorName])
  const toPage = path => {
    trackClick({
      'toptab': trackMap[path]
    });
    props.history.push(path);
  }
  return (
    <div className="news-container">
      <div className="breadcrumb-title">
        <div className="inner-container">
          <Breadcrumb separator="-">
            <Breadcrumb.Item onClick={() => { toPage('/') }} className="breadcrumb-first-title">{t('header.menu.name.home')}</Breadcrumb.Item>
            <Breadcrumb.Item>{t('home.news.title')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="inner-container">
        <div className="news-content">
          <div className="sidebar">
            <AnchorBar />
          </div>
          <div className="main">
            <div id="2024" className="news-part">
              <div className="title">2024</div>
              <div className="content">
                {meta['2024'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2023" className="news-part">
              <div className="title">2023</div>
              <div className="content">
                {meta['2023'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2022" className="news-part">
              <div className="title">2022</div>
              <div className="content">
                {meta['2022'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2021" className="news-part">
              <div className="title">2021</div>
              <div className="content">
                {meta['2021'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2020" className="news-part">
              <div className="title">2020</div>
              <div className="content">
                {meta['2020'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2019" className="news-part">
              <div className="title">2019</div>
              <div className="content">
                {meta['2019'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2018" className="news-part">
              <div className="title">2018</div>
              <div className="content">
                {meta['2018'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
            <div id="2017" className="news-part">
              <div className="title">2017</div>
              <div className="content">
                {meta['2017'].map(item => (
                  <div key={item[`title_${lang}`]} className="item_news" onClick={() => toLink(item.link)}>
                    <div className="news_title">{item[`title_${lang}`]}</div>
                    <div className="news_subtract">{item[`abstract_${lang}`]}</div>
                    <div className="news_time">{moment(item.time).format('YYYY/MM/DD')}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default News;;require('./index.less')