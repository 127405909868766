import React, { useState, useEffect } from 'react';
import { Button, Carousel, Divider, Typography } from 'antd';
import SubNav from '../../components/subNav'
import { useTranslation } from 'react-i18next'
import { trackClick } from '@/utils';
import meta from '../invest/config'
import { latest } from '../news/meta'
import { subMenu, subMenuEn } from '../../config/menu'
import Modal from '../../components/modal'
import { anchorTo } from '@/utils/common.js'
import classnames from 'classnames'
import briefImg from '../../img/index-brief.png'
import { trackMap } from '../../config/trackMap'
import BannerVideo from '../../components/banner-video'
import responsibility1 from './../../img/responsibility1.png'
import responsibility2 from './../../img/responsibility2.png'
import responsibility3 from './../../img/responsibility3.png'
import responsibility4 from './../../img/responsibility4.png'
import responsibility5 from './../../img/responsibility5.png'
import responsibility6 from './../../img/responsibility6.png'
import responsibility7 from './../../img/responsibility7.png'
import responsibility8 from './../../img/responsibility8.png'
import responsibility9 from './../../img/responsibility9.png'
import responsibility10 from './../../img/responsibility10.png'

const { Paragraph } = Typography
let menu = [];
// const decorates = [
//   "https://p3.dfzxvip.com/15364591.png",
//   "https://p3.dfzxvip.com/e4f3c324.png",
//   "https://p3.dfzxvip.com/41cea80b.png",
//   "https://p3.dfzxvip.com/82871c66.png",
//   "https://p3.dfzxvip.com/260c0880.png",
//   "https://p3.dfzxvip.com/a07197d0.png"];
function Index(props) {
  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    language: 'zh',
    controlBar: {
      playToggle: false, // 播放暂停按钮
      volumeMenuButton: true, // 音量控制
      fullscreenToggle: true, // 全屏控制
      volumeControl: false
    },
    poster: require('../../img/videoPoster.jpg'),
    // sources: [{
    //   src: '/path/to/video.mp4',
    //   type: 'video/mp4'
    // }]
  }

  // const videoRef=useRef(null)
  const { t, i18n } = useTranslation()
  const isEn = i18n.language === 'en';
  if (isEn) { // 英文不要新闻栏
    menu = [...subMenuEn['index']]
  } else {
    menu = subMenu['index'];
  }
  const [modalInfo, setModalInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  function changeFixed(bool) {
    setFixed(bool);
  }
  const handleClickPop = (data) => {
    setVisible(true);
    setModalInfo(data);
  }
  const handleCanclePop = () => {
    setVisible(false);
  }
  const toPage = path => {
    trackClick({
      'toptab': trackMap[path]
    });
    const url = `${window.location.origin}/#${path}`;
    window.open(url);
  }
  const toLink = url => {
    window.open(url);
  }

  useEffect(() => {
    const anchorName = props.location.state?.anchorName;
    const timer = setTimeout(() => {
      setIsLoaded(true);
      if (anchorName) {
        const hrefName = anchorName.split("+")[0];
        anchorTo({ elementId: hrefName });
      } else {
        window.scrollTo({ top: 0 })
      }
    }, 500)
    return function cleanTimout() {
      clearTimeout(timer);
    }
  }, [props.location.state?.anchorName])



  return (
    <div className="index-container">
      {/* <Carousel autoplay={false} speed={1500} autoplaySpeed={6000} className="banner-carousel-slick" dots={{ className: 'circle-dots' }}> */}
      {/* <div className="banner-container-first">
      </div> */}
      <BannerVideo options={videoJsOptions}></BannerVideo>
      {/* <div className="banner-container">
                    <div className="banner-title">
                        {isEn ? <div className={classnames("title-txt",{'en':isEn})}><span>Koolearn,</span><br/>Excellent Teacher of All Times</div> : <div className="title-txt">{t('home.banner.title')}</div>}
                    </div>
                </div> */}
      {/* </Carousel> */}
      <SubNav menu={menu} changeFixed={changeFixed} isLoaded={isLoaded} />
      <div style={{ height: `${fixed ? `58px` : '0px'}` }}></div>
      <section id="brief">
        <div className="inner-container">
          <div className="brief-content">
            <div className="brief-cover">
              <img src={briefImg} alt="" />
            </div>
            <div className={classnames("brief-txt", { "en": isEn })}>
              <div className="b-title">{t('home.brief.title')}</div>
              <div className="b-desc">{t("home.brief.desc")}</div>
              <div className="b-link"><Button ghost onClick={() => toPage('/brief')}>{t("global.more")} &gt;</Button></div>
            </div>
          </div>

        </div>
      </section>
      <section id="vision">
        <div className="inner-container">
          <div className="v-title">{t('home.vision.title')}</div>
          <div className='v-cont'>
            <div className="v-desc">
              <span className='v-icon icon1'></span>
              <div className={classnames("txt", { "en_txt": isEn })}>{t('home.vision.desc.paragraph.one')}</div></div>
            <div className="v-desc">
              <span className='v-icon icon2'></span>
              <div className={classnames("txt", { "en_txt": isEn })}>{t('home.vision.desc.paragraph.two')}</div></div>
            <div className="v-desc">
              <span className='v-icon icon3'></span>
              <div className={classnames("txt", { "en_txt": isEn })}>{t('home.vision.desc.paragraph.three')}</div></div>
          </div>
        </div>
      </section>
      <section id="milestone">
        <div className="inner-container">
          <div className="m-title">{t('home.milestone.title')}</div>
          <div className="m-slider">
            <Carousel dots={{ className: "dots-custom" }} arrows={true} infinite={false}>
              <div>
                <div className="m-slider-item">
                  <div className="m-item-seg">
                    <div className="m-seg-title">2023</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2023')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2021</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2021')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2020</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2020')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2019</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2019')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2018</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2018')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2017</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2017')}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="m-slider-item">
                  <div className="m-item-seg">
                    <div className="m-seg-title">2016</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2016')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2014</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2014')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2012</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2012')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2007</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2007')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2006</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2006')}</div>
                  </div>
                  <div className="m-item-seg">
                    <div className="m-seg-title">2005</div>
                    <div className="m-seg-text">{t('home.milestone.seg.2005')}</div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
      <section id="team">
        <div className="inner-container">
          <div className="t-title">{t('home.team.title')}</div>
          <div className="c-gallery-container">
            {meta.directors.map(item => {
              return (
                <div className="c-gallery-item" key={item.name} onClick={() => { handleClickPop(item) }}>
                  <div className="c-gallery-img">
                    <img src={item.img} alt="" />
                  </div>
                  <div className={
                    classnames("c-gallery-figure", { "en": isEn })
                  }>
                    <div className="c-figure-title">{t(`global.${item.name}.name`)}</div>
                    <div className="c-figure-subtitle">{t(`global.${item.name}.position`)}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section id="culture">
        <div className="inner-container">
          <div className="c-title">{t('home.culture.title')}</div>
          <div className={classnames("c-content", { 'en': isEn })}>
            {/* <Space split={<Divider type="vertical" />} className="index-culture-box" size={20}> */}
            <div className="c-txt first">
              <span className='c_icon icon1'></span>
              <p className={classnames("txt", { 'en_txt': isEn })}>{t('home.culture.spirit.one.paragraph')}</p>
            </div>
            <span className='c-divider'></span>
            <div className="c-txt second">
              <span className='c_icon icon2'></span>
              <p className={classnames("txt", { 'en_txt': isEn })}>{t('home.culture.spirit.two.paragraph')}</p>
            </div>
            <span className='c-divider'></span>
            <div className="c-txt third">
              <span className='c_icon icon3'></span>
              <p className={classnames("txt", { 'en_txt': isEn })}>{t('home.culture.spirit.three.paragraph')}</p>
            </div>
            {/* </Space> */}
          </div>
        </div>
      </section>
      <section id="responsibility">
        <div className="inner-container">
          <div className="resp-main">
            {t('home.responsibility.title')}
          </div>
          <div className='resp-cont'>
            <div className='resp-top-color'></div>
            <div className="resp-title">
              <div className="resp-subtitle">
                {t('home.responsibility.desc')}
              </div>
              <div className="resp-footer">{t('home.responsibility.footer')}</div>
            </div>
            <div className='resp-contain'>
              <Carousel arrows={true} className="out-carousel-slick" dots={false}>
                {/* <div className="resp-slide-item">
              <div className="resp-img-container">
                <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/e1a84440.png" alt="" />
                  </div>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/38c8a0e3.png" alt="" />
                  </div>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/dae785e5.png" alt="" />
                  </div>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/015de5a5.png" alt="" />
                  </div>
                </Carousel>
              </div>
              <div className="desc">
                <div className="title">{t('home.responsibility.5.title')}</div>
                <div className="abstract">
                  <div className="abstract-item">- {t('home.responsibility.5.abstract.0')}</div>
                  <div className="abstract-item">- {t('home.responsibility.5.abstract.1')}</div>
                  <div className="abstract-item">- {t('home.responsibility.5.abstract.2')}</div>
                  <div className="abstract-item">- {t('home.responsibility.5.abstract.3')}</div>
                </div>
              </div>
            </div> */}
                {/* <div className="resp-slide-item">
              <div className="resp-img-container">
                <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/cd0126c8.jpg" alt="" />
                  </div>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/3dc841bd.jpg" alt="" />
                  </div>
                </Carousel>
              </div>
              <div className="desc">
                <div className="title">{t('home.responsibility.0.title')}</div>
                <div className="abstract">
                  <div className="abstract-item">- {t('home.responsibility.0.abstract.0')}</div>
                  <div className="abstract-item">- {t('home.responsibility.0.abstract.1')}</div>
                </div>
              </div>
            </div> */}
                {/* <div className="resp-slide-item">
              <div className="img-container"><img src="https://p3.dfzxvip.com/d8af5106.jpg" alt="" /></div>
              <div className="desc">
                <div className="title">{t('home.responsibility.1.title')}</div>
                <div className="abstract">
                  <div className="abstract-item">- {t('home.responsibility.1.abstract.0')}</div>
                  <div className="abstract-item">- {t('home.responsibility.1.abstract.1')}</div>
                </div>
              </div>
            </div> */}
                {/* <div className="resp-slide-item">
              <div className="resp-img-container">
                <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/cd0126c8.jpg" alt="" />
                  </div>
                  <div className="img-container">
                    <img src="https://p3.dfzxvip.com/3dc841bd.jpg" alt="" />
                  </div>
                </Carousel>
              </div>
              <div className="desc">
                <div className="title">{t('home.responsibility.0.title')}</div>
                <div className="abstract">
                  <div className="abstract-item">- {t('home.responsibility.0.abstract.0')}</div>
                  <div className="abstract-item">- {t('home.responsibility.0.abstract.1')}</div>
                </div>
              </div>
            </div> */}
                <div className="resp-slide-item">
                  <div className="resp-img-container">
                    <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                      <div className="img-container"><img src={responsibility1} alt="" /></div>
                      <div className="img-container"><img src={responsibility3} alt="" /></div>
                      <div className="img-container"><img src={responsibility4} alt="" /></div>
                      <div className="img-container"><img src={responsibility5} alt="" /></div>
                    </Carousel>
                  </div>
                  <div className="desc">
                    <div className="title">{t('home.responsibility.2.title')}</div>
                    <div className="abstract">
                      <div className="abstract-item">- {t('home.responsibility.2.abstract.0')}</div>
                      <div className="abstract-item">- {t('home.responsibility.2.abstract.1')}</div>
                    </div>
                  </div>
                </div>
                <div className="resp-slide-item">
                  <div className="resp-img-container">
                    <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                      <div className="img-container"><img src={responsibility2} alt="" /></div>
                      <div className="img-container"><img src={responsibility9} alt="" /></div>
                      <div className="img-container"><img src={responsibility10} alt="" /></div>
                    </Carousel>
                  </div>
                  <div className="desc">
                    <div className="title">{t('home.responsibility.3.title')}</div>
                    <div className="abstract">
                      <div className="abstract-item">- {t('home.responsibility.3.abstract.0')}</div>
                      <div className="abstract-item">- {t('home.responsibility.3.abstract.1')}</div>
                      <div className="abstract-item">- {t('home.responsibility.3.abstract.2')}</div>
                    </div>
                  </div>
                </div>
                <div className="resp-slide-item">
                  <div className="resp-img-container">
                    <Carousel autoplay className="inner-carousel-slick" dots={{ className: 'circle-dots' }}>
                      <div className="img-container">
                        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?action=getalbum&__biz=Mzg4ODcyMzg5NQ==&scene=1&album_id=2687841887067799553&count=3#wechat_redirect" target="_blank" rel="noopener noreferrer">
                          <img src={responsibility6} alt="" />
                        </a>
                      </div>
                      <div className="img-container">
                        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?action=getalbum&__biz=Mzg4ODcyMzg5NQ==&scene=1&album_id=2687841887067799553&count=3#wechat_redirect" target="_blank" rel="noopener noreferrer">
                          <img src={responsibility7} alt="" />
                        </a>
                      </div>
                      <div className="img-container">
                        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?action=getalbum&__biz=Mzg4ODcyMzg5NQ==&scene=1&album_id=2687841887067799553&count=3#wechat_redirect" target="_blank" rel="noopener noreferrer">
                          <img src={responsibility8} alt="" />
                        </a>
                      </div>
                    </Carousel>
                  </div>
                  <div className="desc">
                    <div className="title">{t('home.responsibility.4.title')}</div>
                    <div className="abstract">
                      <div className="abstract-item">- {t('home.responsibility.4.abstract.0')}</div>
                      <div className="abstract-item">- {t('home.responsibility.4.abstract.1')}</div>
                      <div className="abstract-item">- {t('home.responsibility.4.abstract.2')}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="resp-slide-item">
              <div className="img-container"><img src="https://p3.dfzxvip.com/d4da6682.jpg" alt="" /></div>
              <div className="desc">
                <div className="title">{t('home.responsibility.3.title')}</div>
                <div className="abstract">
                  <div className="abstract-item">- {t('home.responsibility.3.abstract.0')}</div>
                  <div className="abstract-item">- {t('home.responsibility.3.abstract.1')}</div>
                  <div className="abstract-item">- {t('home.responsibility.3.abstract.2')}</div>
                </div>
              </div>
            </div> */}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      {
        !isEn && (<section id="news">
          <div className="n-title">{t('home.news.title')}</div>
          <div className="n-content inner-container">
            {
              latest.map((news, index) => (
                <div className="n-content-item" onClick={() => toLink(news.link)} key={news.title_zh}>
                  <div className="n-item-bar">
                    {/* <img src={decorates[index]} alt="" /> */}
                  </div>
                  <div className="n-item-content">
                    <div className="n-item-title">
                      {news[`title_${i18n.language}`]}
                    </div>
                    <Divider dashed></Divider>
                    <div className="n-item-desc">
                      <Paragraph ellipsis={{ rows: 3 }}>
                        {news[`abstract_${i18n.language}`]}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="n-link"><Button ghost onClick={() => toPage('/news')}>{t('global.more')} &gt;</Button></div>
        </section>)}
      <Modal info={modalInfo} visible={visible} onCancle={handleCanclePop}></Modal>
    </div>
  )
}
export default Index;;require('./index.less')