/*
 * @Version: 2.0
 * @Autor: xieyan
 * @Date: 2021-04-28 19:27:18
 * @LastEditors: xieyan
 * @LastEditTime: 2021-04-28 19:58:27
 */
import Track from './../maidian/@xdf/track-h5-sdk';

let tracker;




/**
 * 判断环境
 * @param {*}
 */
export const getEnv = (() => {
  const url = window.location.origin;
  if (url.indexOf('.neibu') > -1) {
    return 'neibu';
  }
  if (url.indexOf('local') > -1) {
    return 'local';
  }
  if (url.indexOf('test') > -1) {
    return 'test';
  }
  return 'prod';
})()

if (!tracker) {
  tracker = new Track({
    env: getEnv, // 上报环境
    params: {
    }  // 埋点中所有需要携带的数据
  })
}

/**
 * 点击埋点
 * @param {*} params 埋点参数
 */
export const trackClick = (params) => {
  tracker.click({
    params
  });
}