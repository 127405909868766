import React, { useState, useEffect } from 'react';
import { subMenu } from '../../config/menu';
import SubNav from '../../components/subNav';
import Banner from '../../components/banner';
import { Button } from 'antd';
// import { Button, Space, Divider } from 'antd';
import { anchorTo } from '@/utils/common.js'
import bannerImg from '../../img/b-banner.jpg';
import brand1 from '../../img/brand.png';
// import brand2 from '../../img/b-brand-2.png';
// import brand3 from '../../img/b-brand-3.png';
import brand4 from '../../img/b-brand-4.png';
// import icon1 from '../../img/b-icon-1.png';
// import icon2 from '../../img/b-icon-2.png';
// import icon3 from '../../img/b-icon-3.png';
import icon4 from '../../img/b-icon-4.png';
import icon5 from '../../img/b-icon-5.png';
import gonzhong_share1 from '../../img/share/gongzhong/img_001.png';
import gonzhong_share2 from '../../img/share/gongzhong/img_002.png';
import douyin1 from '../../img/share/douyin/img001.png'
import douyin2 from '../../img/share/douyin/img002.png'
import douyin3 from '../../img/share/douyin/img003.png'
import douyin4 from '../../img/share/douyin/img004.png'
import douyin5 from '../../img/share/douyin/img005.png'
import douyin6 from '../../img/share/douyin/img006.png'
import kuaishou_share from '../../img/share/kuaishou/img_001.png'
import hongshu_share from '../../img/share/hongshu/img_001.png'

import { useTranslation } from 'react-i18next'


const menu = subMenu['business'];

function Business(props) {
  const { t, i18n } = useTranslation();
  const [fixed, setFixed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shareList, setShareList] = useState([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shareListArr = [
    {
      shareName: 'business.followus.gongzhonghao',
      shareKey: 'gongzhonghao',
      shareImg: 'gonzhongImg',
      shareTypeList: [
        {
          img: gonzhong_share1,
          name: 'business.followus.title1',
        },
        {
          img: gonzhong_share2,
          name: 'business.followus.title2',
        },
      ],
      shareHref: '',
    },
    {
      shareName: 'business.followus.douyin',
      shareKey: 'douyin',
      shareImg: 'douyinImg',
      shareTypeList: [
        {
          img: douyin1,
          name: 'business.followus.title3',
        },
        {
          img: douyin2,
          name: 'business.followus.title4',
        },
        {
          img: douyin3,
          name: 'business.followus.title5',
        },
        {
          img: douyin4,
          name: 'business.followus.title6',
        },
        {
          img: douyin5,
          name: 'business.followus.title7',
        },
        {
          img: douyin6,
          name: 'business.followus.title8',
        },
      ],
      shareHref: '',
    },
    {
      shareName: 'business.followus.kuaishou',
      shareKey: 'kuaishou',
      shareImg: 'kuaishouImg',
      shareTypeList: [
        {
          img: kuaishou_share,
          name: 'business.followus.title3',
        },
      ],
      shareHref: '',
    },
    {
      shareName: 'business.followus.weibo',
      shareKey: 'weibo',
      shareImg: 'weiboImg',
      // shareTypeList: [
      //   {
      //     img: './../../img/share/gongzhong/gongzhong.png',
      //     name: 'aa',
      //   },
      // ],
      shareHref: 'https://weibo.com/haowudongfanglai',
    },
    {
      shareName: 'business.followus.xiaohongshu',
      shareKey: 'xiaohongshu',
      shareImg: 'hongshuImg',
      shareTypeList: [
        {
          img: hongshu_share,
          name: 'business.followus.title3',
        },
      ],
      shareHref: '',
    },
    {
      shareName: 'business.followus.tianmao',
      shareKey: 'tianmao',
      shareImg: 'tianmaoImg',
      // shareTypeList: [
      //   {
      //     img: './../../img/share/gongzhong/gongzhong.png',
      //     name: 'aa',
      //   },
      // ],
      shareHref: 'https://dfzx.tmall.com/',
    },
    {
      shareName: 'business.followus.jingdong',
      shareKey: 'jindong',
      shareImg: 'jindongImg',
      // shareTypeList: [
      //   {
      //     img: './../../img/share/gongzhong/gongzhong.png',
      //     name: 'aa',
      //   },
      // ],
      shareHref: 'https://mall.jd.com/index-12044878.html',
    },
  ];
  useEffect(() => {
    setShareList(shareListArr)
  }, [shareListArr])
  function changeFixed(bool) {
    setFixed(bool);
  }

  function toLink(href) {
    window.open(href);
  }
  useEffect(() => {
    const anchorName = props.location.state?.anchorName;
    const timer = setTimeout(() => {
      setIsLoaded(true);
      if (anchorName) {
        const hrefName = anchorName.split("+")[0];
        anchorTo({ elementId: hrefName });
      } else {
        window.scrollTo({ top: 0 })
      }
    }, 500)
    return function cleanTimout() {
      clearTimeout(timer);
    }
  }, [props.location.state?.anchorName])
  return (
    <div className="business-container">
      <Banner img={bannerImg} alt="business" />
      <SubNav menu={menu} changeFixed={changeFixed} isLoaded={isLoaded} canSwitch={i18n.language === 'en'} />
      <div style={{ height: `${fixed ? `58px` : '0px'}` }}></div>
      {/* <section id="k-12Education">
                <div className="title k-title">{t('business.k12.title')}</div>
                <div className="desc k-desc">
                    {t('business.k12.desc')}
                </div>
                <div className="c-link"><Button ghost><a href="https://k12.koolearn.com/" target="_blank" rel="noopener noreferrer">{t('global.more')} &gt;</a></Button></div>
            </section> */}
      <section id="privateLabelProducts">
        <div className="title c-title">{t('business.selfOperatedGoods.title')}</div>
        <div className="desc c-desc first-desc">{t('business.selfOperatedGoods.desc01')}</div>
        <div className="desc c-desc first-desc">{t('business.selfOperatedGoods.desc02')}</div>
        <div className="desc c-desc first-desc">{t('business.selfOperatedGoods.desc03')}</div>
        <div className="desc c-desc first-desc">{t('business.selfOperatedGoods.desc04')}</div>
        <div className="desc c-desc">{t('business.selfOperatedGoods.desc05')}</div>
        <div className='follow'>
          {/* <p className='tip'>{t('business.followus.title')}</p> */}
          <div className='follow-logo'>
            <span className='follow-icon'></span>
          </div>
          <div className="foot_flow_list">
            {shareList.map(item => (
              <div className="list" key={item.shareKey}>
                <div className={`foot_list_before ${item.shareKey}`}>
                  {/* <img className="list_before_img" src={item.shareImg} alt="" /> */}
                  <span className={`list_before_img ${item.shareImg}`}></span>
                  <span className="list_before_text">{t(item.shareName)}</span>
                  {item.shareTypeList ? (
                    ''
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      className="btn-href"
                      href={item.shareHref}
                      target="_blank"
                      rel="noreferrer"
                    />
                  )}
                </div>
                <div
                  className={`foot_list_after ${item.shareTypeList ? 'has-share' : ''
                    } ${item.shareKey + '_list_after'}`}
                >
                  {item.shareTypeList &&
                    item.shareTypeList.map((obj, index) => (
                      <div className="list_after_box" key={obj.name}>
                        <div src="" alt="" className="list_after_imgarea">
                          <img
                            src={obj.img}
                            alt=""
                            className="list_after_img"
                          />
                          <span className="list_after_text">{t(obj.name)}</span>
                        </div>
                        {index !== item.shareTypeList.length - 1 ? (
                          <div className="list_after_line" />
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="c-link"><Button ghost><a href="https://www.dfzxvip.com/index" target="_blank" rel="noopener noreferrer">{t('global.more')} &gt;</a></Button></div>
      </section>
      <section id="collegeEducation">
        <div className="title c-title">{t('business.college.title')}</div>
        <div className="desc c-desc">{t('business.college.desc')}</div>
        <div className="c-link"><Button ghost><a href="https://daxue.koolearn.com/" target="_blank" rel="noopener noreferrer">{t('global.more')} &gt;</a></Button></div>
      </section>
      {/* <section id="preSchoolEducation">
                <div className="title p-title">{t('business.preSchool.title')}</div>
                <div className="desc p-desc">{t('business.preSchool.desc')}</div>
                <div className="c-link"><Button ghost><a href="https://www.donut.cn/" target="_blank" rel="noopener noreferrer">{t('global.more')} &gt;</a></Button></div>
            </section> */}
      <section id="institutionalCustomers">
        <div className="title i-title">{t('business.customers.title')}</div>
        <div className="desc i-desc">{t('business.customers.desc')}</div>
        <div className="c-link"><Button ghost><a href="http://b.koolearn.com" target="_blank" rel="noopener noreferrer">{t('global.more')} &gt;</a></Button></div>
      </section>
      {/* <section id="associatedCompanies">
        <div className="title a-title">{t('business.companies.title')}</div>
        <div className="desc a-desc">{t('business.companies.desc')}</div>
        <div className="a-companies">
          <span className="a-companies-item" onClick={() => toLink("http://www.stemedu.cn/")}></span>
          <span className="a-companies-item" onClick={() => toLink("https://www.eeo.cn/")}></span>
          <span className="a-companies-item"></span>
        </div>
      </section> */}
      <section id="relatedBrands">
        <div className="title r-title">{t('business.brands.title')}</div>
        <div className="r-logos">
          {/* <Space split={<Divider type="vertical" />} className="r-logos-box" size={100}> */}
          <img src={brand1} alt="" onClick={() => toLink("https://www.dfzxvip.com/index")} className="branch_dfzx" />
          {/* <img src={brand2} alt="" onClick={() => toLink("https://apps.apple.com/cn/app/id852917296")} /> */}
          {/* <img src={brand3} alt="" onClick={()=>toLink("https://dfub.xdf.cn/")}/> */}
          <span className='brand_line'></span>
          <img src={brand4} alt="" onClick={() => toLink("https://b.koolearn.com/")} className="brand_xdf" />
          {/* </Space> */}
        </div>
        <div className="r-downloads">
          {/* <div className="r-downloads-item"><img src={icon1} alt="" /><span className="r-figure">{t('business.download.koolearn')}</span><Button><a href="http://app.koolearn.com/zhuanti/app/" target="_blank" rel="noopener noreferrer">{t('business.download.title')}</a></Button></div> */}
          {/* <div className="r-downloads-item"><img src={icon2} alt="" /><span className="r-figure">{t('business.download.donut')}</span><Button><a href="https://apps.apple.com/cn/app/duo-na-xue-ying-yu-zhi-ru/id852917296" target="_blank" rel="noopener noreferrer">{t('business.download.title')}</a></Button></div> */}
          {/* <div className="r-downloads-item"><img src={icon3} alt="" /><span className="r-figure">{t('business.download.lboc')}</span><Button><a href="https://apps.apple.com/cn/app/%E4%B9%90%E6%92%AD%E8%AF%BE/id1166968789" target="_blank" rel="noopener noreferrer">{t('business.download.title')}</a></Button></div> */}
          <div className="r-downloads-item"><img src={icon5} alt="" />
            {/* <span className="r-figure">{t('business.download.college')}</span> */}
            <span className="r-figure"></span>
            <Button><a href="https://www.dfzxvip.com/downLoad" target="_blank" rel="noopener noreferrer">{t('business.download.title')}</a></Button>
          </div>
          <div className="r-downloads-item"><img src={icon4} alt="" />
            {/* <span className="r-figure">{t('business.download.college')}</span> */}
            <span className="r-figure"></span>
            <Button><a href="http://app.koolearn.com/zhuanti/app/" target="_blank" rel="noopener noreferrer">{t('business.download.title')}</a></Button>
          </div>
        </div>
      </section>
      <section id="cooperation">
        <div className="IRContacts">
          <div className="title ir-title">{t('business.cooperation.title')}</div>
          <div className="ir-subtitle">{t('business.contact.company.name')}</div>
          <div className="ir-desc">{t('business.contact.company.adress')}<br />
          {t('business.contact.company.tel')}<br />{t('business.contact.company.time')}</div>
        </div>
        <div className="talk-lists">
          <div className="talk-lists-item">
            <div className="talk-subtitle">{t('business.cooperation.business.title')}</div>
            <div className="talk-desc">
              {/* {t('business.cooperation.business.contact')}<br /> */}
              {t('business.cooperation.business.email')}</div>
          </div>
          <div className="talk-lists-item">
            <div className="talk-subtitle">{t('business.cooperation.market.title')}</div>
            <div className="talk-desc">
              {/* {t('business.cooperation.market.contact')}<br /> */}
              {t('business.cooperation.market.email')}</div>
          </div>
          {/* <div className="talk-lists-item">
            <div className="talk-subtitle">{t('business.cooperation.online.title')}</div>
            <div className="talk-desc">{t('business.cooperation.online.contact')}<br />{t('business.cooperation.online.email')}</div>
          </div> */}
        </div>
      </section>
      <section></section>
    </div>
  )
}
export default Business;;require('./index.less')