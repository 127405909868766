const subMenu = {
  'index': [
    {
      // path:'/brief',
      href: 'brief',
      name: 'brief',
    }, {
      href: 'vision',
      name: 'vision'
    },
    {
      href: 'milestone',
      name: 'milestone'
    },
    {
      href: 'team',
      name: 'team',
    },
    {
      href: 'culture',
      name: 'culture',
    },
    {
      href: 'responsibility',
      name: 'responsibility'
    },
    {
      // path:'/news',
      href: 'news',
      name: 'news'
    },
  ],
  'business': [
    // {
    //     href:"k-12Education",
    //     name:'k-12Education'
    // },
    {
      href: "privateLabelProducts",
      name: 'privateLabelProducts'
    },
    {
      href: "collegeEducation",
      name: 'collegeEducation'
    },
    // {
    //     href:"preSchoolEducation",
    //     name:'preSchoolEducation'
    // },
    {
      href: "institutionalCustomers",
      name: "institutionalCustomers",
    },
    // {
    //   href: "associatedCompanies",
    //   name: 'associatedCompanies'
    // },
    {
      href: 'relatedBrands',
      name: 'relatedBrands',
    },
    {
      href: 'cooperation',
      name: 'cooperation'
    },
  ],
  'employee': [
    {
      href: "talentDevelopment",
      name: 'talentDevelopment',
    },
    {
      href: "workEnvironment",
      name: 'workEnvironment'
    },
    {
      href: "employeeActivities",
      name: 'employeeActivities'
    },
    {
      href: 'joinUs',
      name: 'joinUs'
    }
  ],
  'invest': [
    {
      href: 'StockInformation',
      name: 'StockInformation'
    }, {
      href: 'CorporateGovernance',
      name: 'CorporateGovernance',
    },
    {
      // path:'/investor/governanceDocuments',
      href: 'GovernanceDocuments',
      name: 'GovernanceDocuments'
    },
    {
      href: 'FinancialReport',
      name: 'FinancialReport'
    },
    {
      href: 'Announcements',
      name: 'Announcements'
    },
    {
      // path:'/investor/pressRelease',
      href: 'PressRelease',
      name: 'PressRelease'
    },
    {
      href: 'AnalystCoverage',
      name: 'AnalystCoverage'
    },
    {
      href: 'E-mailAlert',
      name: 'E-mailAlert'
    }
  ]
}
const subMenuEn = {
  'index': [
    {
      // path:'/brief',
      href: 'brief',
      name: 'brief',
    }, {
      href: 'vision',
      name: 'vision'
    },
    {
      href: 'milestone',
      name: 'milestone'
    },
    {
      href: 'team',
      name: 'team',
    },
    {
      href: 'culture',
      name: 'culture',
    },
    {
      href: 'responsibility',
      name: 'responsibility'
    }, {
      href: 'news',
      name: 'events'
    }
  ],
  'business': [
    // {
    //     href:"k-12Education",
    //     name:'k-12Education'
    // },
    {
      href: "privateLabelProducts",
      name: 'privateLabelProducts'
    },
    {
      href: "collegeEducation",
      name: 'collegeEducation'
    },
    // {
    //     href:"preSchoolEducation",
    //     name:'preSchoolEducation'
    // },
    {
      href: "institutionalCustomers",
      name: "institutionalCustomers",
    },
    // {
    //   href: "associatedCompanies",
    //   name: 'associatedCompanies'
    // },
    {
      href: 'relatedBrands',
      name: 'relatedBrands',
    },
    {
      href: 'cooperation',
      name: 'cooperation'
    },
  ],
  'employee': [
    {
      href: "talentDevelopment",
      name: 'talentDevelopment',
    },
    {
      href: "workEnvironment",
      name: 'workEnvironment'
    },
    {
      href: "employeeActivities",
      name: 'employeeActivities'
    },
    {
      href: 'joinUs',
      name: 'joinUs'
    }
  ],
  'invest': [
    {
      href: 'StockInformation',
      name: 'StockInformation'
    }, {
      href: 'CorporateGovernance',
      name: 'CorporateGovernance',
    },
    {
      // path:'/investor/governanceDocuments',
      href: 'GovernanceDocuments',
      name: 'GovernanceDocuments'
    },
    {
      href: 'FinancialReport',
      name: 'FinancialReport'
    },
    {
      href: 'Announcements',
      name: 'Announcements'
    },
    {
      // path:'/investor/pressRelease',
      href: 'PressRelease',
      name: 'PressRelease'
    },
    {
      href: 'AnalystCoverage',
      name: 'AnalystCoverage'
    },
    {
      href: 'E-mailAlert',
      name: 'E-mailAlert'
    }
  ]
}
export {
  subMenu,
  subMenuEn
};