import React, { useState, useEffect, useRef } from 'react'
import videoPoster from '../../img/videoPoster.jpg'
import videojs from 'video.js'
import "video.js/dist/video-js.css";
// import { VIDEO_SRC_MAP } from './videoAddress.js';
import mainVideo from './../../img/mainVideo.mp4'
import bgVideo from './../../img/bgVideo.mp4'

function BannerVideo(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const bannerVideoRef = useRef(null);
  const { options } = props;
  // const [controls, setControls] = useState(false);
  const [posterModal, setPosterModal] = useState(true);
  const [videoType, setVideoType] = useState('bg');
  const [muted, setMuted] = useState(true);
  const [loop, setLoop] = useState(false);
  // const [isClicked, setIsClicked] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  // const [videoSrc, setVideoSrc] = useState(VIDEO_SRC_MAP['bg'])
  const [videoSrc, setVideoSrc] = useState(bgVideo)
  const [showPoster, setShowPoster] = useState(false)



  useEffect(() => {
    console.log('东方甄选官网香港站-m');
    handleResize()
    window.addEventListener('resize', handleResize);
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = playerRef.current = videojs(videoElement, options,
        () => {
          console.log("player is ready");
          handlePlayerReady(player);
          // setLoaded(true)
          setPosterModal(true)
        });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // console.log('playerplayerplayer===', player);
      // player.src(options.sources[0].src);
      // player.autoplay(true);

    }
    return (() => {
      window.removeEventListener('resize', handleResize);
    })
    // eslint-disable-next-line no-use-before-define
  }, [handlePlayerReady, options, videoRef])

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // 监听视频资源更换
  useEffect(() => {
    // console.log('监听videoType值变化', videoType)
    if (videoType === 'main') {
      // setVideoSrc(VIDEO_SRC_MAP['main'])
      setVideoSrc(mainVideo)
      if (videoRef.current) {
        // videoRef.current.src = VIDEO_SRC_MAP['main'];
        videoRef.current.src = mainVideo;
      }
      // console.log('开始播放')
      // playVideo()
    }
    // eslint-disable-next-line no-use-before-define
  }, [videoType])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // playerRef.current.addClass('vjs-my');
    playerRef.current.userActive(false);
    hideBigPlayButton();
    // you can handle player events here

    // 当播放的资源是主视频，并加载完成后，则隐藏按钮遮罩层
    playerRef.current.on('loadeddata', () => {
      // console.log('加载资源---------->', videoRef.current.src)
      if (videoRef.current.src.indexOf('mainVideo') !== -1) {
        setPosterModal(false)
      }
    });
    // 开始播放 不生效
    playerRef.current.on('firstplay', () => {
      // console.log('firstplay---------')
      playHandle();
      // setLoaded(true)
    });

    playerRef.current.on('waiting', () => {
      console.log('player is waiting');
    });

    playerRef.current.on('dispose', () => {
      console.log('player will dispose');
    });


    // 播放
    playerRef.current.on('play', () => {
      console.log('play')
      playHandle()
    });

    // 暂停
    playerRef.current.on('pause', () => {
      console.log('pause')
      // // 若当前播放的视频是短视频，则播放结束后，自动显示视频默认展示图
      // if (videoRef.current.src.indexOf('mainVideo') !== -1) {
      //   // setPosterModal(false)
      // }
      pauseHandle()

    });

    // 结束
    playerRef.current.on('ended', () => {
      console.log('结束end', playerRef.current)
      // playerRef.current.posterImage.trigger('loadstart');
      // playerRef.current.posterImage.setSrc(videoPoster);
      // playerRef.current.trigger('loadstart');
      // playerRef.current.posterImage.show();
      setShowPoster(true)
      pauseHandle()
    });


    // 异常
    playerRef.current.on('error', e => {
      console.log('playererror:', e);
    });
  };

  const playHandle = () => {
    // console.log('进入playHandle')
    // 判断当前播放的是主视频
    if (videoRef.current.src.indexOf('mainVideo') !== -1) {
      setPosterModal(false)
    }
  }

  const pauseHandle = () => {
    setPosterModal(true)
  }

  // 点击播放按钮
  const playMain = () => {
    setMuted(false)
    setLoop(true)
    setPosterModal(false)
    // setControls(true)
    setVideoType('main')
    setShowPoster(false)
    playerRef.current && playerRef.current.play();
  }

  const hideBigPlayButton = () => {
    playerRef.current.bigPlayButton.el_.style.display = 'none';
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const playVideo = () => {
  //   if (isClicked === true) {
  //     return;
  //   }
  //   console.log('播放视频')
  //   setIsClicked(true)
  //   // playerRef.current && playerRef.current.play();
  // }

  const handleResize = () => {
    const video_cw = document.body.clientWidth
    //要求按照宽1440  高576的宽高比，来计算视频区域宽度与屏幕宽度相等时，对应的高度
    const video_ch = Number.parseInt((video_cw * 576) / 1440)
    // console.log('计算的高度值----------', video_ch, bannerVideoRef.current, bannerVideoRef.current.style)
    bannerVideoRef.current.style.height = video_ch + 'px'
  }

  return (
    <div className="banner-video" id="bannerVideo" ref={bannerVideoRef}>
      {/* <img className={`banner-video-img ${loaded ? 'loaded' : ''} ${controls ? 'none' : ''} `} src={videoPoster} alt="" /> */}
      {/* <div className={`video-wrapper ${!loaded ? 'loaded' : ''} ${controls ? 'video-wrapper-relative' : ''}`}> */}
      <div className="video-wrapper">
        <video
          ref={videoRef}
          playsInline
          webkit-playsinline="true"
          x5-video-player-type="h5-page"
          x5-video-orientation="landscape"
          x5-video-player-fullscreen="false"
          controlsList="nodownload nofullscreen noremoteplayback"
          preload="auto"
          width="100%"
          height="100%"
          muted={muted}
          loop={loop}
          controls
          type="application/x-mpegURL"
          style={{ 'objectFit': 'fill', 'outline': 'none' }}
          className="video-js vjs-default-skin vjs-fluid my-video vjs-big-play-centered"
          poster={videoPoster}
        >
          <source src={videoSrc} />
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider
            upgrading to a web browser that
            <a
              href="https://videojs.com/html5-video-support/"
              target="_blank" rel="noreferrer"
            >
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
      {
        posterModal && <div
          onClick={playMain}
          className={`banner-video-poster ${showPoster ? 'poster_bg' : ''}`}
        // className={`banner-video-poster ${!loaded ? 'loaded' : ''} ${showPoster ? 'poster_bg' : ''}`}
        >
          <div className='poster-contain'>
            <div className="banner-video-poster-inner">
              <div className='banner-video-poster-title'>新东方 · 东方甄选</div>
              <div className='banner-video-poster-subtitle'>鲜美生活  玲琅满目</div>
              <span className="banner-video-poster-icon" />
              {/* <a className="playBtn"></a> */}
            </div>
          </div>
        </div>
      }
    </div >
  )
}
export default BannerVideo;;require('./index.less')