const header = {
  "header.menu.name.home": '公司简介',
  "header.menu.name.business": '业务',
  "header.menu.name.employee": '员工',
  "header.menu.name.investor": '投资者关系',
  "header.submenu.name.contacts": '联系我们',
  "header.submenu.name.brief": '关于我们',
  "header.submenu.name.vision": '愿景',
  "header.submenu.name.milestone": '发展里程碑',
  "header.submenu.name.team": '董事会',
  "header.submenu.name.culture": '企业文化',
  "header.submenu.name.responsibility": '社会责任',
  "header.submenu.name.news": '新闻动态',
  "header.submenu.name.k-12Education": 'K-12教育',
  "header.submenu.name.privateLabelProducts": "自营产品及直播电商",
  "header.submenu.name.collegeEducation": '大学教育',
  "header.submenu.name.preSchoolEducation": '学前教育',
  "header.submenu.name.institutionalCustomers": '机构客户',
  "header.submenu.name.associatedCompanies": '联营业务',
  "header.submenu.name.relatedBrands": '相关品牌',
  "header.submenu.name.IRContacts": '联系我们',
  "header.submenu.name.cooperation": '合作洽谈',
  "header.submenu.name.talentDevelopment": '人才发展',
  "header.submenu.name.workEnvironment": '办公环境',
  "header.submenu.name.employeeActivities": '员工活动',
  "header.submenu.name.joinUs": '加入我们',
  "header.submenu.name.StockInformation": '股价信息',
  "header.submenu.name.CorporateGovernance": '公司管治',
  "header.submenu.name.GovernanceDocuments": '治理文件',
  "header.submenu.name.FinancialReport": '财务报告',
  "header.submenu.name.Announcements": '公告及通函',
  "header.submenu.name.PressRelease": '新闻发布',
  "header.submenu.name.AnalystCoverage": '分析师列表',
  "header.submenu.name.E-mailAlert": '电邮订阅',

}
const global = {
  "global.company.name": "东方甄选",
  "global.governance.documents1.title": "全球发售 / 招股书",
  "global.governance.documents2.title": "股东提名候选人参选本公司董事的程序",
  "global.governance.documents3.title": "VIE合同1 - 独家管理顾问及业务合作协议",
  "global.governance.documents4.title": "VIE合同2 - 独家购股权协议",
  "global.governance.documents5.title": "VIE合同3 - 股份质押协议",
  "global.governance.documents6.title": "2019 ESG报告",
  "global.governance.documents7.title": "2020 ESG报告",
  "global.governance.documents8.title": "2021 ESG报告",
  "global.governance.documents9.title": "2022 ESG报告",
  "global.governance.documents10.title": "2023 ESG报告",
  "global.press20240823.title": "东方甄选公布2024财年全年业绩",
  "global.press20240619.title": "东方甄选6月19日新品发布会",
  "global.press20240124.title": "东方甄选公布2024财年中期业绩",
  "global.press20230825.title": "东方甄选公布2023财年全年业绩",
  "global.press20230117.title": "新东方在线公布2023财年中期业绩",
  "global.press20220826.title": "新东方在线公布2022财年全年业绩",
  "global.press20220121.title": "新东方在线公布2022财年中期业绩",
  "global.press1.title": "新东方在线公布2021财年全年业绩",
  "global.press2.title": "新东方在线公布2021财年中期业绩",
  "global.press3.title": "新东方在线公布2020财年全年业绩",
  "global.press4.title": "新东方在线公布2020财年中期业绩",
  "global.press5.title": "新东方在线公布2019财年全年业绩及收购东方优播49%股权",
  "global.press6.title": "新东方在线成功于香港联交所主板挂牌上市",
  "global.press7.title": "新东方在线公布股份发售结果",
  "global.press8.title": "新东方在线宣布于联交所主板上市计划",
  "global.more": '了解更多',
  "global.all": '查看全部',
  "global.yuminghong.name": '俞敏洪',
  "global.yuminghong.position": '东方甄选董事长，执行董事，CEO',
  "global.yuminghong.brief": '俞先生，60岁，为执行董事、提名委员会主席、董事会主席兼本公司行政总裁。俞先生亦为北京迅程（自2015年5月起）及新东方集团的若干公司（包括乐词网络）的主席兼董事。俞先生于1985年7月获中国北京大学颁授英语学士学位。俞先生为新东方的创办人，自2001年起一直担任其董事会执行主席，并曾于2017年8月（及自2018年3月起担任独立董事）至2019年6月担任SunlandsTechnologyGroup（其美国存托股份于纽约证券交易所上市，NYSE：STG）的董事。自2001年起，俞先生一直为新东方（我们的控股股东，其美国存托股份于纽约证券交易所上市（NYSE：EDU）及股份于联交所上市（股份代号：9901）之公司）主席及董事。',
  "global.sundongxu.name": '孙东旭',
  "global.sundongxu.position": "非执行董事",
  "global.sundongxu.brief": `孙先生，37岁，为非执行董事。于本集团内，孙先生为：北京迅程的董事（自2019年9月起）（亦为北京迅程的行政总裁）、德信东方的董事（自2019年8月起）（亦为德信东方的行政总裁）、迅程香港的董事（自2019年11月起）、酷学慧思的董事（自2019年11月起）（亦为酷学慧思的行政总裁）、西安睿盈的董事（自2020年4月至2021年4月）、新东方无忧在线（香港）教育科技有限公司的董事（自2020年8月起）、东方优选（北京）科技有限公司的董事（自2021年10月起）、北京新远方人力资源服务有限公司的董事（自2021年10月起）、东方甄选（北京）科技有限公司的董事（自2021年12月起）。此前，孙先生为西安新东方学校的校长（自2016年4月至2018年7月）及新东方西北区区域总裁（自2016年4月至2018年5月）。于2007年6月至2008年6月，孙先生于新东方集团（其时包括本集团）天津新东方学校国外考试部任职教师。于2008年6月至2016年4月，孙先生于合肥新东方学校任职，入职时任职国外考试部主管助理（自2008年6月至2009年6月），并在校内担任多个职位，最终校长（自2013年10月至2016年4月）。除在本集团任职外，孙先生亦担任新东方中国的助理副总裁（自2016年4月至2019年4月）及副总裁（自2019年4月起）。孙先生于2007年6月获中国南开大学颁授工学学士学位，主修计算器科学与技术。`,
  "global.yinqiang.name": '尹强',
  "global.yinqiang.position": "执行董事，CFO",
  "global.yinqiang.brief": `尹先生，49岁，为执行董事兼本公司首席财务官。彼亦自2016年1月起担任北京迅程的董事兼财务总监，自2019年7月至2020年11月担任珠海崇胜的董事及自2020年4月担任西安睿盈的董事及总经理（自2021年4月起），并自2020年10月起担任海南海悦东方网络科技有限公司的董事兼总经理。尹先生于1996年7月获中国首都经济贸易大学颁授经济学学士学位，并于2008年7月获中国北京大学颁授工商管理硕士学位。尹先生亦自2001年10月起为中国合资格会计师。除在本集团任职外，尹先生亦担任新东方中国的财务总监及助理副总裁（自2005年6月至2016年5月）及副总裁（自2019年4月起）。尹先生曾于1996年至2001年担任罗兵咸永道会计师事务所的高级会计师。`,
  "global.sunchang.name": "孙畅",
  "global.sunchang.position": "非执行董事",
  "global.sunchang.brief": "孙女士，55岁，为非执行董事兼薪酬委员会成员。彼担任本公司联席行政总裁直至2020年1月19日。孙女士为北京迅程（自2015年5月起）及德信东方（自2018年3月起）的董事。孙女士于1990年7月获中国北京师范大学颁授学前教育学士学位，并于1999年7月获中国的中国人民大学颁授工商管理硕士学位。除在本集团任职外，孙女士自2012年至2016年及自2016年至2020年分别担任新东方中国的助理副总裁及副总裁。孙女士曾担任中国网络通信集团公司（现为中国联合网络通讯集团有限公司，或称中国联通）的投资部总经理（2000年至2004年）及微软（中国）有限公司的营销经理（1997年至2000年）。",
  "global.wuqiang.name": "吴强",
  "global.wuqiang.position": "非执行董事",
  "global.wuqiang.brief": "吴先生，47岁，为非执行董事及审核委员会成员。吴先生亦为乐词网络（自2015年11月起）的董事。吴先生于1997年7月获中国的中国科学自动化研究所颁授工程学硕士学位。除在本集团任职外，吴先生亦曾于2016年至2019年4月担任新东方中国的副总裁，并自2019年4月起一直担任该公司高级副总裁。彼曾于2014年7月至2018年4月担任北京新东方学校的校长。此前，吴先生曾担任北京新东方学校的副校长（2013年至2014年）、新东方中国的研发总监（2005年至2007年）、其他位于青岛及成都的新东方学校的副校长（2008年至2012年）及北京明日东方科技有限公司的总裁（2000年至2005年）。",
  "global.liangyuhua.name": "梁育华",
  "global.liangyuhua.position": '非执行董事',
  "global.liangyuhua.brief": "梁女士，37岁，为非执行董事。彼亦自2019年1月起担任北京迅程的董事。梁女士于2004年6月获美国宾夕凡尼亚州大学沃顿商学院颁授经济学理学士学位（专修金融学）。除在本集团任职外，梁女士自2013年12月起一直担任腾讯控股有限公司附属公司乐娱互动香港有限公司的战略发展部副总经理，并曾于2012年11月至2013年6月担任Arete Research Services L.L.P.的高级研究分析师及于2010年8月至2012年11月担任高盛（亚洲）有限责任公司的投资研究部执行董事。",
  "global.linzheying.name": "林哲莹",
  "global.linzheying.position": "独立非执行董事",
  "global.linzheying.brief": "林先生，58岁，为独立非执行董事、薪酬委员会主席、提名委员会及审核委员会成员。林先生于1987年7月、于2006年7月及2008年6月分别获得山西财经大学（前身为山西财经学院）规划统计专业学士学位、中国北京大学光华管理学院工商管理硕士学位及法国雷恩高等商学院工商管理博士学位。除本集团外，林先生担任深圳市丰巢科技有限公司董事（2016年11月至2017年12月）；及一直担任顺丰控股股份有限公司副总裁（自2014年6月至2019年12月）及古玉资本管理有限公司执行董事（自2011年1月起）。除本公司外，林先生亦担任或曾担任以下上市公司的董事职务：(i)顺丰控股股份有限公司（深圳证券交易所上市公司，股份代号：002352）执行董事兼副主席（自2017年3月至2022年12月）；(ii)上海东正汽车金融股份有限公司（香港联交所上市公司，股份代号：2718）独立非执行董事（自2018年8月至2020年6月）；及(iii)呈和科技股份有限公司（上海证券交易所上市公司，股份代号：688625）董事（自2015年12月至2021年9月）",
  "global.dongruibao.name": "董瑞豹",
  "global.dongruibao.position": "独立非执行董事",
  "global.dongruibao.brief": "董先生，52岁，为独立非执行董事、审核委员会主席兼薪酬委员会及提名委员会成员。董先生于1993年5月获美国威斯康星州大学麦迪逊分校颁授会计学（另修计算机科学）学士学位。董先生先前为美国注册会计师协会(AICPA)会员（1995年至2009年）及特许金融分析师（1999年至2009年）。董先生自2009年至2021年一直担任网易（其美国存托股份于纳斯达克上市（纳斯达克：NTES）及股份于联交所上市（股份代号：9999）之公司）的非执行董事。彼曾担任网易的执行董事（2003年至2009年）及联席营运总监（2004年至2009年）。",
  "global.kuangweixin.name": "邝伟信",
  "global.kuangweixin.position": "独立非执行董事",
  "global.kuangweixin.brief": "邝先生，57岁，为独立非执行董事兼审核委员会成员。邝先生为英格兰及威尔士特许会计师公会会员。邝先生于1987年6月获英国剑桥大学颁授文学士学位。邝先生自2013年8月起担任中国金属资源利用有限公司（联交所上市公司，股份代号：1636）的执行董事。邝先生亦为顺风国际清洁能源有限公司（联交所上市公司，股份代号：1165）（自2014年7月起）、千百度国际控股有限公司（联交所上市公司，股份代号：1028）（自2011年8月起）、中国服饰控股有限公司（联交所上市公司，股份代号：1146）（自2011年6月起）及中国新高教集团有限公司（联交所上市公司，股份代号：2001）（自2017年3月起）的独立非执行董事。邝先生曾担任古杉环保能源有限公司（纽约证券交易所上市公司(NYSE：GU））的总裁（2007年12月至2012年10月）。此前，彼曾担任里昂证券资本市场有限公司投资银行部董事总经理以及香港及中国股票资本市场主管（2004年3月至2006年7月）以及嘉诚证券有限公司的董事（2002年至2003年）及企业财务部的总经理（1997年至2003年）。",
  "global.video.publicity": "https://daxueui-cos.koocdn.com/images/fe_upload/2023/2/2023-2-7-1675763146129.mp4"
}
const home = {
  "home.banner.title": "新东方，老师好",
  "home.brief.title": "关于我们",
  "home.brief.desc": "我们的定位是做一个为客户甄选好物的直播购物平台；以东方甄选品牌的自营农产品为核心的产品和科技公司；为客户带来愉快精神体验的文化传播公司。通过提供优质产品及服务，弘扬中华传统文化及积极价值观，我们希望为每一名客户提供美好、健康、品质生活...",
  "home.vision.title": "企业愿景",
  "home.vision.desc.paragraph.one": "为客户甄选好物的直播购物平台",
  "home.vision.desc.paragraph.two": "以东方甄选品牌的自营农产品为核心的产品科技公司",
  "home.vision.desc.paragraph.three": "为客户带来愉快精神体验的文化传播公司",
  "home.milestone.title": "发展里程碑",
  "home.milestone.seg.2023": "本公司的英文名称已由「Koolearn Technology Holding Limited」更改为「East Buy Holding Limited」，而本公司的双重外文名称已由「新东方在线科技控股有限公司」更改为「东方甄选控股有限公司」。",
  "home.milestone.seg.2021": "成立“东方甄选”，开展自营产品及直播电商业务",
  "home.milestone.seg.2020": "新东方与Tigerstep认购2.3亿美元新东方在线股份",
  "home.milestone.seg.2019": "3月28日 成功登陆港交所，股票代码：1797.HK",
  "home.milestone.seg.2018": "本公司于开曼群岛注册成立为获豁免有限公司。 Dragon Cloud和ChinaCity Capital（我们的B系列首次公开发售前投资者）投资于本集团。 俞先生（新东方的创办人以及我们的董事兼董事会主席）透过Tigerstep认购我们的C系列优先股投资于本集团",
  "home.milestone.seg.2017": "我们推出东方优播，为K-12学生提供的地区性直播互动补习服务",
  "home.milestone.seg.2016": "林芝腾讯投资于本集团",
  "home.milestone.seg.2014": "我们推出自我适应学习系统知心",
  "home.milestone.seg.2012": "我们推出首个英语学习手机应用程序多纳",
  "home.milestone.seg.2007": "我们获得首个机构客户",
  "home.milestone.seg.2006": '新东方教育科技集团纽约证交所上市（NYSE:EDU）',
  "home.milestone.seg.2005": "我们通过境内营运实体北京迅程公司建立业务，推出首个在线教育平台新东方在线",
  "home.team.title": "董事会",
  "home.culture.title": "企业文化",
  "home.culture.spirit.one.paragraph": "秉持“以客户为中心”的战略和“奋斗者为本”的精神",
  "home.culture.spirit.two.paragraph": "秉承新东方精神，薪火相传，成为优秀的产品+科技公司",
  "home.culture.spirit.three.paragraph": "为广大客户提供更好的体验和文化知识收获",
  "home.responsibility.title": "社会责任",
  "home.responsibility.desc": "也许我们做不了太伟大的事情，但作为中国人民的一员，我们如果能够尽一己之力让有些事因为我们的努力而更加美好，有些人，因为我们的帮助而对生活充满希望，我们也会从中体味到一种成就感和幸福感。",
  "home.responsibility.footer": "— — 俞敏洪",
  "home.responsibility.5.title": "新东方在线积极推动教育公平",
  "home.responsibility.5.abstract.0": "“烛光行动”，11年来，培训50000名乡村教师",
  "home.responsibility.5.abstract.1": "“自强之星”，捐赠上亿元，资助上万名大学生",
  "home.responsibility.5.abstract.2": "“希望小学”，共计出资2020万元，援建7所希望小学",
  "home.responsibility.5.abstract.3": "“情系远山”，凝聚15家教育企业，覆盖超过300所学校",
  "home.responsibility.0.title": "新东方在线向丹寨县捐赠108万元公益课",
  "home.responsibility.0.abstract.0": `2021年4月22日，新东方联合新东方在线、BOBO英语等为贵州丹寨县金钟第一小学捐赠200万元学习资源“大礼包”`,
  "home.responsibility.0.abstract.1": `新东方在线捐赠108万元的在线课程公益包，共计300套`,
  "home.responsibility.1.title": `新东方在线发起“燃梦行动”2020公益助学计划`,
  "home.responsibility.1.abstract.0": `2020年10月31日，新东方在线正式发起“燃梦行动-2020公益助学计划”，向贫困家庭、残疾和重疾家庭的学员发放公益助学金`,
  "home.responsibility.1.abstract.1": `助学金以助学券的形式进行发放，共分为一、二、三等，分别为800元助学券、600元助学券及400元助学券`,
  "home.responsibility.2.title": "东方甄选积极宣传非遗文化",
  "home.responsibility.2.abstract.0": "东方甄选自2021年6月以来举办多场专场活动，在活动中宣传非遗文化",
  "home.responsibility.2.abstract.1": `宣传了包含云南孔雀舞、海南木偶戏、山东大鼓等超过50种地方优秀非遗文化`,
  "home.responsibility.3.title": "促进当地农产品销售",
  "home.responsibility.3.abstract.0": "深度溯源，与当地农产品企业合作，帮助企业打开销路",
  "home.responsibility.3.abstract.1": `发挥企业优势，整合资源，创新创造，助力农业发展`,
  "home.responsibility.3.abstract.2": `甄选高品质农品好物，给消费者提供健康，美味，高品质，高性价的产品`,
  "home.responsibility.4.title": "“知识带货”倡导全民阅读和健康生活理念",
  "home.responsibility.4.abstract.0": "东方甄选主打“知识带货”直播特色，倡导全民阅读及健康生活理念，并邀请各界文化大咖",
  "home.responsibility.4.abstract.1": `半年内累计图书销售达到1,244万册`,
  "home.responsibility.4.abstract.2": `茅盾文学奖获奖作品《额尔古纳河的右岸》累计销量超过50万册`,
  "home.news.title": "新闻动态",
}
const business = {
  "business.k12.title": "K-12教育",
  "business.k12.desc": "我们的K-12教育服务包括课外辅导课程，当中涵盖中国小学至高中的大部分标准学校科目。同时，我们提供专为普通高等学校招生全国统一考试而设的备考课程。我们于2015年推出K-12课程。凭借我们于K-12方面的专长，我们亦于2017年推出创新的东方优播课程，该课程为一项针对低线城市学生提供的地区性直播互动辅导服务，该等城市未必拥有便捷的线下基础设施，同时存在随时随地有效连接线上平台的需求增长。于2020财年，此分部的付费学生人次为185.6万名，占我们的付费学生人次总数的65.1%。",
  "business.more": '去官网',
  "business.selfOperatedGoods.title": "自营产品及直播电商",
  "business.selfOperatedGoods.desc01": "自2021年以来，我们成立电商平台东方甄选，依托新东方集团的品牌知名度及多年的运营历史，我们的直播教室技术和现有人才库，通过使用直播营销销售以农产品、日用品及图书为主的优质产品，我们开始在抖音及腾讯商城等不同的社交媒体平台试点直播电商活动。",
  "business.selfOperatedGoods.desc02": "东方甄选已成为一家以提供“东方甄选”自营农产品为内核产品的知名产品和科技公司，且该平台构成严格的供应链管理和售后服务体系的环节，严格遵守一系列相关法律法规。我们制定了极其严格的准入标准来筛选优质供货商，使每款产品的工艺更精良、成份表更单纯。凭借对客户需求的深刻理解及客户反馈，我们通过快速发展自营品牌继续扩大我们的产品选择及SKU。通过创新的内容、多样化的直播活动并加上我们广博的知识和文化分享，我们为客户提供了独特而愉悦的购物体验。",
  "business.selfOperatedGoods.desc03": "自营品方面，我们在产品开发到销售服务的全链路的严格控制过程中会不断引进和应用科学先进的技术，提升用户体验。未来会依托互联网技术的发展进一步提升产品的科技优越性。截止2022年11月底，自营产品数量已达到65款，包括不同口味的黑猪肉烤肠、五常大米、蓝莓原浆及其他热门产品。我们已收到大量有价值的客户反馈，热销产品的产品种类及比例将继续增加。",
  "business.selfOperatedGoods.desc04": "此外，我们采用了远高于行业平均水平的抽检频次。东方甄选将质检部门升级为食品安全中心和质量检验科研中心，并和国内一流质检机构合作，管理每个产品的质检流程。农残、药残、抗生素、微生物等检测项，严格按照东方甄选的产品要求，做到应检尽检。",
  "business.selfOperatedGoods.desc05": "我们不断优化升级服务体验，报告期内和顺丰、京东展开深度合作，不断提升配送质量和冷链配送范围。同时，我们建立了会员体系，截至2022年12月底，累计拥有800万+会员。不断提升的品牌影响力使得更多的国内外厂商，零售商，生产者认可我们的模式促进合作，而不断优化的自营品的服务体验，促进了客户复购率持续提升。得益于产品生产流水品质，开发原则，高强度质检，物流及会员体验，因此，东方甄选自营产品的抖音旗舰店信誉等级是最高等级，店铺评分4.99分，超过100%同行。店铺细项评分均为“高”，商品体验、服务体验、物流体验均接近满分。",
  "business.college.title": "大学教育",
  "business.college.desc": "我们是中国领先的在线课外教育服务供应商，持续提供大学备考及海外备考课程，课程主要供正准备应考统一考试或寻求提高英语能力的大学学生及在职专业人士。凭借优质课程及内容，我们于在线校外辅导及备考方面拥有强大的核心竞争力和极高的品牌知名度。我们致力成为终身学习伙伴，帮助学生充分发挥潜能。我们通过不同的在线平台及手机应用程序以多种形式提供课程及产品。",
  "business.preSchool.title": "学前教育",
  "business.preSchool.desc": "通过多纳学英语及儿童教育应用程序，我们的学前教育分部提供适合儿童的在线教育内容。我们于2012年推出多纳应用程序系列，于2020财年，此分部的付费学生人次为5.4万名，占我们的付费学生人次总数的1.9%。",
  "business.customers.title": "机构客户",
  "business.customers.desc": "我们向机构客户（主要包括高等学校、公共图书馆、电信营运商及在线流媒体视频供应商）提供服务。我们不断加深与不同学校和机构的合作，在职业教育方面，我们与多所院校就“新时代工匠学院”项目及新能源与智慧建筑项目进行研究交流，并计划与部分高职院校和本科院校就产业研究院建设及新型人才培养项目进行深度研究与规划。在中小学和城市图书馆方面，我们拥有“东方树”素质教育平台，整合科技、编程、美育、体育及人文等优质内容，并与学校及培训机构合作。",
  // "business.companies.title": "联营业务",
  "business.companies.desc": "我们积极寻求企业合作，拓展业务领域。目前，我们的联营合作伙伴包括创客教育公司寓乐湾、翼鸥教育和北京时代云图有限公司。",
  "business.brands.title": "相关品牌",
  "business.download.title": "立即下载",
  "business.download.koolearn": "新东方在线",
  "business.download.donut": "多纳学英语",
  "business.download.lboc": "乐播课",
  "business.download.college": "新东方在线大学",
  "business.contact.title": "联系我们",
  "business.contact.company.name": "东方甄选控股有限公司",
  "business.contact.company.adress": "地址：北京市海淀区海淀东三街2号新东方南楼18层",
  "business.contact.company.tel": "电话：4000686666",
  "business.contact.company.time": "服务时间：8:00-24:00",
  "business.cooperation.title": "合作洽谈",
  "business.cooperation.business.title": "商务合作",
  "business.cooperation.business.contact": "联系人：陈老师",
  "business.cooperation.business.email": "E-mail：xuanpin@xdfzx.com",
  "business.cooperation.market.title": "市场合作及媒体联络",
  "business.cooperation.market.contact": "联系人：黄老师",
  "business.cooperation.market.email": "E-mail：mkt@xdfzx.com",
  "business.cooperation.online.title": "网盟合作/线上代理",
  "business.cooperation.online.contact": "联系人：侯老师",
  "business.cooperation.online.email": "E-mail：houjie01@xdfzx.com",
  "business.followus.title": '东方甄选·关注我们',
  "business.followus.gongzhonghao": '公众号',
  "business.followus.douyin": '抖音',
  "business.followus.kuaishou": '快手',
  "business.followus.weibo": '微博',
  "business.followus.xiaohongshu": '小红书',
  "business.followus.tianmao": '天猫',
  "business.followus.jingdong": '京东',
  "business.followus.title1": '东方甄选官方',
  "business.followus.title2": '东方甄选会员',
  "business.followus.title3": '东方甄选',
  "business.followus.title4": '东方甄选之图书',
  "business.followus.title5": '东方甄选自营产品',
  "business.followus.title6": '东方甄选美丽生活',
  "business.followus.title7": '东方甄选看世界',
  "business.followus.title8": '东方甄选将进酒',

}
const employee = {
  "employee.talent.title": "人才发展",
  "employee.talent.desc": `东方甄选人才培养体系，以国际先进人才培养模型——TAT为项目设计理念，集“三力七要素”和哈佛“领导力管道”为理论设计根本，以建立健全人才培养体系、领导力培养体系和综合评价体系为抓手，为东方甄选人才发展提供坚实理论保障和系统性、专业性的培养支持，从而构建了以东方甄选文化相结合的人才发展体系。`,
  "employee.talent.table.title": "东方甄选人才发展体系",
  "employee.talent.table.col1.1": "HPD",
  "employee.talent.table.col1.2": "Leadership",
  "employee.talent.table.col1.3": "General Force",
  "employee.talent.table.col1.4": "Business",
  "employee.talent.table.col1.5": "New Employee",
  "employee.talent.table.col1.6": "Staff Level",
  "employee.talent.table.col2.1": "需求评估、目标设定、战略共识、能力建模、人才盘点、高潜系课程、效果评定",
  "employee.talent.table.col2.2": "团队管理、横向管理、对内管理、对外影响、谈判技巧、战略管理",
  "employee.talent.table.col2.3": "有效沟通、执行力、时间管理、目标管理、职业生涯规划、精力管理",
  "employee.talent.table.col2.4": "销售客服端培训项目、管培生培训项目、产研培训项目、学习交流机制（内外部）",
  "employee.talent.table.col2.5": "企业文化与价值观、新员工培养项目、员工福利",
  "employee.talent.table.col2.6": "大讲堂、读书俱乐部、外训、Koo Show",
  "employee.talent.table.col3.1": "Professional Pro+Sales Pro+PM Pro+Technical Pro+Teaching+Management Pro",
  "employee.talent.table.col4.1": "Pro Operation+Koo吧+微信社群+H5+学习与发展中心+邮件+公众号+海报",
  "employee.leadership.table.title": "东方甄选领导力培养体系",
  "employee.leadership.table.col1.1": "Talent Review",
  "employee.leadership.table.col1.1.subtile": "高潜",
  "employee.leadership.table.col1.2": "Leadership",
  "employee.leadership.table.col1.2.subtile": "航系",
  "employee.environment.title": "办公环境",
  "employee.activities.title": "员工活动",
  "employee.activities.party.title": "公司年会",
  "employee.activities.party.desc": "每年年底举办的年会，是重要的活动之一。",
  "employee.activities.team.title": "团建活动",
  "employee.activities.team.desc": "各部门积极开展团建活动，是增强部门凝聚力和协同力的重要方式。",
  "employee.activities.sports.title": "专场活动",
  "employee.activities.sports.desc": "公司不定期安排专场直播活动，员工可以从中体验当地民俗特色，发挥个人特长。",
  "employee.activities.welfare.title": "回馈社会",
  "employee.activities.welfare.desc": "通过多种形式的“送温暖、爱心传递”活动，公司用实际行动回馈社会、关爱社会，赢得社会的尊重和信任。",
  "employee.join.title": "加入我们",
  "employee.join.social.title": "社会招聘",
  "employee.join.social.desc": "加入我们，共同定义“XIN世界”",
  "employee.join.social.link": "去社招首页",
  "employee.join.campus.title": "校园招聘",
  "employee.join.campus.desc": "寻找“XIN上人”",
  "employee.join.campus.link": "去校招首页",
}
const investor = {
  "investor.company.vedio": "https://s3.koolearn.com/86557e7c.mp4",
  "investor.company.desc": `我们的定位是做一个为客户甄选好物的直播购物平台；以东方甄选品牌的自营农产品为核心的产品和科技公司；为客户带来愉快精神体验的文化传播公司。`,
  "investor.stock.title": "股价信息",
  "investor.corporate.title": "公司管治",
  "investor.chairman.title": "董事会",
  "investor.audit.title": "审核委员会",
  "investor.remuneration.title": "薪酬委员会",
  "investor.nomination.title": "提名委员会",
  "investor.governance.title": "治理文件",
  "investor.financial.title": "财务报告",
  "investor.financial.subtitle": "(财务报告从2019年8月16日之日起)",
  "investor.announcements.title": "公告及通函",
  "investor.announcements.subtitle": "(公告从2019年2月1日之日起)",
  "investor.pressrelease.title": "新闻发布",
  "investor.analyst.title": "分析师列表",
  "investor.email.title": "电邮订阅",
  "investor.analyst.desc": "来自不同机构的54位专业分析师",
  "investor.analyst.link": "查看全部",
  "investor.analyst.disclaimer": "免责声明：",
  "investor.analyst.disclaimer.content": "上述分析师提供东方甄选控股有限公司（“东方甄选”）的股票分析。任何关于对东方甄选的分析、评估、预测和推荐仅代表他们的个人意见，不代表东方甄选和管理层的意见。东方甄选不对上述分析师做出的分析、评估、预测和推荐提供任何指导、暗示或参考意见。",
  "investor.audit.dongruibao.position": "委员会主席",
  "investor.audit.linzheying.position": "委员会成员",
  "investor.audit.kuangweixin.position": "委员会成员",
  "investor.remuneration.linzheying.position": "委员会主席",
  "investor.remuneration.sunchang.position": "委员会成员",
  "investor.remuneration.dongruibao.position": "委员会成员",
  "investor.nomination.yuminghong.position": "委员会主席",
  "investor.nomination.linzheying.position": "委员会成员",
  "investor.nomination.dongruibao.position": "委员会成员",
}
const contact = {
  "contact.investor.title": "投资者/分析师",
  "contact.investor.name": "宋㛃",
  "contact.investor.position": "投资者关系总监",
  "contact.investor.company": "东方甄选控股有限公司",
  "contact.investor.address": "地址：北京市海淀区海淀东三街2号新东方南楼18层",
  "contact.investor.tel": "电话：+8610 6260 9213",
  "contact.investor.fax": "传真：+8610 6260 9299",
  "contact.investor.email": "电邮：songjie@eastbuy.com",
  "contact.media.title": "新闻媒体",
  "contact.media.name": "陆淼",
  "contact.media.position": "副总裁",
  "contact.media.company": "博达浩华国际财经传讯集团",
  "contact.media.address": "地址： 香港中环云咸街60号中央广场23楼2301室",
  "contact.media.tel": "电话： (852) 3150 6788",
  "contact.media.fax": "传真： (852) 3150 6728",
  "contact.media.email": "电邮： eastbuy.hk@pordahavas.com"
}
const brief = {
  "brief.title": "关于我们",
  "brief.paragragh1": '我们的定位是做一个为客户甄选好物的直播购物平台；以东方甄选品牌的自营农产品为核心的产品和科技公司；为客户带来愉快精神体验的文化传播公司。通过提供优质产品及服务，弘扬中华传统文化及积极价值观，我们希望为每一名客户提供美好、健康、品质生活。',
  "brief.paragragh2": "东方甄选控股有限公司（前身为新东方在线科技控股有限公司）由新东方于2005年成立，新东方为中国民办教育行业的先驱，拥有近三十年的经验。秉承改善公众福祉，为社会创造价值的使命，自2021年以来，我们拓展自营产品及直播电商业务并成立了东方甄选，其已成为销售高质量、高性价比的农业及其他产品的知名平台。目前，我们的业务涵盖自营产品及直播电商、大学教育、海外备考和机构合作。",
  "brief.paragragh3": "目前，遵循“以客户为中心”的战略，东方甄选已在抖音开设六个直播账号，形成直播矩阵，产品类别及SKU亦逐步扩大，目前包括农产品、食品、图书及生活用品，我们的品牌在行业中的地位日益突出，获得了数百万回头客和忠实的粉丝及会员。我们通过严格的供应链管理及与不同第三方的多元化合作，帮助人们选择优质农业及其他产品，同时通过提供东方甄选品牌下的多种自营产品为客户创造价值，这些产品健康、高品质且高性价比，包括生鲜食品、果汁、咖啡、茶、床上用品等。此外，东方甄选注重开发文化内容，并已开始加大与地方政府的合作，以推广特色农产品、为文化旅游贡献力量，为社会创造价值。",
}
const news = {
  "news-2021-03-18-title": "打造用户信任品牌，新东方在线获“2021中国消费者品牌榜之卓越品牌",
  "news-2021-03-18-desc": "3月15日，由每日经济新闻主办的315消费者权益日主题活动——“美好生活2021中国消费者品牌榜”评选正式揭榜。凭借良好的品牌形象以及行业影响力，新东方在线（01797.HK）入选并获评“2021中国消费者品牌榜之卓越品牌”。",
  "news-2021-02-07-title": "俞敏洪现身“云过年，心团圆”公益直播，携新东方在线免费送好课",
  "news-2021-02-07-desc": "2月3日，“云过年，心团圆”线上直播在快手创新中国直播间举办。本次直播由共青团哈尔滨市委员会、共青团沈阳市委员会、共青团大连市委员会、共青团石家庄市委员会等单位共同发起“2021年大学生在家过大年”倡议。此外，直播还携手中国网家家创新中国频道及多家互联网平台共同举办的公益直播活动，旨在为今年春节全国在家和留校过春节的大学生们，带来一场兼具人情味和书香气的体验。据悉，新东方在线受邀参加，为大学生们带来了在家免费学的一批优质在线课程。",
  "news-2021-02-04-title": "品质成就口碑，新东方在线荣登“在线教育品牌影响力TOP15",
  "news-2021-02-04-desc": "1月28日，新京报教育频道联合千龙智库共同发布《2020年度“UP新力量”教育品牌影响力舆情报告》。新东方在线凭借良好的用户口碑和品牌影响力，荣登报告公布的“在线教育品牌影响力TOP15”榜单，位列第二名。",
  "news-2021-02-01-1-title": "用实力打造口碑，新东方在线获鲸媒体“最受用户欢迎教育产品”奖项",
  "news-2021-02-01-1-desc": "近日，教育行业媒体鲸媒体发起评选的“鲸媒体2020教育行业年度榜单”正式公布。新东方在线凭借良好的用户口碑和教学服务保障，入选并获评“最受用户欢迎教育产品”荣誉称号。",
  "news-2021-02-01-2-title": "2020中国网科技企业先锋榜发布，新东方在线获“年度品牌影响力企业”",
  "news-2021-02-01-2-desc": "2021年1月28日，由中国互联网新闻中心(中国网)主办的“2020中国网科技企业先锋榜”评选活动正式结束。新东方在线凭借在公益探索、就业保障等方面的卓越表现，获评“年度品牌影响力企业”。",
  "news-2021-01-27-title": "打造在线教育公益新样本 新东方在线捐赠30万份公益课",
  "news-2021-01-27-desc": "1月11日，新东方在线携手民盟中央社会服务部与新东方公益基金会，发起“梦想公益课”助学活动。面向云南、四川、贵州、甘肃等教育欠发达地区，新东方在线将捐赠覆盖小学至高中的在线课程，来自偏远地区的孩子可通过在线学习的形式，获得优质的教育资源。据悉，截至1月18日，新东方在线已捐赠30万份梦想公益课，这些课程将由民盟中央社会服务部发往各地，送到孩子的手中。",

}

const advertiseMessage={
  // 'advertiseMessage.title':'股东公司通讯的安排',
  // 'advertiseMessage.href':'https://s1.eastbuy.com/ir/HKGSR01A-%23689643-v3-East_Buy_-_Paperless_Regime_-_Website_notice_(CN).pdf',
  // 'advertiseMessage.desc':'网站通告',
  // 'advertiseMessage.date':'2023-12-29'
  'advertiseMessage.title':'网站通告',
  'advertiseMessage.desc':'股东公司通讯的安排',
  'advertiseMessage.content':[
    {
      desc:'本公司的股东通讯方式将于 2024 年转变为电子通讯方式',
      info:[
        '本公司现时向股东寄发公司通讯的印刷本。作为本公司提高环保意识举措的一环，本公司将于2024 年第一季度转为电子通讯方式。根据该最新通讯方式，我们将默认不向股东派发公司通讯的印刷本。',
        '于采纳该最新电子通讯方式前，本公司将向各股东存置于香港股份过户登记处的登记地址寄发初始通知函。该函件将详述最新电子通讯方式，并载有股东向本公司知会╱更新其指定功能电邮地址及╱或提出在一定时限内股东收取若干公司通讯的印刷本的具体要求的途径。'
      ]
    },
    {
      desc: '查阅本公司的公司通讯的方式',
      info:[
        '全体股东均可于本公司网站(ir.eastbuy.com)及香港交易及结算所有限公司网站(<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>)查阅及下载公司通讯的电子版本，包括初始通知函的电子版本。该等网站将提供中英文（如有）两种语言版本的公司通讯，英文版本为文件的官方版本。'
      ]
    },
    {
      desc:'公司通讯简介',
      info:[
        '「公司通讯」指由本公司发出或拟发出以供股东提供具体数据或采取具体行动的文件，包括但不限于中期报告及年度报告、股东大会通告、代表委任表格、通函及上市文件。'
      ]
    }
  ],
  'advertiseMessage20240118.desc':'致股东公司通讯之安排',
  'advertiseMessage20240118.content':[
    {
      desc:'',
      info:[
        '<div>作为本公司对环境保护更加负责的一份努力，我们强烈鼓励所有股东选择以电子方式收取本公司日后的公司通讯。 </div>'
      ]
    },
    {
      desc:'何处可获取本公司公司通讯？',
      info:['<div>根据本公司现行惯例，我们将不会预设向股东派发公司通讯的印刷本。</div>',
      '<div>所有股东均可于本公司网站(ir.eastbuy.com)及香港交易及结算所有限公司网站(<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>)阅览及下载公司通讯的电子版本。公司通讯的英文版本及中文版本（如有）均将载于该等网站，而英文版本将为相关文件的正式版本。</div>'
      ]
    },
    {
      desc:'能否索取公司通讯的印刷本？',
      info:[`<div>尽管本公司不鼓励以实物派发公司通讯，但本公司理解若干股东可能因特定原因而需要印刷本。本公
      司将接受个别股东提出的特定书面要求，免费提供公司通讯印刷本。</div>`,
      `
      <table style='border-collapse: collapse;border: 1px solid #aaa;width:100%;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 1px 1px 1px 1px;vertical-align: text-top;'>欲索取公司通讯印刷本之股东</td>
          <td style="padding: 1px 1px 1px 1pxx;vertical-align: text-top;">阁下的要求一经接获及处理，本公司将于要求期限内刊登公司通讯，并将邮寄至阁下存置于香港股份<过户登记处的登记地址（或获提供的公司通讯指定邮件地址）。</td>
          <td  style='border: 1px solid #3F3F3F;'>
            <div style='color:#000000;'>就登记持有人而言：</div>
            <div>阁下可填写<span style="text-decoration: underline;">登记持有人回条的选项3（请参阅下文）</span>提出此要求。</div>
            <div>每项请求的有效期仅为自收到阁下指示之日起计<strong style="text-decoration: underline;">一年</strong>。</div>
            <div style='color:#000000;'>就非登记持有人而言：</div>
            <div>阁下可填写<span style="text-decoration: underline;">非登记持有人回条（请参阅下文）</span>提出此要求。</div>
            <div>我们要求股东在提出此要求时应注意为阁下编制及交付公司通讯印刷本对环境的影响。</div>
          </td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'何处可获取可供采取行动的公司通讯？',
      info:[`<div>可供采取行动的公司通讯亦将在本公司网站(ir.eastbuy.com)及香港交易及结算所有限公司网站(<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>)上获取。 </div>`,
      `<div>此外，本公司亦将向阁下单独发送可供采取行动的公司通讯，本公司建议阁下透过扫描回条上打
      印的阁下专属二维码来提供阁下的电子邮件地址，或者，阁下亦可签署回条并交回本公司的香
      港股份过户登记处香港中央证券登记有限公司，地址为香港湾仔皇后大道东183号合和中心17M
      楼。</div>`,
      `<div>如本公司没有收到阁下的有效电子邮件地址，直至香港股份过户登记处收到阁下有效的电子邮件
      地址前，本公司未来将以印刷本形式发送可供采取行动的公司通讯。 </div>`
      ]
    },
    {
      desc:'如何提交回条？',
      info:['<div> 股东应将填妥及签署的「登记持有人回条」或「非登记持有人回条」（如阁下透过中介持有本公司股份）送交香港股份过户登记处：</div>',
      `<table style='border-collapse: collapse;border: 1px solid #aaa;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>香港股份过户登记处</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">香港中央证券登记有限公司</td>
        </tr>
        <tr>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>登记邮箱地址：</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">香港湾仔皇后大道东183号合和中心17M楼</td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'回条连接',
      info:[`<ul> 
            <li><a target="_blank" href='https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(RH)%20(combined)_ESS_%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Registered%20Holders.pdf'>登记持有人回条</a></li>
            <li><a target="_blank" href="https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(NRH)%20(combined)_ESS_%E9%9D%9E%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Non%20Registered%20Holders.pdf">非登记持有人回条</a></li>
      </div>`
      ]
    },
    {
      desc:'关键术语',
      info:[`<ul> 
        <li>「公司通讯」指本公司为股东提供具体数据或采取行动而发出或将要发出的文件，包括但不限
        于中期及年度报告、股东大会通告、代表委任表格、通函及上市文件。</li>
        <li>「可供采取行动的公司通讯」是指需要股东采取具体行动的公司通讯，包括但不限于股东选举
        表格、申请表格及接纳表格。</li>
      </ul>`
      ]
    }
  ]
} 



const zh_CN = { ...global, ...header, ...home, ...business, ...employee, ...investor, ...contact, ...brief, ...news,...advertiseMessage }
export default zh_CN;