import React from 'react'
import { useTranslation } from 'react-i18next'

import xdfLogo from '../../img/xdf.png'
// import txLogo from '../../img/tx.png'

function Footer() {
  const { i18n } = useTranslation();
  const isZh = i18n.language === 'zh';
  const toLink = url => {
    window.open(url);
  }
  return (
    <div className="footer">
      <div className="f-txt">Copyright © 2000-2023 EastBuy. All rights reserved. {isZh ? '' : ''} <br /> <span>京ICP证050421号 <a href="https://beian.miit.gov.cn/" className="link-a"> 京ICP备05067669号-34</a></span> </div>
      <div className="f-brand">
        <span className="f-brand-item" onClick={() => toLink('http://www.neworiental.org/')}>
          <img src={xdfLogo} alt="" />
        </span>
        {/* <span className="f-brand-item" onClick={() => toLink('https://www.tencent.com/zh-cn/index.html')}>
          <img src={txLogo} alt="" />
        </span> */}
      </div>
    </div>
  )
}

export default Footer;;require('./index.less')