import React, { useState, useEffect } from 'react'
import classnames from 'classnames';
import { trackClick } from '@/utils';

import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { trackMap, sectabMap } from '../../config/trackMap'
import { subMenu, subMenuEn } from '../../config/menu'

let current = '';
let sectabName = '';
const routeMap = {
  'brief': '/',
  'business': '/business',
  'employee': '/employee',
  'invest': '/investor',
  'topconcat': '/contacts',
  '/brief': "/brief"
}

const currentRouteForTabMap = {
  '/': 'nav_brief',
  '/brief': 'nav_brief',
  '/news': 'nav_brief',
  '/business': 'nav_business',
  '/employee': 'nav_employee',
  '/investor': 'nav_invest',
  '/investor/brief': 'nav_invest',
  '/investor/governanceDocuments': 'nav_invest',
  '/investor/pressRelease': 'nav_invest',
  '/contacts': 'nav_topconcat',

}


function MainNav(props) {
  const { t, i18n } = useTranslation();
  const [maskVisible, setMaskVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [hoverTab, setHoverTab] = useState(-1);
  const [clickTab, setClickTab] = useState(-1);
  const [mouseOut, setMouseOut] = useState(false);
  let menuMap;
  if (i18n.language === 'en') {
    menuMap = subMenuEn;
  } else {
    menuMap = subMenu;
  }

  useEffect(() => {
    const { pathname } = props.location;
    const lastPage = props.location.state && props.location.state.lastPage;
    if (lastPage) {
      setCurrentTab(currentRouteForTabMap[lastPage])
    } else {
      setCurrentTab(currentRouteForTabMap[pathname])
    }
  }, [props.location])
  function show(e, index) {
    setMaskVisible(true);
    setHoverTab(index);
    setMouseOut(false);
  }

  function hide() {
    setMaskVisible(false);
    setClickTab(-1);
    setMouseOut(true);
  }

  function titleClick(e, index) {
    setMaskVisible(false);
    setClickTab(index);
    current = e.target.getAttribute('mainkey');
    setCurrentTab(current)
    // 埋点上报
    trackClick({
      'toptab': current.split('_')[1]
    });
    // 路由跳转
    props.history.push(routeMap[current.split('_')[1]]);
  }

  function menuClick(e, index) {
    setMaskVisible(false);
    setClickTab(index);
    current = e.target.getAttribute('subkey').split('?')[0];
    sectabName = e.target.getAttribute('subkey').split('?')[1];
    let subname = e.target.getAttribute('href')
    setCurrentTab(`nav_${current}`);
    // 是否跳转详情页
    let path = e.target.getAttribute('path')

    if (path) {
      // 一级页面埋点
      trackClick({
        'toptab': trackMap[path]
      });
      // 路由跳转
      props.history.replace(path);
    } else {
      // 二级页面埋点
      // events & news 跳转到中文页
      if (sectabName === 'events') {
        // 切换新页面的语言
        localStorage.setItem("language", 'zh')
        // 新页面打开指定页，todo写死待优化
        console.log(window.location.href,"subname")
        let href=window.location.href.slice(0,window.location.href.lastIndexOf('#')+1);
        window.open(`${href}/${subname}`)
        return
      }
      trackClick({
        'sectab': sectabMap[sectabName]
      });
      // 路由跳转
      props.history.replace({ pathname: routeMap[current], state: { anchorName: `${sectabName}+${new Date()}` } });
    }
  }
  return (
    <div className={
      classnames("nav-container", {
        'en': i18n.language === 'en',
        'zh': i18n.language === 'zh',
        'hk': i18n.language === 'hk',
      })
    }>
      <div id="parent">
        <ul className="nav-wrap">
          <li
            className={
              classnames(
                "li-item",
                "sub-menu",
                { "hover": hoverTab === 0 && !mouseOut },
                { "click": clickTab === 0 && !mouseOut }
              )
            }
            onMouseOver={e => { show(e, 0) }}
            onMouseOut={e => { hide() }}
          >
            <span onClick={(e) => { titleClick(e, 0) }} className={classnames("title", { "navactive": currentTab === 'nav_brief' })} id="nav_brief" mainkey="nav_brief">{t('header.menu.name.home')}</span>
            <ul className={classnames("subtitle")} onMouseOver={show}>
              {
                menuMap['index'].map((menu, index) => (
                  <li onClick={e => { menuClick(e, 0) }} className="sub-title" key={`${menu.name}?${index}`} subkey={`brief?${menu.name}`} path={menu.path} href={menu.href}>{t(`header.submenu.name.${menu.name}`)}</li>
                ))
              }
            </ul>
          </li>
          <li
            className={
              classnames(
                "li-item",
                "sub-menu",
                { "hover": hoverTab === 1 && !mouseOut },
                { "click": clickTab === 1 && !mouseOut }
              )}
            onMouseOver={e => { show(e, 1) }}
            onMouseOut={e => { hide() }}>
            <span onClick={(e) => { titleClick(e, 1) }} className={classnames("title", { "navactive": currentTab === 'nav_business' })} id="nav_business" mainkey="nav_business">{t('header.menu.name.business')}</span>
            <ul className={classnames("subtitle")}>
              {
                menuMap['business'].map((menu, index) => (
                  // eslint-disable-next-line react/no-danger-with-children
                  <li onClick={e => { menuClick(e, 1) }} className={`sub-title ${menu.name}`} key={`${menu.name}?${index}`} subkey={`business?${menu.name}`} path={menu.path} dangerouslySetInnerHTML={{
                    __html: t(`header.submenu.name.${menu.name}`)
                  }}></li>
                ))
              }
            </ul>
          </li>
          <li
            className={
              classnames(
                "li-item",
                "sub-menu",
                { "hover": hoverTab === 2 && !mouseOut },
                { "click": clickTab === 2 && !mouseOut })}
            onMouseOver={e => { show(e, 2) }}
            onMouseOut={e => { hide() }}
          >
            <span onClick={(e) => { titleClick(e, 2) }} className={classnames("title", { "navactive": currentTab === 'nav_employee' })} id="nav_employee" mainkey="nav_employee">{t('header.menu.name.employee')}</span>
            <ul className={classnames("subtitle")}>
              {
                menuMap['employee'].map((menu, index) => (
                  <li onClick={e => { menuClick(e, 2) }} className="sub-title" key={`${menu.name}?${index}`} subkey={`employee?${menu.name}`} path={menu.path}>{t(`header.submenu.name.${menu.name}`)}</li>
                ))
              }
            </ul>
          </li>
          <li
            className={
              classnames(
                "li-item",
                "sub-menu",
                { "hover": hoverTab === 3 && !mouseOut },
                { "click": clickTab === 3 && !mouseOut })}
            onMouseOver={e => { show(e, 3) }}
            onMouseOut={e => { hide() }}
          >
            <span onClick={(e) => { titleClick(e, 3) }} className={classnames("title", { "navactive": currentTab === 'nav_invest' })} id="nav_invest" mainkey="nav_invest">{t('header.menu.name.investor')}</span>
            <ul className={classnames("subtitle")}>
              {
                menuMap['invest'].map((menu, index) => (
                  <li onClick={e => { menuClick(e, 3) }} className="sub-title" key={`${menu.name}?${index}`} subkey={`invest?${menu.name}`} path={menu.path}>{t(`header.submenu.name.${menu.name}`)}</li>
                ))
              }
            </ul>
          </li>
          <li className="li-item only-child" onMouseOver={e => { hide(e) }}>
            <span onClick={titleClick} className={classnames("only-child-title", { "navactive": currentTab === 'nav_topconcat' })} id="nav_topconcat" mainkey="nav_topconcat">{t('header.submenu.name.contacts')}</span>
          </li>
        </ul>
        {maskVisible && <div className="mask"></div>}
      </div>
    </div>

  )
}

export default withRouter(MainNav);;require('./index.less')