import Brief from '../pages/brief/index'
import Business from '../pages/business'
import Employee from '../pages/employee'
import Invest from '../pages/invest'
import Contact from '../pages/contact'
import PressRelease from '../pages/pressRelease'
import GovernanceDocuments from '../pages/governanceDocuments'
import PageNotFound from '../pages/pageNotFound'
import BriefForInvestor from '../pages/briefForInvestor/index'
import News from '../pages/news'
import Index from '../pages/index'
import {subMenu,subMenuEn} from '../config/menu'
const routes = [
    {
        path:'/',
        component:Index,
        name:'home',
        children:subMenu['index'],
    },
    {
        path:'/business',
        component:Business,
        name:'business',
        children:subMenu['business'],
    },{
        path:'/employee',
        name:'employee',
        component:Employee,
        children:subMenu['employee'],
    },{
        path:'/investor',
        component:Invest,
        name:'investor',
        children:subMenu['invest'],
    },{
        path:'/contacts',
        name:'contacts',
        component:Contact,
    },
    {
        path:'/news',
        name:'新闻',
        notMenu:true,
        component:News
    },
    {
        path:'/brief',
        notMenu:true,
        component:Brief,
    },
    {
        path:'/investor/brief',
        notMenu:true,
        component:BriefForInvestor,
    },
    {
        path:'/investor/pressRelease',
        notMenu:true,
        component:PressRelease,
    },
    {
        path:'/investor/governanceDocuments',
        notMenu:true,
        component:GovernanceDocuments,
    },
    {
        path:'/404',
        notMenu:true,
        component:PageNotFound,
    }
]
const routesEn = [
    {
        path:'/',
        component:Index,
        name:'home',
        children:subMenuEn['index'],
    },
    {
        path:'/business',
        component:Business,
        name:'business',
        children:subMenu['business'],
    },{
        path:'/employee',
        name:'employee',
        component:Employee,
        children:subMenu['employee'],
    },{
        path:'/investor',
        component:Invest,
        name:'investor',
        children:subMenu['invest'],
    },{
        path:'/contacts',
        name:'contacts',
        component:Contact,
    },
    {
        path:'/news',
        name:'新闻',
        notMenu:true,
        component:News
    },
    {
        path:'/brief',
        notMenu:true,
        component:Brief,
    },
    {
        path:'/invest/pressRelease',
        notMenu:true,
        component:PressRelease,
    },
    {
        path:'/invest/governanceDocuments',
        notMenu:true,
        component:GovernanceDocuments,
    },
    {
        path:'/404',
        notMenu:true,
        component:PageNotFound,
    }
]
export {
    routes,
    routesEn
};