import Layout from './components/layout'
import './App.less';
import './config/i18n.js'
function App() {
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
;require('./App.less')