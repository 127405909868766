/**
 * 获得导航菜单中的锚点信息
 * @param {*} anchorMenu  导航菜单
 */
function getAnchorPosition(anchorMenu){
    let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){ // Safiri计算scrollTop
        scrollTop =  window.pageYOffset;
    }
    return anchorMenu.map(menu => {
        const idForAnchor = menu.name || menu;
        const el = document.getElementById(idForAnchor);
        if(el){
            return {name:idForAnchor,height:Math.floor(el.getBoundingClientRect().top)+scrollTop}
        }else{
            return false;
        }
    }).filter(obj => obj)
}

/**
 * 点击id滚动到锚点位置
 */

function anchorTo({elementId,offset=0,behavior='smooth'}){
    let el = document.getElementById(elementId);
    let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){ // Safiri计算scrollTop
        scrollTop =  window.pageYOffset;
    }
    if(el){
        const top = el.getBoundingClientRect().top+scrollTop;
        window.scrollTo({top:top + offset,behavior});
    }
}

/**
 * 获取滚动条滚动的距离
 */
function getScrollTop(){
    let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){ // Safiri计算scrollTop
        scrollTop =  window.pageYOffset;
    }
    return scrollTop;
}

export {
    getAnchorPosition,
    anchorTo,
    getScrollTop
}