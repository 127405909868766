import React, { useState, useEffect } from 'react';
import SubNav from '../../components/subNav';
import Banner from '../../components/banner';
import { subMenu } from '../../config/menu';
import { anchorTo } from '@/utils/common.js'
import bannerImg from '../../img/employee.png'
import workImg1 from '../../img/environment1.png'
import workImg2 from '../../img/environment2.png'
import workImg3 from '../../img/environment3.png'
import leadershipImg from '../../img/leadership.png'
import a1 from '../../img/a1.png'
import a2 from '../../img/a2.png'
import a3 from '../../img/a3.png'
import a4 from '../../img/a4.png'

import { useTranslation } from 'react-i18next'


const menu = subMenu['employee'];

function Employee(props) {
  const { t, i18n } = useTranslation();
  const [fixed, setFixed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  function changeFixed(bool) {
    setFixed(bool);
  }
  useEffect(() => {
    const anchorName = props.location.state?.anchorName;
    const timer = setTimeout(() => {
      setIsLoaded(true);
      if (anchorName) {
        const hrefName = anchorName.split("+")[0];
        anchorTo({ elementId: hrefName });
      } else {
        window.scrollTo({ top: 0 })
      }
    }, 500)
    return function cleanTimout() {
      clearTimeout(timer);
    }
  }, [props.location.state?.anchorName])

  return (
    <div className="employee-container">
      <Banner img={bannerImg} alt="员工" />
      <SubNav menu={menu} changeFixed={changeFixed} isLoaded={isLoaded} />
      <div style={{ height: `${fixed ? `58px` : '0px'}` }}></div>
      <section id="talentDevelopment">
        <div className="p_title">{t('employee.talent.title')}</div>
        <div className="p_regular">
          {t('employee.talent.desc')}
        </div>
        <div className="p_table">
          <div className="p_table_title">{t('employee.talent.table.title')}</div>
          <table className="p_table1">
            <tbody>
              <tr>
                <td>{t('employee.talent.table.col1.1')}</td>
                <td>{t('employee.talent.table.col2.1')}</td>
                <td rowSpan="6" className="collapse-row">{t('employee.talent.table.col3.1').split("+").map((str, index) => <div key={`${str}-${index}`}>{str}</div>)}</td>
                <td rowSpan="6" className="collapse-row">{t('employee.talent.table.col4.1').split("+").map((str, index) => <div key={`${str}-${index}`}>{str}</div>)}</td>
              </tr>
              <tr>
                <td>{t('employee.talent.table.col1.2')}</td>
                <td>{t('employee.talent.table.col2.2')}</td>
              </tr>
              <tr>
                <td>{t('employee.talent.table.col1.3')}</td>
                <td>{t('employee.talent.table.col2.3')}</td>
              </tr>
              <tr>
                <td>{t('employee.talent.table.col1.4')}</td>
                <td>{t('employee.talent.table.col2.4')}</td>
              </tr>
              <tr>
                <td>{t('employee.talent.table.col1.5')}</td>
                <td>{t('employee.talent.table.col2.5')}</td>
              </tr>
              <tr>
                <td>{t('employee.talent.table.col1.6')}</td>
                <td>{t('employee.talent.table.col2.6')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p_table">
          <div className="p_table_title">{t('employee.leadership.table.title')}</div>
          <table className="p_table2">
            <tbody>
              <tr>
                <td>{t('employee.leadership.table.col1.1')}{i18n.language !== 'en' && <div className="subtitle">{t('employee.leadership.table.col1.1.subtile')}</div>}</td>
                <td rowSpan="2" className="collapse_img"><img src={leadershipImg} alt="leadership" /></td>
              </tr>
              <tr>
                <td>{t('employee.leadership.table.col1.2')}{i18n.language !== 'en' && <div className="subtitle">{t('employee.leadership.table.col1.2.subtile')}</div>}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section id="workEnvironment">
        <div className="w-title">{t('employee.environment.title')}</div>
        <div className="w-gallery-box">
          <div className="w-gallery-wrap">
            <img src={workImg1} alt="办公环境" />
          </div>
          <div className="w-gallery-wrap">
            <div className="w-gallery-item">
              <img src={workImg2} alt="办公环境" />
            </div>
            <div className="w-gallery-item">
              <img src={workImg3} alt="办公环境" />
            </div>
          </div>

        </div>
      </section>
      <section id="employeeActivities">
        <div className="a-title">{t('employee.activities.title')}</div>
        <div className="a-gallery-box">
          <div className="a-gallery-wrap">
            <div className="a-gallery-item">
              <img src={a1} alt="公司年会" />
            </div>
            <div className="a-gallery-item">
              <div className="a-gallery-title">{t('employee.activities.party.title')}</div>
              <div className="p_regular">{t('employee.activities.party.desc')}</div>
            </div>
          </div>
          <div className="a-gallery-wrap">
            <div className="a-gallery-item">
              <img src={a2} alt="专场活动" />
            </div>
            <div className="a-gallery-item">
              <div className="a-gallery-title">{t('employee.activities.sports.title')}</div>
              <div className="p_regular">{t('employee.activities.sports.desc')}</div>
            </div>
          </div>
          <div className="a-gallery-wrap">
            <div className="a-gallery-item">
              <img src={a3} alt="回馈社会" />
            </div>
            <div className="a-gallery-item">
              <div className="a-gallery-title">{t('employee.activities.welfare.title')}</div>
              <div className="p_regular">{t('employee.activities.welfare.desc')}</div>
            </div>
          </div>
          <div className="a-gallery-wrap">
            <div className="a-gallery-item">
              <img src={a4} alt="团建活动" />
            </div>
            <div className="a-gallery-item">
              <div className="a-gallery-title">{t('employee.activities.team.title')}</div>
              <div className="p_regular">{t('employee.activities.team.desc')}</div>
            </div>
          </div>
        </div>
      </section>
      <section id="joinUs" >
        <div className="j-box">
          <div className="j-wrap j-title">{t('employee.join.title')}</div>
          <div className="j-wrap j-btn-box">
            <div className="j-wrap-item">
              <div className="j-item-info">
                <div className="j-item-title">{t('employee.join.social.title')}</div>
                <div className="j-item-desc">{t('employee.join.social.desc')}</div>
              </div>
              <div className="j-item-link"><a href="https://www.dfzxvip.com/talentrecruitment" target="_blank" rel="noopener noreferrer">{t('employee.join.social.link')} &gt;</a></div>
            </div>
            <div className="j-wrap-item">
              <div className="j-item-info">
                <div className="j-item-title">{t('employee.join.campus.title')}</div>
                <div className="j-item-desc">{t('employee.join.campus.desc')}</div>
              </div>
              <div className="j-item-link"><a href="https://zhaopin.koolearn.com/campus_apply/koolearn/5375#/?_k=x9slde" target="_blank" rel="noopener noreferrer">{t('employee.join.campus.link')} &gt;</a></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Employee;;require('./index.less')