import React from 'react'
import { withRouter } from 'react-router-dom';

import logo from '../../img/logo.png'
import { useTranslation } from 'react-i18next'
import MainNav from '../mainNav'

function Header(props) {
  let { i18n } = useTranslation();
  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang)
  }

  const activeStyle = {
    'color': '#EB5B03'
  }

  function toPage(path) {
    props.history.push(path);
  }

  const enBtn = props.location.pathname !== '/news';

  return (
    <div className="header-container" id="header-box">
      <div className="h-box">
        <div className="logo" onClick={() => toPage('/')}>
          <img src={logo} alt="" />
        </div>
        <MainNav></MainNav>
        <div className="lang">
          <span onClick={() => changeLanguage('zh')} style={i18n.language === 'zh' ? activeStyle : {}}>简</span>
          <span className="divider">/</span>
          <span onClick={() => changeLanguage('hk')} style={i18n.language === 'hk' ? activeStyle : {}}>繁</span>
          {
            enBtn && <>
              <span className="divider">/</span>
              <span onClick={() => changeLanguage('en')} style={i18n.language === 'en' ? activeStyle : {}}>EN</span>
            </>
          }
        </div>
      </div>
    </div>
  )
}
export default withRouter(Header);;require('./index.less')