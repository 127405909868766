import React from 'react'

import { useTranslation } from 'react-i18next'

function Modal({info,visible,onCancle}){
    let {t} = useTranslation()
    const handleDocumentClick = e => {
        if( e.target.className === 'modal-container'){
            onCancle();
        }
    }
    return visible? <div className="modal-container" onClick={handleDocumentClick}>
        <div className="modal-wrap">
            <div className={`content ${info.hasPadding ? 'hasSidePadding' : ''}`}>
                <div className="picture">
                    <img src={info.xImg} alt=""/>
                </div>
                <div className="desc">
                    <div className="title">
                        <div className="name">{t(`global.${info.name}.name`)}</div>
                        <div className="position">{t(`global.${info.name}.position`)}</div> 
                    </div>
                    <div className="brief">{t(`global.${info.name}.brief`)}</div>
                </div>
            </div>
            <div className="closeBtn" onClick={onCancle}>
            </div>
        </div>
    </div>:null
}
export default Modal;;require('./index.less')