import yuminghong from '../../img/俞敏洪.png'
// import sundongxu from '../../img/孙东旭.png'
import yinqiang from '../../img/尹强.png'
import sunchang from '../../img/孙畅.png'
// import wuqiang from '../../img/吴强.png'
// import liangyuhua from '../../img/梁育华.png'
import linzheying from '../../img/林哲莹.png'
import dongruibao from '../../img/董瑞豹.png'
import kuangweixin from '../../img/邝伟信.png'
import yuminghongX from '../../img/俞敏洪半身像.png'
// import sundongxuX from '../../img/孙东旭半身像.png'
import yinqiangX from '../../img/尹强半身像.png'
import sunchangX from '../../img/孙畅半身像.png'
// import wuqiangX from '../../img/吴强半身像.png'
import dongruibaoX from '../../img/董瑞豹半身像.png'
import kuangweixinX from '../../img/邝伟信半身像.png'

//董事会
const directors = [
  { name: 'yuminghong', img: yuminghong, xImg: yuminghongX },
  { name: 'yinqiang', img: yinqiang, xImg: yinqiangX },
  // { name: 'sundongxu', img: sundongxu, xImg: sundongxuX },
  { name: 'sunchang', img: sunchang, xImg: sunchangX },
  // { name: 'wuqiang', img: wuqiang, xImg: wuqiangX },
  // { name: 'liangyuhua', img: liangyuhua, xImg: liangyuhua, hasPadding: true },
  { name: 'linzheying', img: linzheying, xImg: linzheying, hasPadding: true },
  { name: 'dongruibao', img: dongruibao, xImg: dongruibaoX },
  { name: 'kuangweixin', img: kuangweixin, xImg: kuangweixinX },
];
// 审核委员会
const auditCommittee = [
  { name: 'dongruibao', img: dongruibao, xImg: dongruibaoX },
  { name: 'linzheying', img: linzheying, xImg: linzheying, hasPadding: true },
  { name: 'kuangweixin', img: kuangweixin, xImg: kuangweixinX },
];
// 薪酬委员会
const remunerationCommittee = [
  { name: 'linzheying', img: linzheying, xImg: linzheying, hasPadding: true },
  { name: 'sunchang', img: sunchang, xImg: sunchangX },
  { name: 'dongruibao', img: dongruibao, xImg: dongruibaoX },
];
// 提名委员会
const nominationCommittee = [
  { name: 'yuminghong', img: yuminghong, xImg: yuminghongX },
  { name: 'linzheying', img: linzheying, xImg: linzheying, hasPadding: true },
  { name: 'dongruibao', img: dongruibao, xImg: dongruibaoX },

];
// 治理文件
const governanceDocuments = [
  { title: 'global.governance.documents10.title', subtitle: '2023-09-20', href_zh: 'https://s1.eastbuy.com/ir/2023%20ESG%E6%8A%A5%E5%91%8A.pdf', href_hk: 'https://s1.eastbuy.com/ir/2023%20ESG%E6%8A%A5%E5%91%8A.pdf', href_en: 'https://s1.eastbuy.com/ir/2023%20ESG%20Report.pdf' },
  { title: 'global.governance.documents9.title', subtitle: '2022-09-20', href_zh: 'https://s3.koolearn.com/0920/2022%20ESG%E5%A0%B1%E5%91%8A.pdf', href_hk: 'https://s3.koolearn.com/0920/2022%20ESG%E5%A0%B1%E5%91%8A.pdf', href_en: 'https://s3.koolearn.com/0920/2022%20ESG%20Report.pdf' },
  { title: 'global.governance.documents8.title', subtitle: '2021-09-16', href_zh: 'https://s3.koolearn.com/2021 ESG报告(中文).pdf', href_hk: 'https://s3.koolearn.com/2021 ESG報告.pdf', href_en: 'https://s3.koolearn.com/2021 ESG Report.pdf' },
  { title: 'global.governance.documents7.title', subtitle: '2020-09-14', href_zh: 'https://s3.koolearn.com/2020 ESG报告.pdf', href_hk: 'https://s3.koolearn.com/2020 ESG報告.pdf', href_en: 'https://s3.koolearn.com/2020 ESG Report.pdf' },
  { title: 'global.governance.documents6.title', subtitle: '2019-09-24', href_zh: 'https://s3.koolearn.com/2019 ESG报告.pdf', href_hk: 'https://s3.koolearn.com/2019 ESG報告.pdf', href_en: 'https://s3.koolearn.com/2019 ESG Report.pdf' },
  { title: 'global.governance.documents1.title', subtitle: '2019-03-28', href_zh: 'https://s3.koolearn.com/Prospectus - Chinese.pdf', href_hk: 'https://s3.koolearn.com/Prospectus - Chinese.pdf', href_en: 'https://s3.koolearn.com/Prospectus - English.pdf' },
  { title: 'global.governance.documents2.title', subtitle: '2019-03-28', href_zh: 'https://s3.koolearn.com/governance1(CH).pdf', href_hk: 'https://s3.koolearn.com/governance1(HK).pdf', href_en: 'https://s3.koolearn.com/governance1(EN).pdf' },
  { title: 'global.governance.documents3.title', subtitle: '2019-03-28', href_zh: 'https://s3.koolearn.com/governance2.pdf', href_hk: 'https://s3.koolearn.com/governance2(HK).pdf', href_en: 'https://s3.koolearn.com/governance2(EN).pdf' },
  { title: 'global.governance.documents4.title', subtitle: '2019-03-28', href_zh: 'https://s3.koolearn.com/governance3.pdf', href_hk: 'https://s3.koolearn.com/governance3(HK).pdf', href_en: 'https://s3.koolearn.com/governance3(EN).pdf' },
  { title: 'global.governance.documents5.title', subtitle: '2019-03-28', href_zh: 'https://s3.koolearn.com/governance4.pdf', href_hk: 'https://s3.koolearn.com/governance4(HK).pdf', href_en: 'https://s3.koolearn.com/governance4(EN).pdf' },
]
// 新闻发布
const pressRelease = [
  {
    title: 'global.press20240823.title', time: '2024-08-23',
    href_zh: "https://s1.eastbuy.com/ir/Fiance2024/东方甄选公布2024财年全年业绩.pdf",
    href_hk: "https://s1.eastbuy.com/ir/Fiance2024/東方甄選公佈2024財年全年業績.pdf",
    href_en: "https://s1.eastbuy.com/ir/Fiance2024/East%20Buy%20Announces%20FY2024%20Annual%20Results.pdf"
  },
  { title: 'global.press20240619.title', time: '2024-06-19',
    href_zh: "https://s1.eastbuy.com/ir/%E4%B8%9C%E6%96%B9%E7%94%84%E9%80%896%E6%9C%8819%E6%97%A5%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A.pdf",
    href_hk: "https://s1.eastbuy.com/ir/%E6%9D%B1%E6%96%B9%E7%94%84%E9%81%B86%E6%9C%8819%E6%97%A5%E6%96%B0%E5%93%81%E7%99%BC%E4%BD%88%E6%9C%83.pdf",
    href_en: "https://s1.eastbuy.com/ir/East%20Buy%20New%20Product%20Launch%20Event%20on%20June%2019.pdf" },
  {
    title: 'global.press20240124.title', time: '2024-01-24',
    href_zh: "https://s1.eastbuy.com/ir/%E3%80%90%E6%96%B0%E9%97%BB%E7%A8%BF%E3%80%91%E4%B8%9C%E6%96%B9%E7%94%84%E9%80%89%E6%8E%A7%E8%82%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%281797.HK%29%E5%85%AC%E5%B8%832024%E8%B4%A2%E5%B9%B4%E4%B8%AD%E6%9C%9F%E4%B8%9A%E7%BB%A9.pdf",
    href_hk: "https://s1.eastbuy.com/ir/%E3%80%90%E6%96%B0%E8%81%9E%E7%A8%BF%E3%80%91%E6%9D%B1%E6%96%B9%E7%94%84%E9%81%B8%E6%8E%A7%E8%82%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%281797.HK%29%E5%85%AC%E4%BD%882024%E8%B2%A1%E5%B9%B4%E4%B8%AD%E6%9C%9F%E6%A5%AD%E7%B8%BE.pdf",
    href_en: "https://s1.eastbuy.com/ir/Press%20Release-East%20Buy%20Holding%20Limited%20%281797.HK%29%20FY2024%20Interim%20Results%20Annoucement.pdf"
  },
  {
    title: 'global.press20230825.title', time: '2023-08-25',
    href_zh: "https://s1.eastbuy.com/ir/%E3%80%90%E6%96%B0%E9%97%BB%E7%A8%BF%E3%80%91%E4%B8%9C%E6%96%B9%E7%94%84%E9%80%89%E6%8E%A7%E8%82%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%281797.HK%29%E5%85%AC%E5%B8%832023%E8%B4%A2%E5%B9%B4%E5%B9%B4%E5%BA%A6%E4%B8%9A%E7%BB%A920230825_Final.pdf",
    href_hk: "https://s1.eastbuy.com/ir/%E3%80%90%E6%96%B0%E8%81%9E%E7%A8%BF%E3%80%91%E6%9D%B1%E6%96%B9%E7%94%84%E9%81%B8%E6%8E%A7%E8%82%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%281797.HK%29%E5%85%AC%E4%BD%882023%E8%B2%A1%E5%B9%B4%E5%B9%B4%E5%BA%A6%E6%A5%AD%E7%B8%BE20230825_Final.pdf",
    href_en: "https://s1.eastbuy.com/ir/Press%20Release-East%20Buy%20Holding%20Limited%281797.HK%29FY2023%20Annual%20Results%20Annoucement%2020230825_Final.pdf"
  },
  {
    title: 'global.press20230117.title', time: '2023-01-17',
    href_zh: "https://s8.dfzxvip.com/【新闻稿】新东方在线科技控股有限公司(1797.HK)公布2023财年中期业绩_20230117_Final_V4.pdf",
    href_hk: "https://s8.dfzxvip.com/【新聞稿】新東方在綫科技控股有限公司(1797.HK)公佈2023財年中期業績_20230117 _Final_V4.pdf",
    href_en: "https://s8.dfzxvip.com/[Press Release] Koolearn Technology Holding Limited (1797.HK) Announces Fiscal Year 2023 Interim Results_EN 20230117 _Final_V4.pdf"
  },
  { title: 'global.press20220826.title', time: '2022-08-26', href_zh: "https://s3.koolearn.com/2022Financial/【新闻稿】FY2022 新东方在线公布2022财年年度业绩.pdf", href_hk: "https://s3.koolearn.com/2022Financial/【新聞稿】FY2022 新東方在綫公佈2022財年年度業績.pdf", href_en: "https://s3.koolearn.com/2022Financial/Press Release-Koolearn Technology Holding Limited(1797.HK)FY2022 Annual Results Announcement.pdf" },
  { title: 'global.press20220121.title', time: '2022-01-21', href_zh: "https://s3.koolearn.com/2022Financial/【新闻稿】新东方在线科技控股有限公司(1797.HK)公布2022财年中期业绩 Final.pdf", href_hk: "https://s3.koolearn.com/2022Financial/【新聞稿】新東方在綫科技控股有限公司(1797.HK)公佈2022財年中期業績 Final.pdf", href_en: "https://s3.koolearn.com/2022Financial/Koolearn%20Technology%20Holding%20Limited(1797.HK)FY2022%201H%20Results%20Announcement%20EN%20Final.pdf" },
  { title: 'global.press1.title', time: '2021-08-27', href_zh: "https://s3.koolearn.com/新东方在线公布2021财年全年业绩.pdf", href_hk: "https://s3.koolearn.com/新東方在綫公佈2021財年全年業績.pdf", href_en: "https://s3.koolearn.com/Koolearn Announces FY2021 Annual Results.pdf" },
  { title: 'global.press2.title', time: '2021-01-22', href_zh: "https://s3.koolearn.com/20210122(CH).pdf", href_hk: "https://s3.koolearn.com/20210122(HK).pdf", href_en: "https://s3.koolearn.com/20210122(EN).pdf" },
  { title: 'global.press3.title', time: '2020-08-21', href_zh: "https://s3.koolearn.com/20200821(CH).pdf", href_hk: "https://s3.koolearn.com/20200821(HK).pdf", href_en: "https://s3.koolearn.com/20200821(EN).pdf" },
  { title: 'global.press4.title', time: '2020-01-20', href_zh: "https://s3.koolearn.com/20200120(CH).pdf", href_hk: "https://s3.koolearn.com/20200120(HK).pdf", href_en: "https://s3.koolearn.com/20200120(EN).pdf" },
  { title: 'global.press5.title', time: '2019-08-16', href_zh: "https://s3.koolearn.com/20190816(CH).pdf", href_hk: "https://s3.koolearn.com/20190816(HK).pdf", href_en: "https://s3.koolearn.com/20190816(EN).pdf" },
  { title: 'global.press6.title', time: '2019-03-29', href_zh: "https://s3.koolearn.com/20190329(CH).pdf", href_hk: "https://s3.koolearn.com/20190329(HK).pdf", href_en: "https://s3.koolearn.com/20190329(EN).pdf" },
  { title: 'global.press7.title', time: '2019-03-28', href_zh: "https://s3.koolearn.com/20190328(CH).pdf", href_hk: "https://s3.koolearn.com/20190328(HK).pdf", href_en: "https://s3.koolearn.com/20190328(EN).pdf" },
  { title: 'global.press8.title', time: '2019-03-15', href_zh: "https://s3.koolearn.com/20190315(CH).pdf", href_hk: "https://s3.koolearn.com/20190315(HK).pdf", href_en: "https://s3.koolearn.com/20190315(EN).pdf" }
]

// const analysts = [
//   {
//     "firm": "CAITONG SECURITIES",
//     "analyst": "Ninghe Pan",
//     "email": "pannh@ctsec.com",
//   },
//   {
//     "firm": "CAITONG SECURITIES",
//     "analyst": "Yang Liu",
//     "email": "liuyang01@ctsec.com",
//   },
//   {
//     "firm": "CAITONG SECURITIES",
//     "analyst": "Yuebo Li",
//     "email": "liyb@ctsec.com",
//   },
//   {
//     "firm": "CHANGJIANG SECURITIES",
//     "analyst": "Gang Zhao",
//     "email": "zhaogang@cjsc.com.cn",
//   },
//   {
//     "firm": "China Merchants Securities",
//     "analyst": "Jia Gu",
//     "email": "gujia@cmschina.com.cn",
//   },
//   {
//     "firm": "China Merchants Securities",
//     "analyst": "Xiaoyan Xie",
//     "email": "xiexiaoyan1@cmschina.com.cn",
//   },
//   {
//     "firm": "China Merchants Securities",
//     "analyst": "Shuai Xu",
//     "email": "xushuai2@cmschina.com.cn",
//   },
//   {
//     "firm": "China Merchants Securities (HK)",
//     "analyst": "Tommy Wong",
//     "email": "tommywong@cmschina.com.hk",
//   },
//   {
//     "firm": "China Merchants Securities (HK)",
//     "analyst": "Crystal Li",
//     "email": "crystalli@cmschina.com.hk",
//   },
//   {
//     "firm": "China Merchants Securities (HK)",
//     "analyst": "Kaia Zhang",
//     "email": "kaiazhang@cmschina.com.hk",
//   },
//   {
//     "firm": "CHINA RENAISSANCE",
//     "analyst": "Yiwen Zhang",
//     "email": "yiwenzhang@chinarenaissance.com",
//   },
//   {
//     "firm": "CHINA SECURITIES",
//     "analyst": "Ye Le",
//     "email": "yele@csc.com.cn",
//   },
//   {
//     "firm": "CHINA SECURITIES",
//     "analyst": "Yanglu Huang",
//     "email": "huangyanglu@csc.com.cn",
//   },
//   {
//     "firm": "CICC",
//     "analyst": "Liping Zhao",
//     "email": "liping.zhao@cicc.com.cn",
//   },
//   {
//     "firm": "CICC",
//     "analyst": "Caini Wang",
//     "email": "caini.wang@cicc.com.cn",
//   },
//   {
//     "firm": "CICC",
//     "analyst": "Xiaodan Zhang",
//     "email": "xiaodan3.zhang@cicc.com.cn",
//   },
//   {
//     "firm": "CICC",
//     "analyst": "Kai Qian",
//     "email": "kai.qian@cicc.com.cn",
//   },
//   {
//     "firm": "CINDA SECURITIES",
//     "analyst": "Cuiting Feng",
//     "email": "fengcuiting@cindasc.com",
//   },
//   {
//     "firm": "Citi Research",
//     "analyst": "Alice Cai",
//     "email": "alice.cai@citi.com",
//   },
//   {
//     "firm": "Citi Research",
//     "analyst": "Eric Lau",
//     "email": "eric.h.lau@citi.com",
//   },
//   {
//     "firm": "CITIC SECURITIES",
//     "analyst": "Chongguang Feng",
//     "email": "fengchongguang@citics.com",
//   },
//   {
//     "firm": "Daiwa Capital Markets",
//     "analyst": "Candis Chan",
//     "email": "candis.chan@hk.daiwacm.com",
//   },
//   {
//     "firm": "Daiwa Capital Markets",
//     "analyst": "John Choi",
//     "email": "john.choi@hk.daiwacm.com",
//   },
//   {
//     "firm": "Daiwa Capital Markets",
//     "analyst": "Robin Leung",
//     "email": "robin.leung@hk.daiwacm.com",
//   },
//   {
//     "firm": "ESSENCE INTERNATIONAL",
//     "analyst": "Alex Wang",
//     "email": "alexwang@eif.com.hk",
//   },
//   {
//     "firm": "EVERBRIGHT SECURITIES",
//     "analyst": "Tianzi Fu",
//     "email": "futz@ebscn.com",
//   },
//   {
//     "firm": "GF SECURITIES",
//     "analyst": "Shi Kuang",
//     "email": "kuangshi@gf.com.cn",
//   },
//   {
//     "firm": "GF SECURITIES",
//     "analyst": "Minting Ye",
//     "email": "yeminting@gf.com.cn",
//   },
//   {
//     "firm": "GUOLIAN SECURITIES",
//     "analyst": "Mengyao Chen",
//     "email": "cmy@glsc.com.cn",
//   },
//   {
//     "firm": "GUOSEN SECURITIES",
//     "analyst": "Guang Zen",
//     "email": "zengguang@guosen.com.cn",
//   },
//   {
//     "firm": "GUOSEN SECURITIES",
//     "analyst": "Xiao Zhong",
//     "email": "zhongxiao@guosen.com.cn",
//   },
//   {
//     "firm": "GUOSEN SECURITIES",
//     "analyst": "Lu Zhang",
//     "email": "zhanglu5@guosen.com.cn",
//   },
//   {
//     "firm": "GUOTAI JUNAN SECURITIES",
//     "analyst": "Yuenan Liu",
//     "email": "liuyuenan@gtjas.com",
//   },
//   {
//     "firm": "GUOTAI JUNAN SECURITIES",
//     "analyst": "Ying Su",
//     "email": "suying@gtjas.com",
//   },
//   {
//     "firm": "HAITONG",
//     "analyst": "Liting Wang",
//     "email": "wanglt@haitong.com",
//   },
//   {
//     "firm": "HAITONG",
//     "analyst": "Hongke Li",
//     "email": "lhk11523@haitong.com",
//   },
//   {
//     "firm": "HAITONG",
//     "analyst": "Leina Cao",
//     "email": "cln13796@haitong.com",
//   },
//   {
//     "firm": "HAITONG",
//     "analyst": "Yijie Wang",
//     "email": "wyj13985@haitong.com",
//   },
//   {
//     "firm": "Haitong International",
//     "analyst": "Yingzhi Xu",
//     "email": "yingzhi.xu@htisec.com",
//   },
//   {
//     "firm": "HUAAN SECURITIES",
//     "analyst": "Rong Jin",
//     "email": "jinrong@hazq.com",
//   },
//   {
//     "firm": "HUAAN SECURITIES",
//     "analyst": "Zemin Chen",
//     "email": "czm3779@hfzq.com.cn",
//   },
//   {
//     "firm": "HUAAN SECURITIES",
//     "analyst": "Lan Yang",
//     "email": "yanglan@hfzq.com.cn",
//   },
//   {
//     "firm": "HUATAI FINANCIAL HOLDINGS (HONG KONG) LIMITED",
//     "analyst": "Lulu Xia",
//     "email": "xialulu@htsc.com",
//   },
//   {
//     "firm": "HUATAI FINANCIAL HOLDINGS (HONG KONG) LIMITED",
//     "analyst": "Jie Hou",
//     "email": "houjie017864@htsc.com",
//   },
//   {
//     "firm": "HUATAI FINANCIAL HOLDINGS (HONG KONG) LIMITED",
//     "analyst": "Yujia Zheng",
//     "email": "zhengyujia@htsc.com",
//   },
//   {
//     "firm": "HUATAI SECURITIES",
//     "analyst": "Lian Duan",
//     "email": "duanlian@htsc.com",
//   },
//   {
//     "firm": "INDUSTRIAL SECURITIES",
//     "analyst": "Jian Song",
//     "email": "songjian@xyzq.com.cn",
//   },
//   {
//     "firm": "KAIYUAN SECURITIES",
//     "analyst": "Guangzhao Fang",
//     "email": "fangguangzhao@kysec.cn",
//   },
//   {
//     "firm": "NORTHEAST SECURITIES",
//     "analyst": "Yiran Qian",
//     "email": "fangguangzhao@kysec.cn",
//   },
//   {
//     "firm": "NORTHEAST SECURITIES",
//     "analyst": "Yuxiang Song",
//     "email": "songyx@nesc.cn",
//   },
//   {
//     "firm": "ORIENT SECURITIES",
//     "analyst": "Wenqian Xiang",
//     "email": "xiangwenqian@orientsec.com.cn",
//   },
//   {
//     "firm": "ORIENT SECURITIES",
//     "analyst": "Bo Zhan",
//     "email": "zhanbo@orientsec.com.cn",
//   },
//   {
//     "firm": "ORIENT SECURITIES",
//     "analyst": "Yuqi Li",
//     "email": "liyuqi@orientsec.com.cn",
//   },
//   {
//     "firm": "ORIENT SECURITIES",
//     "analyst": "Conglu Wu",
//     "email": "wuconglu@orientsec.com.cn",
//   },
//   {
//     "firm": "SEALAND SECURITIES",
//     "analyst": "Lei Yao",
//     "email": "yaol02@ghzq.com.cn",
//   },
//   {
//     "firm": "SEALAND SECURITIES",
//     "analyst": "Ruijiao Tan",
//     "email": "tanrq@ghzq.com.cn",
//   },
//   {
//     "firm": "SOOCHOW SECURITIES",
//     "analyst": "Liangwei Zhang",
//     "email": "zhanglw@dwzq.com.cn",
//   },
//   {
//     "firm": "SOOCHOW SECURITIES",
//     "analyst": "Liangjiu Zhou",
//     "email": "zhoulj@dwzq.com.cn",
//   },
//   {
//     "firm": "SOUTHWEST SECURITIES",
//     "analyst": "Yan Liu",
//     "email": "liuyan@swsc.com.cn",
//   },
//   {
//     "firm": "SOUTHWEST SECURITIES",
//     "analyst": "XiangJie Wang",
//     "email": "wxj@swsc.com.cn",
//   },
//   {
//     "firm": "SWS RESEARCH",
//     "analyst": "Zhe Huang",
//     "email": "huangzhe@swsresearch.com",
//   },
//   {
//     "firm": "TF SECURITIES",
//     "analyst": "Haiyang Sun",
//     "email": "sunhaiyang@tfzq.com",
//   },
//   {
//     "firm": "TF SECURITIES",
//     "analyst": "Zhangming Liu",
//     "email": "liuzhangming@tfzq.com",
//   },
//   {
//     "firm": "WESTERN SECURITIES",
//     "analyst": "Yanli Li",
//     "email": "liyanli@research.xbmail.com.cn",
//   },
//   {
//     "firm": "ZHESHANG SECURITIES",
//     "analyst": "Li Ma",
//     "email": "mali@stocke.com.cn",
//   },
//   {
//     "firm": "ZHONGTAI SECURITIES",
//     "analyst": "Xiaochen Han",
//     "email": "hanxc@zts.com.cn",
//   }
// ]

const analysts={
  'en': [
    {
      "firm":"BOCOM International",
      "analyst":"Mengqi Sun",
      "email":"mengqi.sun@bocomgroup.com"
      },
      {
      "firm":"BOCOM International",
      "analyst":"Connie Gu",
      "email":"connie.gu@bocomgroup.com"
      },
      {
      "firm":"CAITONG SECURITIES",
      "analyst":"Jian Yu",
      "email":"yujian@ctsec.com"
      },
      {
      "firm":"CAITONG SECURITIES",
      "analyst":"Yang Liu",
      "email":"liuyang01@ctsec.com"
      },
      {
      "firm":"CHANGJIANG SECURITIES",
      "analyst":"Gang Zhao",
      "email":"zhaogang@cjsc.com.cn"
      },
      {
      "firm":"China Merchants Securities",
      "analyst":"Jia Gu",
      "email":"gujia@cmschina.com.cn"
      },
      {
      "firm":"China Merchants Securities",
      "analyst":"Xiaoyan Xie",
      "email":"xiexiaoyan1@cmschina.com.cn"
      },
      {
      "firm":"China Merchants Securities",
      "analyst":"Shuai Xu",
      "email":"xushuai2@cmschina.com.cn"
      },
      {
      "firm":"China Merchants Securities (HK)",
      "analyst":"Tommy Wong",
      "email":"tommywong@cmschina.com.hk"
      },
      {
      "firm":"China Merchants Securities (HK)",
      "analyst":"Crystal Li",
      "email":"crystalli@cmschina.com.hk"
      },
      {
      "firm":"China Merchants Securities (HK)",
      "analyst":"Kaia Zhang",
      "email":"kaiazhang@cmschina.com.hk"
      },
      {
      "firm":"CHINA RENAISSANCE",
      "analyst":"Yiwen Zhang",
      "email":"yiwenzhang@chinarenaissance.com"
      },
      {
      "firm":"CHINA SECURITIES",
      "analyst":"Ye Le",
      "email":"yele@csc.com.cn"
      },
      {
      "firm":"CHINA SECURITIES",
      "analyst":"Yanglu Huang",
      "email":"huangyanglu@csc.com.cn"
      },
      {
      "firm":"CICC",
      "analyst":"Liping Zhao",
      "email":"liping.zhao@cicc.com.cn"
      },
      {
      "firm":"CICC",
      "analyst":"Caini Wang",
      "email":"caini.wang@cicc.com.cn"
      },
      {
      "firm":"CICC",
      "analyst":"Xiaodan Zhang",
      "email":"xiaodan3.zhang@cicc.com.cn"
      },
      {
      "firm":"CINDA SECURITIES",
      "analyst":"Cuiting Feng",
      "email":"fengcuiting@cindasc.com"
      },
      {
      "firm":"Citi Research",
      "analyst":"Alice Cai",
      "email":"alice.cai@citi.com"
      },
      {
      "firm":"Citi Research",
      "analyst":"Eric Lau",
      "email":"eric.h.lau@citi.com"
      },
      {
      "firm":"CITIC SECURITIES",
      "analyst":"Chongguang Feng",
      "email":"fengchongguang@citics.com"
      },
      {
      "firm":"CITIC SECURITIES",
      "analyst":"Yikun Zheng",
      "email":"zhengyikun@citics.com"
      },
      {
      "firm":"Daiwa Capital Markets",
      "analyst":"Candis Chan",
      "email":"candis.chan@hk.daiwacm.com"
      },
      {
      "firm":"Daiwa Capital Markets",
      "analyst":"John Choi",
      "email":"john.choi@hk.daiwacm.com"
      },
      {
      "firm":"ESSENCE INTERNATIONAL",
      "analyst":"Alex Wang",
      "email":"alexwang@eif.com.hk"
      },
      {
      "firm":"EVERBRIGHT SECURITIES",
      "analyst":"Tianzi Fu",
      "email":"futz@ebscn.com"
      },
      {
      "firm":"GF SECURITIES",
      "analyst":"Shi Kuang",
      "email":"kuangshi@gf.com.cn"
      },
      {
      "firm":"GF SECURITIES",
      "analyst":"Minting Ye",
      "email":"yeminting@gf.com.cn"
      },
      {
      "firm":"Goldman Sachs",
      "analyst":"Timothy Zhao",
      "email":"timothy.zhao@gs.com"
      },
      {
      "firm":"Goldman Sachs",
      "analyst":"Ronald Keung",
      "email":"ronald.keung@gs.com"
      },
      {
      "firm":"Goldman Sachs",
      "analyst":"Eunice Liu",
      "email":"eunice.liu@gs.com"
      },
      {
      "firm":"GUOLIAN SECURITIES",
      "analyst":"Mengyao Chen",
      "email":"cmy@glsc.com.cn"
      },
      {
      "firm":"GUOSEN SECURITIES",
      "analyst":"Guang Zen",
      "email":"zengguang@guosen.com.cn"
      },
      {
      "firm":"GUOSEN SECURITIES",
      "analyst":"Xiao Zhong",
      "email":"zhongxiao@guosen.com.cn"
      },
      {
      "firm":"GUOSEN SECURITIES",
      "analyst":"Lu Zhang",
      "email":"zhanglu5@guosen.com.cn"
      },
      {
      "firm":"GUOTAI JUNAN SECURITIES",
      "analyst":"Yuenan Liu",
      "email":"liuyuenan@gtjas.com"
      },
      {
      "firm":"GUOTAI JUNAN SECURITIES",
      "analyst":"Zitong Zhuang",
      "email":"zhuangzitong026312@gtjas.com"
      },
      {
      "firm":"GUOTAI JUNAN SECURITIES",
      "analyst":"Ying Su",
      "email":"suying@gtjas.com"
      },
      {
      "firm":"HAITONG",
      "analyst":"Liting Wang",
      "email":"wanglt@haitong.com"
      },
      {
      "firm":"HAITONG",
      "analyst":"Hongke Li",
      "email":"lhk11523@haitong.com"
      },
      {
      "firm":"HAITONG",
      "analyst":"Leina Cao",
      "email":"cln13796@haitong.com"
      },
      {
      "firm":"HAITONG",
      "analyst":"Yijie Wang",
      "email":"wyj13985@haitong.com"
      },
      {
      "firm":"Haitong International",
      "analyst":"Yingzhi Xu",
      "email":"yingzhi.xu@htisec.com"
      },
      {
      "firm":"HUAAN SECURITIES",
      "analyst":"Rong Jin",
      "email":"jinrong@hazq.com"
      },
      {
      "firm":"HUAAN SECURITIES",
      "analyst":"Yuehao Shi",
      "email":"shiyuehao@hazq.com"
      },
      {
      "firm":"HUA CHUANG SECURITIES",
      "analyst":"Xin Liu",
      "email":"liuxin3@hcyjs.com"
      },
      {
      "firm":"HUA CHUANG SECURITIES",
      "analyst":"Zixuan Guo",
      "email":"guozixuan@hcyjs.com"
      },
      {
      "firm":"HUATAI SECURITIES",
      "analyst":"Lulu Xia",
      "email":"xialulu@htsc.com"
      },
      {
      "firm":"HUATAI SECURITIES",
      "analyst":"Bo Zhan",
      "email":"zhanbo@htsc.com"
      },
      {
      "firm":"HUATAI SECURITIES",
      "analyst":"Yujia Zheng",
      "email":"zhengyujia@htsc.com"
      },
      {
      "firm":"INDUSTRIAL SECURITIES",
      "analyst":"Jian Song",
      "email":"songjian@xyzq.com.cn"
      },
      {
      "firm":"KAIYUAN SECURITIES",
      "analyst":"Guangzhao Fang",
      "email":"fangguangzhao@kysec.cn"
      },
      {
      "firm":"NORTHEAST SECURITIES",
      "analyst":"Yiran Qian",
      "email":"qianyr@nesc.cn"
      },
      {
      "firm":"NORTHEAST SECURITIES",
      "analyst":"Yuxiang Song",
      "email":"songyx@nesc.cn"
      },
      {
      "firm":"ORIENT SECURITIES",
      "analyst":"Wenqian Xiang",
      "email":"xiangwenqian@orientsec.com.cn"
      },
      {
      "firm":"SEALAND SECURITIES",
      "analyst":"Lei Yao",
      "email":"yaol02@ghzq.com.cn"
      },
      {
      "firm":"SEALAND SECURITIES",
      "analyst":"Ruijiao Tan",
      "email":"tanrq@ghzq.com.cn"
      },
      {
      "firm":"SOOCHOW SECURITIES",
      "analyst":"Liangwei Zhang",
      "email":"zhanglw@dwzq.com.cn"
      },
      {
      "firm":"SOOCHOW SECURITIES",
      "analyst":"Liangjiu Zhou",
      "email":"zhoulj@dwzq.com.cn"
      },
      {
      "firm":"SOUTHWEST SECURITIES",
      "analyst":"Yan Liu",
      "email":"liuyan@swsc.com.cn"
      },
      {
      "firm":"SOUTHWEST SECURITIES",
      "analyst":"XiangJie Wang",
      "email":"wxj@swsc.com.cn"
      },
      {
      "firm":"SWS RESEARCH",
      "analyst":"Zhe Huang",
      "email":"huangzhe@swsresearch.com"
      },
      {
      "firm":"TF SECURITIES",
      "analyst":"Haiyang Sun",
      "email":"sunhaiyang@tfzq.com"
      },
      {
      "firm":"WESTERN SECURITIES",
      "analyst":"Haixing Feng",
      "email":"fenghaixing@research.xbmail.com.cn"
      },
      {
      "firm":"ZHESHANG SECURITIES",
      "analyst":"Li Ma",
      "email":"mali@stocke.com.cn"
      },
      {
      "firm":"ZHESHANG SECURITIES",
      "analyst":"Zhao Chen",
      "email":"chenzhao01@stocke.com.cn"
      },
      {
      "firm":"ZHONGTAI SECURITIES",
      "analyst":"Xiaochen Han",
      "email":"hanxc@zts.com.cn"
      }
  ],
  'zh':[
    {
      "firm":"交银国际",
      "analyst":"孙梦琪",
      "email":"mengqi.sun@bocomgroup.com"
      },
      {
      "firm":"交银国际",
      "analyst":"谷馨瑜",
      "email":"connie.gu@bocomgroup.com"
      },
      {
      "firm":"财通证券",
      "analyst":"于健",
      "email":"yujian@ctsec.com"
      },
      {
      "firm":"财通证券",
      "analyst":"刘洋",
      "email":"liuyang01@ctsec.com"
      },
      {
      "firm":"长江证券",
      "analyst":"赵刚",
      "email":"zhaogang@cjsc.com.cn"
      },
      {
      "firm":"招商证券",
      "analyst":"顾佳",
      "email":"gujia@cmschina.com.cn"
      },
      {
      "firm":"招商证券",
      "analyst":"谢笑妍",
      "email":"xiexiaoyan1@cmschina.com.cn"
      },
      {
      "firm":"招商证券",
      "analyst":"徐帅",
      "email":"xushuai2@cmschina.com.cn"
      },
      {
      "firm":"招商证券国际",
      "analyst":"王腾杰",
      "email":"tommywong@cmschina.com.hk"
      },
      {
      "firm":"招商证券国际",
      "analyst":"李怡珊",
      "email":"crystalli@cmschina.com.hk"
      },
      {
      "firm":"招商证券国际",
      "analyst":"张嘉仪",
      "email":"kaiazhang@cmschina.com.hk"
      },
      {
      "firm":"华兴证券",
      "analyst":"张译文",
      "email":"yiwenzhang@chinarenaissance.com"
      },
      {
      "firm":"中信建投",
      "analyst":"叶乐",
      "email":"yele@csc.com.cn"
      },
      {
      "firm":"中信建投",
      "analyst":"黄杨璐",
      "email":"huangyanglu@csc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"赵丽萍",
      "email":"liping.zhao@cicc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"王彩妮",
      "email":"caini.wang@cicc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"张潇丹",
      "email":"xiaodan3.zhang@cicc.com.cn"
      },
      {
      "firm":"信达证券",
      "analyst":"冯翠婷",
      "email":"fengcuiting@cindasc.com"
      },
      {
      "firm":"Citi",
      "analyst":"Alice Cai",
      "email":"alice.cai@citi.com"
      },
      {
      "firm":"Citi",
      "analyst":"Eric Lau",
      "email":"eric.h.lau@citi.com"
      },
      {
      "firm":"中信证券",
      "analyst":"冯重光",
      "email":"fengchongguang@citics.com"
      },
      {
      "firm":"中信证券",
      "analyst":"郑逸坤",
      "email":"zhengyikun@citics.com"
      },
      {
      "firm":"Daiwa",
      "analyst":"Candis Chan",
      "email":"candis.chan@hk.daiwacm.com"
      },
      {
      "firm":"Daiwa",
      "analyst":"John Choi",
      "email":"john.choi@hk.daiwacm.com"
      },
      {
      "firm":"安信国际",
      "analyst":"汪阳",
      "email":"alexwang@eif.com.hk"
      },
      {
      "firm":"光大证券",
      "analyst":"付天姿",
      "email":"futz@ebscn.com"
      },
      {
      "firm":"广发证券",
      "analyst":"旷实",
      "email":"kuangshi@gf.com.cn"
      },
      {
      "firm":"广发证券",
      "analyst":"叶敏婷",
      "email":"yeminting@gf.com.cn"
      },
      {
      "firm":"高盛",
      "analyst":"Timothy Zhao",
      "email":"timothy.zhao@gs.com"
      },
      {
      "firm":"高盛",
      "analyst":"Ronald Keung",
      "email":"ronald.keung@gs.com"
      },
      {
      "firm":"高盛",
      "analyst":"Eunice Liu",
      "email":"eunice.liu@gs.com"
      },
      {
      "firm":"国联证券",
      "analyst":"陈梦瑶",
      "email":"cmy@glsc.com.cn"
      },
      {
      "firm":"国信证券",
      "analyst":"曾光",
      "email":"zengguang@guosen.com.cn"
      },
      {
      "firm":"国信证券",
      "analyst":"钟潇",
      "email":"zhongxiao@guosen.com.cn"
      },
      {
      "firm":"国信证券",
      "analyst":"张鲁",
      "email":"zhanglu5@guosen.com.cn"
      },
      {
      "firm":"国泰君安",
      "analyst":"刘越男",
      "email":"liuyuenan@gtjas.com"
      },
      {
      "firm":"国泰君安",
      "analyst":"庄子童",
      "email":"zhuangzitong026312@gtjas.com"
      },
      {
      "firm":"国泰君安",
      "analyst":"苏颖",
      "email":"suying@gtjas.com"
      },
      {
      "firm":"海通证券",
      "analyst":"汪立亭",
      "email":"wanglt@haitong.com"
      },
      {
      "firm":"海通证券",
      "analyst":"李宏科",
      "email":"lhk11523@haitong.com"
      },
      {
      "firm":"海通证券",
      "analyst":"曹蕾娜",
      "email":"cln13796@haitong.com"
      },
      {
      "firm":"海通证券",
      "analyst":"王祎婕",
      "email":"wyj13985@haitong.com"
      },
      {
      "firm":"海通国际",
      "analyst":"许樱之",
      "email":"yingzhi.xu@htisec.com"
      },
      {
      "firm":"华安证券",
      "analyst":"金荣",
      "email":"jinrong@hazq.com"
      },
      {
      "firm":"华安证券",
      "analyst":"石月昊",
      "email":"shiyuehao@hazq.com"
      },
      {
      "firm":"华创证券",
      "analyst":"刘欣",
      "email":"liuxin3@hcyjs.com"
      },
      {
      "firm":"华创证券",
      "analyst":"郭子萱",
      "email":"guozixuan@hcyjs.com"
      },
      {
      "firm":"华泰证券",
      "analyst":"夏路路",
      "email":"xialulu@htsc.com"
      },
      {
      "firm":"华泰证券",
      "analyst":"詹博",
      "email":"zhanbo@htsc.com"
      },
      {
      "firm":"华泰证券",
      "analyst":"郑裕佳",
      "email":"zhengyujia@htsc.com"
      },
      {
      "firm":"兴业证券",
      "analyst":"宋健",
      "email":"songjian@xyzq.com.cn"
      },
      {
      "firm":"开源证券",
      "analyst":"方光照",
      "email":"fangguangzhao@kysec.cn"
      },
      {
      "firm":"东北证券",
      "analyst":"钱熠然",
      "email":"qianyr@nesc.cn"
      },
      {
      "firm":"东北证券",
      "analyst":"宋雨翔",
      "email":"songyx@nesc.cn"
      },
      {
      "firm":"东方证券",
      "analyst":"项雯倩",
      "email":"xiangwenqian@orientsec.com.cn"
      },
      {
      "firm":"国海证券",
      "analyst":"姚蕾",
      "email":"yaol02@ghzq.com.cn"
      },
      {
      "firm":"国海证券",
      "analyst":"谭瑞峤",
      "email":"tanrq@ghzq.com.cn"
      },
      {
      "firm":"东吴证券",
      "analyst":"张良卫",
      "email":"zhanglw@dwzq.com.cn"
      },
      {
      "firm":"东吴证券",
      "analyst":"周良玖",
      "email":"zhoulj@dwzq.com.cn"
      },
      {
      "firm":"西南证券",
      "analyst":"刘言",
      "email":"liuyan@swsc.com.cn"
      },
      {
      "firm":"西南证券",
      "analyst":"王湘杰",
      "email":"wxj@swsc.com.cn"
      },
      {
      "firm":"申万宏源研究",
      "analyst":"黄哲",
      "email":"huangzhe@swsresearch.com"
      },
      {
      "firm":"天风证券",
      "analyst":"孙海洋",
      "email":"sunhaiyang@tfzq.com"
      },
      {
      "firm":"西部证券",
      "analyst":"冯海星",
      "email":"fenghaixing@research.xbmail.com.cn"
      },
      {
      "firm":"浙商证券",
      "analyst":"马莉",
      "email":"mali@stocke.com.cn"
      },
      {
      "firm":"浙商证券",
      "analyst":"陈钊",
      "email":"chenzhao01@stocke.com.cn"
      },
      {
      "firm":"中泰证券",
      "analyst":"韩筱辰",
      "email":"hanxc@zts.com.cn"
      }
  ],
  'hk':[
    {
      "firm":"交銀國際",
      "analyst":"孫夢琪",
      "email":"mengqi.sun@bocomgroup.com"
      },
      {
      "firm":"交銀國際",
      "analyst":"谷馨瑜",
      "email":"connie.gu@bocomgroup.com"
      },
      {
      "firm":"財通證券",
      "analyst":"于健",
      "email":"yujian@ctsec.com"
      },
      {
      "firm":"財通證券",
      "analyst":"劉洋",
      "email":"liuyang01@ctsec.com"
      },
      {
      "firm":"長江證券",
      "analyst":"趙剛",
      "email":"zhaogang@cjsc.com.cn"
      },
      {
      "firm":"招商證券",
      "analyst":"顧佳",
      "email":"gujia@cmschina.com.cn"
      },
      {
      "firm":"招商證券",
      "analyst":"謝笑妍",
      "email":"xiexiaoyan1@cmschina.com.cn"
      },
      {
      "firm":"招商證券",
      "analyst":"徐帥",
      "email":"xushuai2@cmschina.com.cn"
      },
      {
      "firm":"招商證券國際",
      "analyst":"王騰杰",
      "email":"tommywong@cmschina.com.hk"
      },
      {
      "firm":"招商證券國際",
      "analyst":"李怡珊",
      "email":"crystalli@cmschina.com.hk"
      },
      {
      "firm":"招商證券國際",
      "analyst":"張嘉儀",
      "email":"kaiazhang@cmschina.com.hk"
      },
      {
      "firm":"華興證券",
      "analyst":"張譯文",
      "email":"yiwenzhang@chinarenaissance.com"
      },
      {
      "firm":"中信建投",
      "analyst":"葉樂",
      "email":"yele@csc.com.cn"
      },
      {
      "firm":"中信建投",
      "analyst":"黃楊璐",
      "email":"huangyanglu@csc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"趙麗萍",
      "email":"liping.zhao@cicc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"王彩妮",
      "email":"caini.wang@cicc.com.cn"
      },
      {
      "firm":"中金公司",
      "analyst":"張瀟丹",
      "email":"xiaodan3.zhang@cicc.com.cn"
      },
      {
      "firm":"信達證券",
      "analyst":"馮翠婷",
      "email":"fengcuiting@cindasc.com"
      },
      {
      "firm":"Citi",
      "analyst":"Alice Cai",
      "email":"alice.cai@citi.com"
      },
      {
      "firm":"Citi",
      "analyst":"Eric Lau",
      "email":"eric.h.lau@citi.com"
      },
      {
      "firm":"中信證券",
      "analyst":"馮重光",
      "email":"fengchongguang@citics.com"
      },
      {
      "firm":"中信證券",
      "analyst":"鄭逸坤",
      "email":"zhengyikun@citics.com"
      },
      {
      "firm":"Daiwa",
      "analyst":"Candis Chan",
      "email":"candis.chan@hk.daiwacm.com"
      },
      {
      "firm":"Daiwa",
      "analyst":"John Choi",
      "email":"john.choi@hk.daiwacm.com"
      },
      {
      "firm":"安信國際",
      "analyst":"汪陽",
      "email":"alexwang@eif.com.hk"
      },
      {
      "firm":"光大證券",
      "analyst":"付天姿",
      "email":"futz@ebscn.com"
      },
      {
      "firm":"廣發證券",
      "analyst":"曠實",
      "email":"kuangshi@gf.com.cn"
      },
      {
      "firm":"廣發證券",
      "analyst":"葉敏婷",
      "email":"yeminting@gf.com.cn"
      },
      {
      "firm":"高盛",
      "analyst":"Timothy Zhao",
      "email":"timothy.zhao@gs.com"
      },
      {
      "firm":"高盛",
      "analyst":"Ronald Keung",
      "email":"ronald.keung@gs.com"
      },
      {
      "firm":"高盛",
      "analyst":"Eunice Liu",
      "email":"eunice.liu@gs.com"
      },
      {
      "firm":"國聯證券",
      "analyst":"陳夢瑤",
      "email":"cmy@glsc.com.cn"
      },
      {
      "firm":"國信證券",
      "analyst":"曾光",
      "email":"zengguang@guosen.com.cn"
      },
      {
      "firm":"國信證券",
      "analyst":"鐘瀟",
      "email":"zhongxiao@guosen.com.cn"
      },
      {
      "firm":"國信證券",
      "analyst":"張魯",
      "email":"zhanglu5@guosen.com.cn"
      },
      {
      "firm":"國泰君安",
      "analyst":"劉越男",
      "email":"liuyuenan@gtjas.com"
      },
      {
      "firm":"國泰君安",
      "analyst":"莊子童",
      "email":"zhuangzitong026312@gtjas.com"
      },
      {
      "firm":"國泰君安",
      "analyst":"蘇穎",
      "email":"suying@gtjas.com"
      },
      {
      "firm":"海通證券",
      "analyst":"汪立亭",
      "email":"wanglt@haitong.com"
      },
      {
      "firm":"海通證券",
      "analyst":"李宏科",
      "email":"lhk11523@haitong.com"
      },
      {
      "firm":"海通證券",
      "analyst":"曹蕾娜",
      "email":"cln13796@haitong.com"
      },
      {
      "firm":"海通證券",
      "analyst":"王禕婕",
      "email":"wyj13985@haitong.com"
      },
      {
      "firm":"海通國際",
      "analyst":"許櫻之",
      "email":"yingzhi.xu@htisec.com"
      },
      {
      "firm":"華安證券",
      "analyst":"金榮",
      "email":"jinrong@hazq.com"
      },
      {
      "firm":"華安證券",
      "analyst":"石月昊",
      "email":"shiyuehao@hazq.com"
      },
      {
      "firm":"華創證券",
      "analyst":"劉欣",
      "email":"liuxin3@hcyjs.com"
      },
      {
      "firm":"華創證券",
      "analyst":"郭子萱",
      "email":"guozixuan@hcyjs.com"
      },
      {
      "firm":"華泰證券",
      "analyst":"夏路路",
      "email":"xialulu@htsc.com"
      },
      {
      "firm":"華泰證券",
      "analyst":"詹博",
      "email":"zhanbo@htsc.com"
      },
      {
      "firm":"華泰證券",
      "analyst":"鄭裕佳",
      "email":"zhengyujia@htsc.com"
      },
      {
      "firm":"興業證券",
      "analyst":"宋健",
      "email":"songjian@xyzq.com.cn"
      },
      {
      "firm":"開源證券",
      "analyst":"方光照",
      "email":"fangguangzhao@kysec.cn"
      },
      {
      "firm":"東北證券",
      "analyst":"錢熠然",
      "email":"qianyr@nesc.cn"
      },
      {
      "firm":"東北證券",
      "analyst":"宋雨翔",
      "email":"songyx@nesc.cn"
      },
      {
      "firm":"東方證券",
      "analyst":"項雯倩",
      "email":"xiangwenqian@orientsec.com.cn"
      },
      {
      "firm":"國海證券",
      "analyst":"姚蕾",
      "email":"yaol02@ghzq.com.cn"
      },
      {
      "firm":"國海證券",
      "analyst":"譚瑞嶠",
      "email":"tanrq@ghzq.com.cn"
      },
      {
      "firm":"東吳證券",
      "analyst":"張良衛",
      "email":"zhanglw@dwzq.com.cn"
      },
      {
      "firm":"東吳證券",
      "analyst":"周良玖",
      "email":"zhoulj@dwzq.com.cn"
      },
      {
      "firm":"西南證券",
      "analyst":"劉言",
      "email":"liuyan@swsc.com.cn"
      },
      {
      "firm":"西南證券",
      "analyst":"王湘傑",
      "email":"wxj@swsc.com.cn"
      },
      {
      "firm":"申萬宏源研究",
      "analyst":"黃哲",
      "email":"huangzhe@swsresearch.com"
      },
      {
      "firm":"天風證券",
      "analyst":"孫海洋",
      "email":"sunhaiyang@tfzq.com"
      },
      {
      "firm":"西部证券",
      "analyst":"冯海星",
      "email":"fenghaixing@research.xbmail.com.cn"
      },
      {
      "firm":"浙商證券",
      "analyst":"馬莉",
      "email":"mali@stocke.com.cn"
      },
      {
      "firm":"浙商證券",
      "analyst":"陳釗",
      "email":"chenzhao01@stocke.com.cn"
      },
      {
      "firm":"中泰證券",
      "analyst":"韓筱辰",
      "email":"hanxc@zts.com.cn"
      }
  ]
}

const meta = {
  directors,
  auditCommittee,
  remunerationCommittee,
  governanceDocuments,
  nominationCommittee,
  pressRelease,
  analysts,
}

export default meta;