/*
 * @Author: liuzhenli
 * @Date: 2021-05-18 10:46:12
 * @LastEditors: liuzhenli
 * @LastEditTime: 2021-05-18 10:46:42
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import {HashRouter as Router} from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'


smoothscroll.polyfill()
ReactDOM.render(
  <>
    <Router>
      <App/>
    </Router>
  </>,
  document.getElementById('root')
);;require('./index.less')