

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _readOnlyError2 = _interopRequireDefault(require("@babel/runtime/helpers/readOnlyError"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _md = _interopRequireDefault(require("../../../../md5"));

/*
 * @Author: your name
 * @Date: 2020-08-05 15:21:58
 * @LastEditTime: 2021-01-27 22:06:57
 * @LastEditors: liuzhenli
 * @Description: In User Settings Edit
 * @FilePath: /track/utils/index.js
 */
// import ENV from './env';
var ArrayProto = Array.prototype;
// var FuncProto = Function.prototype;
var ObjProto = Object.prototype;
// var slice = ArrayProto.slice;
var toString = ObjProto.toString;
// var hasOwnProperty = ObjProto.hasOwnProperty;
// var nativeBind = FuncProto.bind;
var nativeForEach = ArrayProto.forEach;
// var nativeIndexOf = ArrayProto.indexOf;
// var nativeIsArray = Array.isArray;
var breaker = {};
var utils = {
  getDomain: function getDomain() {
    var match = window.location.hostname.match(/[^.]+\.[^.]+$/);
    return match ? match[0] : 'koolearn.com';
  },
  getLogType: function getLogType() {
    var match = window.location.hostname.match(/[^.]+\.[^.]+$/);
    return match ? match[0] : "unknowndomain";
  },
  getHost: function getHost(env) {
    var host = '';

    if (env && this.isString(env)) {
      if (/trunk|neibu|release/.test(env)) {
        host = ".".concat(env);
      } else if (/test/.test(env)) {
        host = env;
      } else if (/prod/.test(env)) {
        host = '';
      } else {
        host = '';
      }
    } else {
      var isNeibu = /\w(\.trunk|\.neibu|\.release|test)\./.exec(window.location.hostname);
      host = isNeibu && isNeibu[1];
    }

    return host ? "https://dj".concat(host, ".koolearn.com") : "https://dj.koolearn.com";
  },
  getCookie: function getCookie(name) {
    var cookieList = window.document.cookie.match(new RegExp("(^| )".concat(name, "(?:=([^;]*))?(;|$)")));
    if (cookieList && cookieList[2]) return cookieList[2];
    return "";
  },
  setCookie: function setCookie(name, value, isSessionCookie) {
    var time = new Date(new Date().getTime() + 3600 * 24 * 1000 * 365);
    var expires = isSessionCookie ? "" : "expires=".concat(time.toGMTString(), ";");
    window.document.cookie = "".concat(name, "=").concat(value, ";path=/;").concat(expires, "domain=").concat(this.getDomain());
  },
  getKuid: function getKuid(time) {
    var kuid;

    if (this.getCookie("KUID")) {
      kuid = this.getCookie("KUID");
    } else {
      var string = (window.returnCitySN ? window.returnCitySN.cip : "0.0.0.0") + "_" + (navigator.userAgent ? navigator.userAgent : "userAgent") + "_" + new Date().getTime() + "_koo";
      kuid = (0, _md["default"])(string);
      this.setCookie("KUID", kuid);
    }

    return kuid;
  },

  /**
   * 解析字符串，将key=main_choose_grade_id&value=3这样的字符串转换成一个带有=前键值的对象方法
   * param     {String} str 字符串
   * @return   {Object} 返回str转换后的对象
   */
  parseStr: function parseStr(str) {
    var res = {};
    var arr = []; // 将字符串以&为分割点转换成数组

    arr = str.split("&"); // 此处过滤掉无效参数，兼容url结尾& 【hasModifiy】

    arr = arr.filter(function(x) {
      return /\w+=\w*/.test(x);
    }); // 循环字符串转换为了对应键值的对象,字符串必须是ey=main_choose_grade_id&value=3格式

    this.each(arr, function(v, k) {
      var _v$split = v.split("="),
        _v$split2 = (0, _slicedToArray2["default"])(_v$split, 2),
        name = _v$split2[0],
        value = _v$split2[1];

      res[decodeURIComponent(name).toLowerCase()] = decodeURIComponent(value);
    });
    return res;
  },
  throttle: function throttle(func, wait) {
    var previous = 0;
    return function() {
      var now = Date.now();
      var ctx = this;
      var args = arguments;

      if (now - previous > wait) {
        func.apply(ctx, args);
        previous = now;
      }
    };
  },

  /**
   * 根据不同的移动设备赋予不同的clientid数值
   * return  {String} 返回clientid的值
   */
  clientUserAgent: function clientUserAgent() {
    var userAgentInfo = navigator.userAgent;
    // var clientID = ""; // 移动端匹配字段

    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod", "phone", "pad", "pod", "BlackBerry", "Mobile", "webOS", "ios"];
    var flagSystem = "web";

    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) >= 0) {
        // 如果是移动端，需要进一步判断是ios还是Android
        if (/android|Linux/gi.test(userAgentInfo)) {
          // 安卓系统
          flagSystem = "android";
        } else if (!!/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(userAgentInfo)) {
          //  eg: (iPod; CPU Mac OS X
          // ios系统
          flagSystem = "ios";
        }
      }
    }

    return flagSystem;
  },

  /**
   * @description:  prelogid在所有js加载完成之前记录，预防其他js文件有替换刚进入页面的logid,因为有在加载js中自动请求ajax请求，ajax请求会替换掉刚进页面的logid
   * 保存prelogid之前先判断是否已经存在上一页的prelogid，如果存在，则记录下来，当前页面的所有日志的prelogid字段都用这个数值
   * @param {null}
   * @return {null}
   */
  setPrelogid: function setPrelogid() {
    window.koo_webLog_prelogid = this.getCookie("prelogid") || "";

    var getValue = function() {
      var cip = window.returnCitySN ? window.returnCitySN.cip : "0.0.0.0";
      var ua = navigator.userAgent || "userAgent";
      var time = new Date().getTime();
      var value;
      return function() {
        value = value || (0, _md["default"])("".concat(cip, "_").concat(ua, "_").concat(time, "_koo"));
        return value;
      };
    }();

    var prelogid = this.getCookie("X-Request-Id") || getValue() || "";
    this.setCookie("prelogid", prelogid); // 预埋log.session

    if (!this.getCookie("log.session")) {
      this.setCookie("log.session", getValue(), true);
    }
  },

  /**
   * @description: 拼接queryString
   * @param {url: string}
   * @param {params: object}
   * @return {string}
   */
  setQueryString: function setQueryString(obj) {
    var str = [];
    this.each(obj, function(v, k) {
      str.push("".concat(k, "=").concat(v));
    });
    return str.join("&");
  },
  // 发送日志请求
  send: function send(url, queryParams) {
    var eventLogimg = new Image();
    var hyphen = url.indexOf("?") === -1 ? "?" : "";
    eventLogimg.src = "".concat(url).concat(hyphen).concat(queryParams);
  },
  getElementByAttr: function getElementByAttr(dom, attr) {
    while (true) {
      if (dom === window.document.body) break;
      if (dom.hasAttribute(attr)) break;
      dom = dom.parentNode;
    }

    return dom;
  },

  /**
   * @description: 页面可视区域高度
   * @param {}
   * @return {number}
   */
  getPageHeight: function getPageHeight() {
    return window.innerHeight ? window.innerHeight : window.document.documentElement.clientHeight ? window.document.documentElement.clientHeight : window.document.body.offsetHeight || 0;
  },
  getPageWidth: function getPageWidth() {
    return window.innerWidth ? window.innerWidth : window.document.documentElement.clientWidth ? window.document.documentElement.clientWidth : window.document.body.offsetWidth || 0;
  },

  /**
   * 原声js页面任意元素距页面顶部和左边距的总距离方法
   * @param  {String || Object} 目标元素节点
   * return  {Object} 返回左边距和顶边距对象数据
   */
  offsetLT: function offsetLT(ele) {
    var top = ele.offsetTop;
    var left = ele.offsetLeft; // 将ele换成起相对定位的父元素

    var offsetEle = ele.offsetParent; // 只要还有相对定位的父元素

    while (offsetEle) {
      // 获得父元素 距他父元素的top值,累加到结果中
      top += offsetEle.offsetTop;
      left += offsetEle.offsetLeft; // 再次将elem换成他相对定位的父元素上;

      offsetEle = offsetEle.offsetParent;
    }

    return {
      left: left,
      top: top
    };
  },
  // 下划线改驼峰
  toHump: function toHump(str) {
    if (!str) return '';
    return str.replace(/_(\w)/g, function(all, letter) {
      return letter.toUpperCase();
    });
  },
  // 驼峰改下划线
  toLine: function toLine(str) {
    if (!str) return '';
    return str.replace(/([A-Z])/g, function(all, letter) {
      return "_".concat(letter.toLowerCase());
    });
  },
  isString: function isString(obj) {
    return toString.call(obj) === '[object String]';
  },
  isObject: function isObject(obj) {
    return toString.call(obj) === '[object Object]';
  },
  isFunction: function isFunction(obj) {
    return toString.call(obj) === '[object Function]';
  },
  bind: function bind(fn, thisArg) {
    return function wrap() {
      var args = new Array(arguments.length);

      for (var i = 0; i < args.length; i++) {
        args[i] = arguments[i];
      }

      return fn.apply(thisArg, args);
    };
  },

  /**
   * @description: extends object a by adding the propeties of b
   * @param {object} a 
   * @param {object} b 
   * @param {object} thisArg  
   * @return {object} 扩展后的a
   */
  extend2bind: function extend2bind(a, b, thisArg) {
    var _this = this;

    this.each(b, function(v, k) {
      if (thisArg && _this.isFunction(v)) {
        a[k] = _this.bind(v, thisArg);
      } else if (_this.isObject(v)) {
        a[k] = a[k] || {};

        _this.extend2bind(a[k], v, thisArg);
      } else {
        a[k] = v;
      }
    });
    return a;
  },
  each: function each(obj, iterator, context) {
    if (obj == null) {
      return false;
    }

    if (nativeForEach && obj.forEach === nativeForEach) {
      obj.forEach(iterator, context);
    } else if (obj.length === +obj.length) {
      for (var i = 0, l = obj.length; i < l; (0, _readOnlyError2["default"])("i"), i++) {
        if (i in obj && iterator.call(context, obj[i], i, obj) === breaker) {
          return false;
        }
      }
    } else if (this.isFunction(obj.contructor) && obj.contructor !== Function) {
      Object.getOwnPropertyNames(obj).forEach(iterator, context);
    } else {
      // TODO 解决es6 class 属性 不可枚举，暂时用这样的方法fix，待优化
      Object.getOwnPropertyNames(obj).forEach(function(key) {
        if (iterator.call(context, obj[key], key, obj) === breaker) {
          return false;
        }
      }, context); // for (const key in obj) {
      //   if (hasOwnProperty.call(obj, key)) {
      //     if (iterator.call(context, obj[key], key, obj) === breaker) {
      //       return false;
      //     }
      //   }
      // }
    }
  },
  extend: function extend(obj) {
    var _this2 = this;

    this.each(ArrayProto.slice.call(arguments, 1), function(source) {
      for (var prop in source) {
        if (source[prop] !== void 0) {
          // 防止相同属性被覆盖
          if (_this2.isObject(source[prop])) {
            obj[prop] = _this2.extend({}, obj[prop] || {}, source[prop]);
          } else {
            obj[prop] = source[prop];
          }
        }
      }
    });
    return obj;
  }
}; // export default (window => utils)(ENV);

var _default = utils;
exports["default"] = _default;