import React,{useState, useEffect} from 'react'
import classnames from 'classnames'
import {getAnchorPosition,anchorTo,getScrollTop} from '../../utils/common'

const menuList = ['2024','2023','2022','2021','2020','2019','2018','2017'];
function AnchorBar(){
    const [current,setCurrent] = useState(0);
    const handleClick = (index) => {
        anchorTo({elementId:menuList[index],offset:-80,behavior:'auto'});
        setCurrent(index);
    }
    function handleScroll() {
        const scrollTop = getScrollTop();
        const anchorsPositions = getAnchorPosition(menuList);
        if(anchorsPositions){
            let i;
            for(i = anchorsPositions.length-1;i>=0;i--){
                if(scrollTop >= anchorsPositions[i].height - 80){
                    setCurrent(i)
                    break;
                }
            }
        }
    }
    useEffect(() =>{
        window.onscroll = handleScroll
        return function(){
            document.removeEventListener('scroll',handleScroll,false)
        }
    })

    return (
        <div className="anchor-vertical-container">
            <div className="anchor-line"></div>
            <ul>
                {menuList.map((menu,index)=>
                    <li key={menu} className={classnames("anchor-item",{"anchor-item-active":current === index})} onClick={() => handleClick(index)}><span className="anchor-ink"></span>{menu}</li>
                )}
            </ul>
        </div>
    )
}

export default AnchorBar;;require('./index.less')