import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { trackClick } from '@/utils';
import classnames from 'classnames';

import { sectabMap } from '../../config/trackMap'
import { getAnchorPosition } from '@/utils/common.js'

@withTranslation()
class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needFixed: false,
      selectedKey: null,
      preVisible: false,
      nextVisible: true,
      navSlidePos: 0,
      fixHeight: 0,
    }
    this.ref = React.createRef();
    this.scrollNavRef = React.createRef();
    this.t = props.t
  }
  componentDidMount() {
    const fixHeight = this.ref.current.offsetTop;
    const header = document.getElementById('header-box');
    this.setState({
      fixHeight,
      header
    });
    const timer = setInterval(() => {
      const scrollTop = this.getScrollTop();
      this.autoHighlight(scrollTop);
    }, 500);
    this.setState({
      timer
    })
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);//按下键盘按键时触发事件
    const { timer } = this.state;
    clearInterval(timer);
  }

  handleScroll = () => {
    const scrollTop = this.getScrollTop();
    const marginBot = this.getMarginBot();
    const { needFixed, fixHeight, header } = this.state;
    const { changeFixed } = this.props;
    this.autoHighlight(scrollTop);
    if (scrollTop < fixHeight) {// 滚动最小高度
      this.setState({ needFixed: false })
      changeFixed(false)
      if (header) {
        header.style.display = 'block'
      }
    } else if (scrollTop >= fixHeight && marginBot > 100) { // 滚动吸顶
      this.setState({ needFixed: true })
      changeFixed(true)
      if (header) {
        header.style.display = 'none'
      }
    }
    if (marginBot <= 0) { // 到底部取消固定
      needFixed && this.setState({ needFixed: false })
      changeFixed(false)
      if (header) {
        header.style.display = 'block'
      }
    }
  }

  componentWillReceiveProps(props) {
    const fixHeight = this.ref.current.offsetTop || this.state.fixHeight;
    const anchorsPositions = (props.isLoaded || props.frameLoadingState) ? getAnchorPosition(props.menu) : [];
    this.setState({
      anchorsPositions,
      fixHeight,
    });
    if (!props.canSwitch) {
      this.setState({
        preVisible: false,
        nextVisible: false
      })
    }
  }
  // componentWillReceiveProps替代方案
  //    static getDerivedStateFromProps(nextProps,prevState){
  //     const  anchorsPositions = (nextProps.isLoaded  ||  nextProps.frameLoadingState )? getAnchorPosition(nextProps.menu) : [];
  //     return {
  //         anchorsPositions,
  //         nextVisible: !!nextProps.canSwitch,
  //         preVisible:false,
  //     };
  //    }

  /**
   * 获取滚动条滚动的距离
   */
  getScrollTop() {
    let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) { // Safiri计算scrollTop
      scrollTop = window.pageYOffset;
    }
    return scrollTop;
  }

  /**
   * 获取距离底部的距离
   */
  getMarginBot() {
    let marginBot = 0;
    if (document.compatMode === "CSS1Compat") {
      marginBot = document.documentElement.scrollHeight
        - Math.max(document.documentElement.scrollTop, document.body.scrollTop)
        - document.documentElement.clientHeight;
    }
    else {
      marginBot = document.body.scrollHeight
        - document.body.scrollTop
        - document.body.clientHeight;

    }
    return marginBot;
  }

  /**
   * 滚动时自动高亮
   */
  autoHighlight(position) {
    const { anchorsPositions } = this.state;
    let selectedKey = '';
    const marginBot = this.getMarginBot();
    if (anchorsPositions) {
      let i;
      for (i = anchorsPositions.length - 1; i >= 0; i--) {
        if (marginBot <= 150) {
          selectedKey = anchorsPositions[anchorsPositions.length - 1].name;
          break;
        } else if (position >= anchorsPositions[i].height) {
          selectedKey = anchorsPositions[i].name;
          break;
        }
      }
      if (i < 0) {
        selectedKey = undefined;
        if (selectedKey !== this.state.selectedKey) {
          this.setState({
            selectedKey
          })
        }
      } else {
        if (selectedKey !== this.state.selectedKey) {
          this.setState({
            selectedKey,
          });
          if (this.scrollNavRef.current && this.ref.current.offsetWidth) {
            const els = document.getElementsByClassName('subNav-li');
            const offsetLeft = els[i].offsetLeft > Math.floor(this.ref.current.offsetWidth / 2) ? els[i].offsetLeft - 100 : 0;
            this.scrollNavRef.current.scrollLeft = offsetLeft;
          }
        }
      }
    }
  }

  anchorTo(anchorName, pathname) {
    trackClick({
      'sectab': sectabMap[anchorName]
    });
    let anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      window.scrollTo({ top: anchorElement.offsetTop, behavior: "smooth" });
    }
  }

  navScrollToEnd = () => {
    const el = this.scrollNavRef.current;
    el.scrollLeft = el.offsetWidth;
    this.setState({
      preVisible: true,
      nextVisible: false,

    })
  }

  navScrollToStart = () => {
    this.scrollNavRef.current.scrollLeft = 0;
    this.setState({
      preVisible: false,
      nextVisible: true,
    })
  }
  filter = (menu) => {
    return menu && menu.length && menu.filter(item => item.name !== 'events')
  }
  render() {
    const { menu } = this.props;
    let filter_menu = this.filter(menu)
    const { needFixed, selectedKey, preVisible, nextVisible } = this.state;
    return (
      <div className={classnames("h-anchour-box", { "fixed": needFixed })} ref={this.ref}>
        <div className="subnav-container">
          {preVisible && <div className="prev-container">
            <div className="prev" onClick={this.navScrollToStart}></div>
          </div>
          }
          <ul className="subNav-ul" ref={this.scrollNavRef}>
            {filter_menu.map(item => {
              return (
                <li
                  className={classnames("subNav-li", { "active": item.name === selectedKey })}
                  key={item.name}
                  onClick={e => { this.anchorTo(item.name, item.href) }}
                >
                  {this.t(`header.submenu.name.${item.name}`)}
                </li>
              )
            })}
          </ul>
          {nextVisible && <div className="next-container">
            <div className="next" onClick={this.navScrollToEnd}></div>
          </div>}
        </div>
      </div>
    )
  }
}
export default SubNav;;require('./index.less')