const header = {
  "header.menu.name.home": '公司簡介',
  "header.menu.name.business": '業務',
  "header.menu.name.employee": '員工',
  "header.menu.name.investor": '投資者關係',
  "header.submenu.name.contacts": '聯繫我們',
  "header.submenu.name.brief": '關於我們',
  "header.submenu.name.vision": '願景',
  "header.submenu.name.milestone": '發展里程碑',
  "header.submenu.name.team": '董事會',
  "header.submenu.name.culture": '企業文化',
  "header.submenu.name.responsibility": '社會責任',
  "header.submenu.name.news": '新聞動態',
  "header.submenu.name.k-12Education": 'K-12教育',
  "header.submenu.name.privateLabelProducts": "自營產品及直播電商",
  "header.submenu.name.collegeEducation": '大學教育',
  "header.submenu.name.preSchoolEducation": '學前教育',
  "header.submenu.name.institutionalCustomers": '機構客戶',
  "header.submenu.name.associatedCompanies": '聯營業務',
  "header.submenu.name.relatedBrands": '相關品牌',
  "header.submenu.name.IRContacts": '聯繫我們',
  "header.submenu.name.cooperation": '合作洽談',
  "header.submenu.name.talentDevelopment": '人才發展',
  "header.submenu.name.workEnvironment": '辦公環境',
  "header.submenu.name.employeeActivities": '員工活動',
  "header.submenu.name.joinUs": '加入我們',
  "header.submenu.name.StockInformation": '股價信息',
  "header.submenu.name.CorporateGovernance": '公司管治',
  "header.submenu.name.GovernanceDocuments": '治理文件',
  "header.submenu.name.FinancialReport": '財務報告',
  "header.submenu.name.Announcements": '公告及通函',
  "header.submenu.name.PressRelease": '新聞發佈',
  "header.submenu.name.AnalystCoverage": '分析師列表',
  "header.submenu.name.E-mailAlert": '電郵訂閱',
}
const global = {
  "global.company.name": "東方甄選",
  "global.governance.documents1.title": "全球發售 / 招股書",
  "global.governance.documents2.title": "股東提名人選參選本公司董事的程序",
  "global.governance.documents3.title": "VIE合同1 - 獨家管理顧問及業務合作協議",
  "global.governance.documents4.title": "VIE合同2 - 獨家購股權協議",
  "global.governance.documents5.title": "VIE合同3 - 股份質押協議",
  "global.governance.documents6.title": "2019 ESG報告",
  "global.governance.documents7.title": "2020 ESG報告",
  "global.governance.documents8.title": "2021 ESG報告",
  "global.governance.documents9.title": "2022 ESG報告",
  "global.governance.documents10.title": "2023 ESG報告",
  "global.press20240823.title": "東方甄選公佈2024財年全年業績",
  "global.press20240619.title": "東方甄選6月19日新品發佈會",
  "global.press20240124.title": "東方甄選公佈2024財年中期業績",
  "global.press20230825.title": "東方甄選公佈2023財年全年業績",
  "global.press20230117.title": "新東方在線公佈2023財年中期業績",
  "global.press20220826.title": "新東方在綫公佈2022財年全年業績",
  "global.press20220121.title": "新東方在線公佈2022財年中期業績",
  "global.press1.title": "新東方在綫公佈2021財年全年業績",
  "global.press2.title": "新東方在綫公佈2021財年中期業績",
  "global.press3.title": "新東方在綫公佈2020財年全年業績",
  "global.press4.title": "新東方在綫公佈2020財年中期業績",
  "global.press5.title": "新東方在綫公佈2019財年全年業績及收購東方優播49%股權",
  "global.press6.title": "新東方在綫成功於香港聯交所主板掛牌上市",
  "global.press7.title": "新東方在綫公佈股份發售結果",
  "global.press8.title": "新東方在綫宣佈於聯交所主板上市計畫",
  "global.more": '了解更多',
  "global.all": '查看全部',
  "global.yuminghong.name": '俞敏洪',
  "global.yuminghong.position": '東方甄選董事長，執行董事，CEO',
  "global.yuminghong.brief": '俞先生，60歲，執行董事、提名委員會主席、董事會主席兼本公司行政總裁。俞先生亦為北京迅程（自2015年5月起）及新東方集團的若干公司（包括樂詞網絡）的主席兼董事。俞先生於1985年7月獲中國北京大學頒授英語學士學位。俞先生為新東方的創辦人，自2001年起一直擔任其董事會執行主席，並曾於2017年8月（及自2018年3月起擔任獨立董事）至2019年6月擔任SunlandsTechnologyGroup（其美國存託股份於紐約證券交易所上市，NYSE：STG）的董事。自2001年起，俞先生一直為新東方（我們的控股股東，其美國存託股份於紐約證券交易所上市（NYSE：EDU）及股份於聯交所上市（股份代號：9901）之公司）主席及董事。',
  "global.sundongxu.name": '孫東旭',
  "global.sundongxu.position": "非執行董事",
  "global.sundongxu.brief": `孫先生，37歲，為非執行董事。於本集團內，孫先生為：北京迅程的董事（自2019年9月起）（亦為北京迅程的行政總裁）、德信東方的董事（自2019年8月起）（亦為德信東方的行政總裁）、迅程香港的董事（自2019年11月起）、酷學慧思的董事（自2019年11月起）（亦為酷學慧思的行政總裁）、西安睿盈的董事（自2020年4月至2021年4月）、新東方無憂在線（香港）教育科技有限公司的董事（自2020年8月起）、東方優選（北京）科技有限公司的董事（自2021年10月起）、北京新遠方人力資源服務有限公司的董事（自2021年10月起）、東方甄選（北京）科技有限公司的董事（自2021年12月起）。此前，孫先生為西安新東方學校的校長（自2016年4月至2018年7月）及新東方西北區區域總裁（自2016年4月至2018年5月）。於2007年6月至2008年6月，孫先生於新東方集團（其時包括本集團）天津新東方學校國外考試部任職教師。於2008年6月至2016年4月，孫先生於合肥新東方學校任職，入職時任職國外考試部主管助理（自2008年6月至2009年6月），並在校內擔任多個職位，最終校長（自2013年10月至2016年4月）。除在本集團任職外，孫先生亦擔任新東方中國的助理副總裁（自2016年4月至2019年4月）及副總裁（自2019年4月起）。孫先生於2007年6月獲中國南開大學頒授工學學士學位，主修計算機科學與技術。`,
  "global.yinqiang.name": '尹強',
  "global.yinqiang.position": "執行董事，CFO",
  "global.yinqiang.brief": "尹先生，49歲，為執行董事兼本公司首席財務官。彼亦自2016年1月起擔任北京迅程的董事兼財務總監，自2019年7月至2020年11月擔任珠海崇勝的董事及自2020年4月擔任西安睿盈的董事及總經理（自2021年4月起），並自2020年10月起擔任海南海悅東方網絡科技有限公司的董事兼總經理。尹先生於1996年7月獲中國首都經濟貿易大學頒授經濟學學士學位，並於2008年7月獲中國北京大學頒授工商管理碩士學位。尹先生亦自2001年10月起為中國合資格會計師。除在本集團任職外，尹先生亦擔任新東方中國的財務總監及助理副總裁（自2005年6月至2016年5月）及副總裁（自2019年4月起）。尹先生曾於1996年至2001年擔任羅兵咸永道會計師事務所的高級會計師。",
  "global.sunchang.name": "孫暢",
  "global.sunchang.position": "非執行董事",
  "global.sunchang.brief": "孫女士，55歲，為非執行董事兼薪酬委員會成員。彼擔任本公司聯席行政總裁直至2020年1月19日。孫女士為北京迅程（自2015年5月起）及德信東方（自2018年3月起）的董事。孫女士於1990年7月獲中國北京師範大學頒授學前教育學士學位，並於1999年7月獲中國的中國人民大學頒授工商管理碩士學位。除在本集團任職外，孫女士自2012年至2016年及自2016年至2020年分別擔任新東方中國的助理副總裁及副總裁。孫女士曾擔任中國網絡通信集團公司（現為中國聯合網絡通訊集團有限公司，或稱中國聯通）的投資部總經理（2000年至2004年）及微軟（中國）有限公司的營銷經理（1997年至2000年）。",
  "global.wuqiang.name": "吳強",
  "global.wuqiang.position": "非執行董事",
  "global.wuqiang.brief": "吳先生，47歲，為非執行董事及審核委員會成員。吳先生亦為樂詞網絡（自2015年11月起）的董事。吳先生於1997年7月獲中國的中國科學自動化研究所頒授工程學碩士學位。除在本集團任職外，吳先生亦曾於2016年至2019年4月擔任新東方中國的副總裁，並自2019年4月起一直擔任該公司高級副總裁。彼曾於2014年7月至2018年4月擔任北京新東方學校的校長。此前，吳先生曾擔任北京新東方學校的副校長（2013年至2014年）、新東方中國的研發總監（2005年至2007年）、其他位於青島及成都的新東方學校的副校長（2008年至2012年）及北京明日東方科技有限公司的總裁（2000年至2005年）。",
  "global.liangyuhua.name": "梁育華",
  "global.liangyuhua.position": '非執行董事',
  "global.liangyuhua.brief": "梁女士，37歲，為非執行董事。彼亦自2019年1月起擔任北京迅程的董事。梁女士於2004年6月獲美國賓夕凡尼亞州大學沃頓商學院頒授經濟學理學士學位（專修金融學）。除在本集團任職外，梁女士自2013年12月起一直擔任騰訊控股有限公司附屬公司樂娛互動香港有限公司的戰略發展部副總經理，並曾於2012年11月至2013年6月擔任Arete Research Services L.L.P.的高級研究分析師及於2010年8月至2012年11月擔任高盛（亞洲）有限責任公司的投資研究部執行董事。",
  "global.linzheying.name": "林哲瑩",
  "global.linzheying.position": "獨立非執行董事",
  "global.linzheying.brief": "林先生，58歲，為獨立非執行董事、薪酬委員會主席、提名委員會及審核委員會成員。林先生於1987年7月、於2006年7月及2008年6月分別獲得山西財經大學（前身為山西財經學院）規劃統計專業學士學位、中國北京大學光華管理學院工商管理碩士學位及法國雷恩高等商學院工商管理博士學位。除本集團外，林先生擔任深圳市豐巢科技有限公司董事（2016年11月至2017年12月）；及一直擔任順豐控股股份有限公司副總裁（自2014年6月至2019年12月）及古玉資本管理有限公司執行董事（自2011年1月起）。 除本公司外，林先生亦擔任或曾擔任以下上市公司的董事職務：(i)順豐控股股份有限公司（深圳證券交易所上市公司，股份代號：002352）執行董事兼副主席（自2017年3月至2022年12月）；(ii)上海東正汽車金融股份有限公司（香港聯交所上市公司，股份代號：2718）獨立非執行董事（自2018年8月至2020年6月）；及(iii)呈和科技股份有限公司（上海證券交易所上市公司，股份代號：688625）董事（自2015年12月至2021年9月）。",
  "global.dongruibao.name": "董瑞豹",
  "global.dongruibao.position": "獨立非執行董事",
  "global.dongruibao.brief": "董先生，52歲，為獨立非執行董事、審核委員會主席兼薪酬委員會及提名委員會成員。董先生於1993年5月獲美國威斯康星州大學麥迪遜分校頒授會計學（另修電腦科學）學士學位。董先生先前為美國註冊會計師協會(AICPA)會員（1995年至2009年）及特許金融分析師（1999年至2009年）。董先生自2009年至2021年一直擔任網易（其美國存託股份於納斯達克上市（納斯達克：NTES）及股份於聯交所上市（股份代號：9999）之公司）的非執行董事。彼曾擔任網易的執行董事（2003年至2009年）及聯席營運總監（2004年至2009年）。",
  "global.kuangweixin.name": "鄺偉信",
  "global.kuangweixin.position": "獨立非執行董事",
  "global.kuangweixin.brief": "鄺先生，57歲，為獨立非執行董事兼審核委員會成員。鄺先生為英格蘭及威爾士特許會計師公會會員。鄺先生於1987年6月獲英國劍橋大學頒授文學士學位。鄺先生自2013年8月起擔任中國金屬資源利用有限公司聯交所上市公司，股份代號：1636）的執行董事。鄺先生亦為順風國際清潔能源有限公司（聯交所上市公司，股份代號：1165）（自2014年7月起）、千百度國際控股有限公司（聯交所上市公司，股份代號：1028）（自2011年8月起）、中國服飾控股有限公司（聯交所上市公司，股份代號：1146）（自2011年6月起）及中國新高教集團有限公司（聯交所上市公司，股份代號：2001）（自2017年3月起）的獨立非執行董事。鄺先生曾擔任古杉環保能源有限公司（紐約證券交易所上市公司(NYSE：GU））的總裁（2007年12月至2012年10月）。此前，彼曾擔任里昂證券資本市場有限公司投資銀行部董事總經理以及香港及中國股票資本市場主管（2004年3月至2006年7月）以及嘉誠證券有限公司的董事（2002年至2003年）及企業財務部的總經理（1997年至2003年）。",
  "global.video.publicity": "https://daxueui-cos.koocdn.com/images/fe_upload/2023/2/2023-2-7-1675763146129.mp4"
}
const home = {
  "home.banner.title": "新東方，老師好",
  "home.brief.title": "關於我們",
  "home.brief.desc": "我們的定位是做一個為客戶甄選好物的直播購物平台；以東方甄選品牌的自營農產品為核心的產品和科技公司；為客戶帶來愉快精神體驗的文化傳播公司。通過提供優質產品及服務，弘揚中華傳統文化及積極價值觀，我們希望為每一名客戶提供美好、健康、品質生活...",
  "home.vision.title": "企業願景",
  "home.vision.desc.paragraph.one": "為客戶甄選好物的直播購物平台",
  "home.vision.desc.paragraph.two": "以東方甄選品牌的自營農產品為核心的產品科技公司",
  "home.vision.desc.paragraph.three": "以東方甄選品牌的自營農產品為核心的產品科技公司",
  "home.milestone.title": "發展里程碑",
  "home.milestone.seg.2023": "本公司的英文名稱已由「Koolearn Technology Holding Limited」更改為「East Buy Holding Limited」，而本公司的雙重外文名稱已由「新東方在綫科技控股有限公司」更改為「東方甄選控股有限公司」。",
  "home.milestone.seg.2021": "成立“東方甄選”，開展自營產品及直播電商業務",
  "home.milestone.seg.2020": "新東方與Tigerstep認購2.3億美元新東方在綫股份",
  "home.milestone.seg.2019": "3月28日 成功登陸港交所，股票代碼：1797.HK",
  "home.milestone.seg.2018": "本公司於開曼群島註冊成立為獲豁免有限公司。Dragon Cloud和ChinaCity Capital（我們的B系列首次公開發售前投資者）投資於本集團。俞先生（新東方的創辦人以及我們的董事兼董事會主席）透過Tigerstep認購我們的C系列優先股投資於本集團。",
  "home.milestone.seg.2017": "我們推出東方優播，為K-12學生提供的地區性直播互動補習服務",
  "home.milestone.seg.2016": "林芝騰訊投資於本集團",
  "home.milestone.seg.2014": "我們推出自我適應學習系統知心",
  "home.milestone.seg.2012": "我們推出首個英語學習手機應用程序多納",
  "home.milestone.seg.2007": "我們獲得首個機構客戶",
  "home.milestone.seg.2006": '新東方教育科技集團紐約證交所上市（NYSE:EDU）',
  "home.milestone.seg.2005": "我們透過現時為我們境內營運實體的北京迅程建立業務。我們推出首個在綫教育平臺新東方在綫",
  "home.team.title": "董事會",
  "home.culture.title": "企業文化",
  "home.culture.spirit.one.paragraph": "秉持“以客戶為中心”的戰略和“奮鬥者為本”的精神",
  "home.culture.spirit.two.paragraph": "秉承新東方精神，薪火相傳，成為優秀的產品+科技公司",
  "home.culture.spirit.three.paragraph": "為廣大客戶提供更好的體驗和文化知識收穫",
  "home.responsibility.title": "社會責任",
  "home.responsibility.desc": "也許我們做不了太偉大的事情，但作為中國人民的一員，我們如果能夠盡一己之力讓有些事因為我們的努力而更加美好，有些人，因為我們的幫助而對生活充滿希望，我們也會從中體味到一種成就感和幸福感。",
  "home.responsibility.footer": "— — 俞敏洪",
  "home.responsibility.5.title": "新東方在綫積極推動教育公平",
  "home.responsibility.5.abstract.0": "“燭光行動”，11年來，培訓50000名鄉村教師",
  "home.responsibility.5.abstract.1": "“自強之星”，捐贈上億元，資助上萬名大學生",
  "home.responsibility.5.abstract.2": "“希望小學”，共計出資2020萬元，援建7所希望小學",
  "home.responsibility.5.abstract.3": "“情系遠山”，凝聚15家教育企業，覆蓋超過300所學校",
  "home.responsibility.0.title": "新東方在綫向丹寨縣捐贈108萬元公益課",
  "home.responsibility.0.abstract.0": `2021年4月22日，新東方聯合新東方在綫、BOBO英語等為貴州丹寨縣金鐘第一小學捐贈200萬元學習資源「大禮包」`,
  "home.responsibility.0.abstract.1": `新東方在綫捐贈108萬元的在綫課程公益包，共計300套`,
  "home.responsibility.1.title": `新東方在綫發起「燃夢行動」2020公益助學計劃`,
  "home.responsibility.1.abstract.0": `2020年10月31日，新東方在綫正式發起「燃夢行動-2020公益助學計劃」，向貧困家庭、殘疾和重疾家庭的學員發放公益助學金`,
  "home.responsibility.1.abstract.1": `助學金將以助學券的形式進行發放，共分為一、二、三等，分別為800元助學券、600元助學券及400元助學券`,
  "home.responsibility.2.title": "東方甄選積極宣傳非遺文化",
  "home.responsibility.2.abstract.0": '東方甄選自2021年6月以來舉辦多場專場活動，在活動中宣傳非遺文化',
  "home.responsibility.2.abstract.1": `宣傳了包含雲南孔雀舞、海南木偶戲、山東大鼓等超過50種地方優秀非遺文化`,
  "home.responsibility.3.title": "促進當地農產品銷售",
  "home.responsibility.3.abstract.0": "深度溯源，與當地農產品企業合作，幫助企業打開銷路",
  "home.responsibility.3.abstract.1": `發揮企業優勢，整合資源，創新創造，助力農業發展`,
  "home.responsibility.3.abstract.2": `甄選高品質農品好物，給消費者提供健康，美味，高品質，高性價的產品`,
  "home.responsibility.4.title": "“知識帶貨”宣導全民閱讀和健康生活理念",
  "home.responsibility.4.abstract.0": "東方甄選主打“知識帶貨”直播特色，宣導全民閱讀及健康生活理念，並邀請各界文化大咖",
  "home.responsibility.4.abstract.1": `半年內累計圖書銷售達到1,244萬冊`,
  "home.responsibility.4.abstract.2": `茅盾文學獎獲獎作品《額爾古納河的右岸》累計銷量超過50萬冊`,
  "home.news.title": "新聞動態",
}
const business = {
  "business.k12.title": "K-12教育",
  "business.k12.desc": "我們的K-12教育服務包括課外輔導課程，當中涵蓋中國小學至高中的大部分標準學校科目。同時，我們提供專為普通高等學校招生全國統一考試而設的備考課程。我們於2015年推出K-12課程。憑藉我們於K-12方面的專長，我們亦於2017年推出創新的東方優播課程，該課程為一項針對低綫城市學生提供的地區性直播互動輔導服務，該等城市未必擁有便捷的綫下基礎設施，同時存在隨時隨地有效連接綫上平台的需求增長。於2020財年，此分部的付費學生人次為185.6萬名，占我們的付費學生人次總數的65.1%。",
  "business.more": '去官網',
  "business.selfOperatedGoods.title": "自營產品及直播電商",
  "business.selfOperatedGoods.desc01": "自2021年以來，我們成立電商平台東方甄選，依託新東方集團的品牌知名度及多年的運營歷史，我們的直播教室技術和現有人才庫，通過使用直播行銷銷售以農產品、日用品及圖書為主的優質產品，我們開始在抖音及騰訊商城等不同的社交媒體平台試點直播電商活動。",
  "business.selfOperatedGoods.desc02": "東方甄選已成為一家以提供“東方甄選”自營農產品為內核產品的知名產品和科技公司，且該平台構成嚴格的供應鏈管理和售後服務體系的環節，嚴格遵守一系列相關法律法規。我們制定了極其嚴格的准入標準來篩選優質供應商，使每款產品的工藝更精良、成份表更單純。憑藉對客戶需求的深刻理解及客戶回饋，我們通過快速發展自營品牌繼續擴大我們的產品選擇及SKU。通過創新的內容、多樣化的直播活動並加上我們廣博的知識和文化分享，我們為客戶提供了獨特而愉悅的購物體驗。",
  "business.selfOperatedGoods.desc03": "自營品方面，我們在產品開發到銷售服務的全鏈路的嚴格控制過程中會不斷引進和應用科學先進的技術，提升用戶體驗。未來會依託互聯網技術的發展進一步提升產品的科技優越性。截止2022年11月底，自營產品數量已達到65款，包括不同口味的黑豬肉烤腸、五常大米、藍莓原漿及其他熱門產品。我們已收到大量有價值的客戶回饋，熱銷產品的產品種類及比例將繼續增加。",
  "business.selfOperatedGoods.desc04": "此外，我們採用了遠高於行業平均水準的抽檢頻次。東方甄選將質檢部門升級為食品安全中心和品質檢驗科研中心，並和國內一流質檢機構合作，管理每個產品的質檢流程。農殘、藥殘、抗生素、微生物等檢測項，嚴格按照東方甄選的產品要求，做到應檢盡檢。",
  "business.selfOperatedGoods.desc05": "我們不斷優化升級服務體驗，報告期內和順豐、京東展開深度合作，不斷提升配送品質和冷鏈配送範圍。同時，我們建立了會員體系，截至2022年12月底，累計擁有800萬+會員。不斷提升的品牌影響力使得更多的國內外廠商，零售商，生產者認可我們的模式促進合作，而不斷優化的自營品的服務體驗，促進了客戶複購率持續提升。得益於產品生產流水品質，開發原則，高強度質檢，物流及會員體驗，因此，東方甄選自營產品的抖音旗艦店信譽等級是最高等級，店鋪評分4.99分，超過100%同行。店鋪細項評分均為“高”，商品體驗、服務體驗、物流體驗均接近滿分。",
  "business.college.title": "大學教育",
  "business.college.desc": "我們是中國領先的線上課外教育服務供應商，持續提供大學備考及海外備考課程，課程主要供正準備應考統一考試或尋求提高英語能力的大學學生及在職專業人士。憑藉優質課程及內容，我們於線上校外輔導及備考方面擁有強大的核心競爭力和極高的品牌知名度。我們致力成為終身學習夥伴，幫助學生充分發揮潛能。我們通過不同的線上平台及手機應用程式以多種形式提供課程及產品。",
  "business.preSchool.title": "學前教育",
  "business.preSchool.desc": "通過多納學英語及兒童教育應用程序，我們的學前教育分部提供適合兒童的在綫教育內容。我們於2012年推出多納應用程序系列，於2020財年，此分部的付費學生人次為5.4萬名，占我們的付費學生人次總數的1.9%。",
  "business.customers.title": "機構客戶",
  "business.customers.desc": "我們向機構客戶（主要包括高等學校、公共圖書館、電信營運商及線上流媒體視頻供應商）提供服務。我們不斷加深與不同學校和機構的合作，在職業教育方面，我們與多所院校就“新時代工匠學院”項目及新能源與智慧建築專案進行研究交流，並計畫與部分高職院校和本科院校就產業研究院建設及新型人才培養項目進行深度研究與規劃。在中小學和城市圖書館方面，我們擁有“東方樹”素質教育平台，整合科技、程式設計、美育、體育及人文等優質內容，並與學校及培訓機構合作。",
  // "business.companies.title": "聯營業務",
  "business.companies.desc": "我們積極尋求企業合作，拓展業務領域。現時，我們的聯營合作夥伴包括創客教育公司寓樂灣、翼鷗教育和北京時代雲圖有限公司。",
  "business.brands.title": "相關品牌",
  "business.download.title": "立即下載",
  "business.download.koolearn": "新東方在綫",
  "business.download.donut": "多納學英語",
  "business.download.lboc": "樂播課",
  "business.download.college": "新東方在綫大學",
  "business.contact.title": "聯繫我們",
  "business.contact.company.name": "東方甄選控股有限公司",
  "business.contact.company.adress": "地址：北京市海澱區海澱東三街2號新東方南樓18層",
  "business.contact.company.tel": "電話：4000686666",
  "business.contact.company.time": "服務時間：8:00-24:00",
  "business.cooperation.title": "合作洽談",
  "business.cooperation.business.title": "商務合作",
  "business.cooperation.business.contact": "連絡人：陳老師",
  "business.cooperation.business.email": "E-mail：xuanpin@xdfzx.com",
  "business.cooperation.market.title": "市場合作及媒體聯絡",
  "business.cooperation.market.contact": "連絡人：黃老師",
  "business.cooperation.market.email": "E-mail：mkt@xdfzx.com",
  "business.cooperation.online.title": "網盟合作/綫上代理",
  "business.cooperation.online.contact": "連絡人：侯老師",
  "business.cooperation.online.email": "E-mail：houjie01@xdfzx.com",
  "business.followus.title": '東方甄選-關注我們',
  "business.followus.gongzhonghao": '公眾號',
  "business.followus.douyin": '抖音',
  "business.followus.kuaishou": '快手',
  "business.followus.weibo": '微博',
  "business.followus.xiaohongshu": '小紅書',
  "business.followus.tianmao": '天貓',
  "business.followus.jingdong": '京東',
  "business.followus.title1": '東方甄選官方',
  "business.followus.title2": '東方甄選會員',
  "business.followus.title3": '東方甄選',
  "business.followus.title4": '東方甄選之圖書',
  "business.followus.title5": '東方甄選自營產品',
  "business.followus.title6": '東方甄選美麗生活',
  "business.followus.title7": '東方甄選看世界',
  "business.followus.title8": '東方甄選將進酒',
}
const employee = {
  "employee.talent.title": "人才發展",
  "employee.talent.desc": `東方甄選人才培養體系，以國際先進人才培養模型——TAT為項目設計理念，集“三力七要素”和哈佛“領導力筦道”為理論設計根本，以建立健全人才培養體系、領導力培養體系和綜合評價體系為抓手，為東方甄選人才發展提供堅實理論保障和系統性、專業性的培養支持，從而構建了以東方甄選文化相結合的人才發展體系。`,
  "employee.talent.table.title": "東方甄選人才發展體系",
  "employee.talent.table.col1.1": "HPD",
  "employee.talent.table.col1.2": "Leadership",
  "employee.talent.table.col1.3": "General Force",
  "employee.talent.table.col1.4": "Business",
  "employee.talent.table.col1.5": "New Employee",
  "employee.talent.table.col1.6": "Staff Level",
  "employee.talent.table.col2.1": "需求評估、目標設定、戰略共識、能力建模、人才盤點、高潜系課程",
  "employee.talent.table.col2.2": "團隊管理、橫向管理、對內管理、對外影響、談判技巧、戰略管理",
  "employee.talent.table.col2.3": "有效溝通、執行力、時間管理、目標管理、職業生涯規劃、精力管理",
  "employee.talent.table.col2.4": "銷售客服端培訓項目、管培生培訓項目、產研培訓項目、學習交流機制（內外部）",
  "employee.talent.table.col2.5": "企業文化與價值觀、新員工培養項目、員工福利",
  "employee.talent.table.col2.6": "大講堂、讀書俱樂部、外訓、Koo Show",
  "employee.talent.table.col3.1": "Professional Pro+Sales Pro+PM Pro+Technical Pro+Teaching+Management Pro",
  "employee.talent.table.col4.1": "Pro Operation+Koo吧+微信社群+H5+學習與發展中心+郵件+公眾號+海報",
  "employee.talent.table.col4.2": "Koo吧",
  "employee.talent.table.col4.3": "微信社群",
  "employee.talent.table.col4.4": "H5",
  "employee.talent.table.col4.5": "學習與發展中心",
  "employee.talent.table.col4.6": "郵件",
  "employee.talent.table.col4.7": "公眾號",
  "employee.talent.table.col4.8": "海報",
  "employee.leadership.table.title": "東方甄選領導力培養體系",
  "employee.leadership.table.col1.1": "Talent Review",
  "employee.leadership.table.col1.1.subtile": "高潛",
  "employee.leadership.table.col1.2": "Leadership",
  "employee.leadership.table.col1.2.subtile": "航係",
  "employee.environment.title": "辦公環境",
  "employee.activities.title": "員工活動",
  "employee.activities.party.title": "公司年會",
  "employee.activities.party.desc": "每年年底舉辦的年會，是重要的活動之一。",
  "employee.activities.team.title": "團建活動",
  "employee.activities.team.desc": "各部門積極開展團建活動，是增强部門凝聚力和協同力的重要方式。",
  "employee.activities.sports.title": "專場活動",
  "employee.activities.sports.desc": "公司不定期安排專場直播活動，員工可以從中體驗當地民俗特色，發揮個人特長。",
  "employee.activities.welfare.title": "回饋社會",
  "employee.activities.welfare.desc": "通過多種形式的“送溫暖、愛心傳遞”活動，公司用實際行動回饋社會、關愛社會，贏得社會的尊重和信任。",
  "employee.join.title": "加入我們",
  "employee.join.social.title": "社會招聘",
  "employee.join.social.desc": "加入我們，共同定義“XIN世界”",
  "employee.join.social.link": "去社招首頁",
  "employee.join.campus.title": "校園招聘",
  "employee.join.campus.desc": "尋找“XIN上人”",
  "employee.join.campus.link": "去校招首頁",
}
const investor = {
  "investor.company.desc": `我們的定位是做一個為客戶甄選好物的直播購物平台；以東方甄選品牌的自營農產品為核心的產品和科技公司；為客戶帶來愉快精神體驗的文化傳播公司。`,
  "investor.stock.title": "股價信息",
  "investor.corporate.title": "公司管治",
  "investor.chairman.title": "董事會",
  "investor.audit.title": "審核委員會",
  "investor.remuneration.title": "薪酬委員會",
  "investor.nomination.title": "提名委員會",
  "investor.governance.title": "治理文件",
  "investor.financial.title": "財務報告",
  "investor.financial.subtitle": "(財務報告從2019年8月16日之日起)",
  "investor.announcements.title": "公告及通函",
  "investor.announcements.subtitle": "(公告從2019年2月1日之日起)",
  "investor.pressrelease.title": "新聞發佈",
  "investor.analyst.title": "分析師列表",
  "investor.email.title": "電郵訂閱",
  "investor.analyst.desc": "來自不同機构的54位專業分析師",
  "investor.analyst.link": "查看列表",
  "investor.analyst.disclaimer": "免責聲明：",
  "investor.analyst.disclaimer.content": "上述分析師提供東方甄選控股有限公司（“東方甄選”）的股票分析。任何關於對東方甄選的分析、評估、預測和推薦僅代表他們的個人意見，不代表東方甄選和管理層的意見。 東方甄選不對上述分析師做出的分析、評估、預測和推薦提供任何指導、暗示或參考意見。",
  "investor.audit.dongruibao.position": "委員會主席",
  "investor.audit.linzheying.position": "委員會成員",
  "investor.audit.kuangweixin.position": "委員會成員",
  "investor.remuneration.linzheying.position": "委員會主席",
  "investor.remuneration.sunchang.position": "委員會成員",
  "investor.remuneration.dongruibao.position": "委員會成員",
  "investor.nomination.yuminghong.position": "委員會主席",
  "investor.nomination.linzheying.position": "委員會成員",
  "investor.nomination.dongruibao.position": "委員會成員",
}
const contact = {
  "contact.investor.title": "投資者/分析師",
  "contact.investor.name": "宋㛃",
  "contact.investor.position": "投資者關係總監",
  "contact.investor.company": "東方甄選控股有限公司",
  "contact.investor.address": "地址：北京市海澱區海澱東三街2號新東方南樓18層",
  "contact.investor.tel": "電話：+8610 6260 9213",
  "contact.investor.fax": "傳真：+8610 6260 9299",
  "contact.investor.email": "電郵：songjie@eastbuy.com",
  "contact.media.title": "新聞媒體",
  "contact.media.name": "陸淼",
  "contact.media.position": "副總裁",
  "contact.media.company": "博達浩華國際財經傳訊集團",
  "contact.media.address": "地址： 香港中環雲咸街60號中央廣場23樓2301室",
  "contact.media.tel": "電話： (852) 3150 6788",
  "contact.media.fax": "傳真： (852) 3150 6728",
  "contact.media.email": "電郵： eastbuy.hk@pordahavas.com"
}
const brief = {
  "brief.title": "關於我們",
  "brief.paragragh1": '我們的定位是做一個為客戶甄選好物的直播購物平台；以東方甄選品牌的自營農產品為核心的產品和科技公司；為客戶帶來愉快精神體驗的文化傳播公司。通過提供優質產品及服務，弘揚中華傳統文化及積極價值觀，我們希望為每一名客戶提供美好、健康、品質生活。',
  "brief.paragragh2": "東方甄選控股有限公司（前身為新東方在綫科技控股有限公司）由新東方于2005年成立，新東方為中國民辦教育行業的先驅，擁有近三十年的經驗。秉承改善公眾福祉，為社會創造價值的使命，自2021年以來，我們拓展自營產品及直播電商業務並成立了東方甄選，其已成為銷售高品質、高性價比的農業及其他產品的知名平台。目前，我們的業務涵蓋自營產品及直播電商、大學教育、海外備考和機構合作。",
  "brief.paragragh3": "目前，遵循“以客戶為中心”的戰略，東方甄選已在抖音開設六個直播帳號，形成直播矩陣，產品類別及SKU亦逐步擴大，目前包括農產品、食品、圖書及生活用品，我們的品牌在行業中的地位日益突出，獲得了數百萬回頭客和忠實的粉絲及會員。我們通過嚴格的供應鏈管理及與不同第三方的多元化合作，幫助人們選擇優質農業及其他產品，同時通過提供東方甄選品牌下的多種自營產品為客戶創造價值，這些產品健康、高品質且高性價比，包括生鮮食品、果汁、咖啡、茶、床上用品等。此外，東方甄選注重開發文化內容，並已開始加大與地方政府的合作，以推廣特色農產品、為文化旅遊貢獻力量，為社會創造價值。",
}

const advertiseMessage={
  // 'advertiseMessage.title':'股東公司通訊的安排',
  // 'advertiseMessage.href':'https://s1.eastbuy.com/ir/HKGSR01A-%23689643-v3-East_Buy_-_Paperless_Regime_-_Website_notice_(CN).pdf',
  // 'advertiseMessage.desc':'網站通告',
  // 'advertiseMessage.date':'2023-12-29'
  'advertiseMessage.title':'網站通告',
  'advertiseMessage.desc':'股東公司通訊的安排',
  'advertiseMessage.content':[
    {
      desc:'本公司的股東通訊方式將於 2024 年轉變為電子通訊方式',
      info:[
        '本公司現時向股東寄發公司通訊的印刷本。作為本公司提高環保意識舉措的一環，本公司將於2024 年第一季度轉為電子通訊方式。根據該最新通訊方式，我們將默認不向股東派發公司通訊的印刷本。',
        '於採納該最新電子通訊方式前，本公司將向各股東存置於香港股份過戶登記處的登記地址寄發初始通知函。該函件將詳述最新電子通訊方式，並載有股東向本公司知會╱更新其指定功能電郵地址及╱或提出在一定時限内股東收取若干公司通讯的印刷本的具体要求的途徑。'
      ]
    },
    {
      desc: '查閱本公司的公司通訊的方式',
      info:[
        '全體股東均可於本公司網站(ir.eastbuy.com)及香港交易及結算所有限公司網站(<a style="color:#EB5B03; text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>) 查閱及下載公司通訊的電子版本，包括初始通知函的電子版本。該等網站將提供中英文（如有）兩種語言版本的公司通訊，英文版本為文件的官方版本。'
      ]
    },
    {
      desc:'公司通訊簡介',
      info:[
        '「公司通訊」指由本公司發出或擬發出以供股東提供具體資料或採取具體行動的文件，包括但不限於中期報告及年度報告、股東大會通告、代表委任表格、通函及上市文件。'
      ]
    }
  ],
  'advertiseMessage20240118.desc':'致股東公司通訊之安排',
  'advertiseMessage20240118.content':[
    {
      desc:'',
      info:[
        '<div>作為本公司對環境保護更加負責的一份努力，我們強烈鼓勵所有股東選擇以電子方式收取本公司日後的公司通訊。 </div>'
      ]
    },
    {
      desc:'何處可獲取本公司公司通訊？',
      info:['<div>根據本公司現行慣例，我們將不會預設向股東派發公司通訊的印刷本。</div>',
      '<div>所有股東均可於本公司網站(ir.eastbuy.com)及香港交易及結算所有限公司網站(<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>)閱覽及下載公司通訊的電子版本。公司通訊的英文版本及中文版本（如有）均將載於該等網站，而英文版本將為相關文件的正式版本。</div>'
      ]
    },
    {
      desc:'能否索取公司通訊的印刷本？',
      info:[`<div>儘管本公司不鼓勵以實物派發公司通訊，但本公司理解若干股東可能因特定原因而需要印刷本。本公
      司將接受個別股東提出的特定書面要求，免費提供公司通訊印刷本。</div>`,
      `
      <table style='border-collapse: collapse;border: 1px solid #aaa;width:100%;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 1px 1px 1px 1px;vertical-align: text-top;'>欲索取公司通訊印刷本之股東</td>
          <td style="padding: 1px 1px 1px 1pxx;vertical-align: text-top;">閣下的要求一經接獲及處理，本公司將於要求期限內刊登公司通訊，並將郵寄至閣下存置於香港股份過戶登記處的登記地址（或獲提供的公司通訊指定郵寄地址）。</td>
          <td  style='border: 1px solid #3F3F3F;'>
            <div style='color:#000000;'>就登記持有人而言：</div>
            <div>閣下可填寫<span style="text-decoration: underline;">登記持有人回條的選項3（請參閲下文）</span>提出此要求。</div>
            <div>每項請求的有效期僅為自收到
            閣下指示之日起計<strong style="text-decoration: underline;">一年</strong>。</div>
            <div style='color:#000000;'>就非登記持有人而言：</div>
            <div>閣下可填寫<span style="text-decoration: underline;">非登記持有人回條（請參閲下文）</span>提出此要求。</div>
            <div>我們要求股東在提出此要求時 應注意為閣下編製及交付公司通訊印刷本對環境的影響。</div>
          </td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'何處可獲取可供採取行動的公司通訊？',
      info:[`<div>可供採取行動的公司通訊亦將在本公司網站(ir.eastbuy.com)及香港交易及結算所有限公司網站
      (<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>)上獲取。</div>`,
      `<div>此外，本公司亦將向閣下單獨發送可供採取行動的公司通訊，本公司建議閣下透過掃描回條上列
      印的閣下專屬二維碼來提供閣下的電子郵件地址，或者，閣下亦可簽署回條並交回本公司的香
      港股份過戶登記處香港中央證券登記有限公司，地址為香港灣仔皇后大道東183號合和中心17M
      樓。</div>`,
      `<div>如本公司沒有收到閣下的有效電子郵件地址，直至香港股份過戶登記處收到閣下有效的電子郵件
      地址前，本公司未来將以印刷本形式發送可供採取行動的公司通訊。 </div>`
      ]
    },
    {
      desc:'如何提交回條？',
      info:['<div>股東應將填妥及簽署的「登記持有人回條」或「非登記持有人回條」（如閣下透過中介持有本公司股份）送交香港股份過戶登記處：</div>',
      `<table style='border-collapse: collapse;border: 1px solid #aaa;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>香港股份過戶登記處</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">香港中央證券登記有限公司</td>
        </tr>
        <tr>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>登記郵箱地址：</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">香港灣仔皇后大道東183號合和中心17M樓</td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'回條連接',
      info:[`<ul> 
            <li><a target="_blank" href='https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(RH)%20(combined)_ESS_%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Registered%20Holders.pdf'>登記持有人回條</a></li>
            <li><a target="_blank" href="https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(NRH)%20(combined)_ESS_%E9%9D%9E%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Non%20Registered%20Holders.pdf">非登記持有人回條</a></li>
      </div>`
      ]
    },
    {
      desc:'關鍵術語',
      info:[`<ul> 
        <li>「公司通訊」指本公司為股東提供具體資料或採取行動而發出或將要發出的文件，包括但不限
        於中期及年度報告、股東大會通告、代表委任表格、通函及上市文件。
        </li>
        <li>「可供採取行動的公司通訊」是指需要股東採取具體行動的公司通訊，包括但不限於股東選舉
        表格、申請表格及接納表格。
        </li>
      </ul>`
      ]
    }
  ]
}

const hk_CN = { ...global, ...header, ...home, ...business, ...employee, ...investor, ...contact, ...brief,...advertiseMessage }
export default hk_CN;