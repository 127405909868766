const global = {
  "global.company.name": "East Buy Holding Limited",
  "global.governance.documents1.title": "Listing documents – Offer for subscription / prospectus",
  "global.governance.documents2.title": "Procedures for shareholders to propose a director",
  "global.governance.documents3.title": "VIE contract 1 - Exclusive Management Consultancy and Business Cooperation Agreement",
  "global.governance.documents4.title": "VIE contract 2 - Exclusive Call Option Agreement",
  "global.governance.documents5.title": "VIE contract 3 – Equity Pledge Agreement",
  "global.governance.documents6.title": "2019 ESG Report",
  "global.governance.documents7.title": "2020 ESG Report",
  "global.governance.documents8.title": "2021 ESG Report",
  "global.governance.documents9.title": "2022 ESG Report",
  "global.governance.documents10.title": "2023 ESG Report",
  "global.press20240823.title": "East Buy Announces FY2024 Annual Results",
  "global.press20240619.title": "East Buy New Product Launch Event on June 19",
  "global.press20240124.title": "East Buy Announces FY2024 Interim Results",
  "global.press20230825.title": "East Buy Announces FY2023 Annual Results",
  "global.press20230117.title": "Koolearn Announces FY2023 Interim Results",
  "global.press20220826.title": "Koolearn Announces FY2022 Annual Results",
  "global.press20220121.title": "Koolearn Announces FY2022 Interim Results",
  "global.press1.title": "Koolearn Announces FY2021 Annual Results",
  "global.press2.title": "Koolearn Announces FY2021 Interim Results",
  "global.press3.title": "Koolearn Announces FY2020 Annual Results",
  "global.press4.title": "Koolearn Announces FY2020 Interim Results",
  "global.press5.title": "Koolearn Announces FY2019 Annual Results and Acquisition of 49% of Dongfang Youbo",
  "global.press6.title": "Koolearn Successfully Listed on the Main Board of the Hong Kong Stock Exchange",
  "global.press7.title": "Koolearn Announces Allotment Results",
  "global.press8.title": "Koolearn Announces Proposed Listing on the Main Board of the Hong Kong Stock Exchange",
  "global.more": 'Learn more',
  "global.all": 'View all',
  "global.yuminghong.name": 'Mr. YU Minhong',
  "global.yuminghong.position": 'Chairman of East Buy,Executive Director,CEO',
  "global.yuminghong.brief": `Mr. Yu, aged 60, is an executive Director, chairman of the Nomination Committee, Chairman of our Board and our Company's chief executive officer. Mr. Yu is also the chairman and a director of Beijing Xuncheng, since May 2015, and certain companies under the New Oriental Group, including Leci Internet. Mr. Yu received his bachelor’s degree in English from Peking University, China in July 1985. Mr. Yu is the founder and currently the executive chairman of the board of directors of New Oriental, since 2001, and was a director of Sunlands Technology Group, a company whose American depository shares are listed on the New York Stock Exchange (NYSE: STG), from August 2017 (and an independent director from March 2018) to June 2019. Since 2001, Mr. Yu has been the chairman and director of New Oriental, our Controlling Shareholder and a company whose American depository shares are listed on the New York Stock Exchange (NYSE: EDU) and shares are listed on the Stock Exchange (stock code: 9901). `,
  "global.sundongxu.name": 'Mr. SUN Dongxu',
  "global.sundongxu.position": "Non-executive Director",
  "global.sundongxu.brief": `Mr. Sun, aged 37, is a non-executive Director. Within our Group, Mr. Sun is a director of: Beijing Xuncheng since September 2019, Dexin Dongfang since August 2019 (also Dexin Dongfang’s CEO), Xuncheng HK since November 2019, Kuxue Huisi (also Kuxue Huisi’s CEO) since November 2019, Xi’an Ruiying from April 2020 to April 2021, and New Oriental Wuyou Online (HK) Education & Technology Co., Ltd. since August 2020, Dong Fang You Xuan (Beijing) Technology Co., Ltd. from October 2021, Beijing Xin Yuanfang Human Resource Services Co., Ltd. from October 2021, East Buy (Beijing) Technology Co., Ltd. from December 2021. Prior to this, Mr. Sun was the principal of Xi’an New Oriental School from April 2016 to July 2018, and the regional president of Northwestern China of New Oriental China from April 2016 to May 2018. Mr. Sun began his career with the New Oriental Group (which, at the time, included our Group) as a teacher in the foreign exams department of Tianjin New Oriental School from June 2007 to June 2008. Between June 2008 and April 2016, Mr. Sun worked at Hefei New Oriental School, beginning as the assistant supervisor of the foreign examination department, from June 2008 to June 2009, and moving through various positions within the school to ultimately acting as the principal, from October 2013 to April 2016. Aside from our Group, Mr. Sun has been the vice-president of New Oriental China since April 2019, and was previously the assistant vice-president of New Oriental China from April 2016 to April 2019. Mr. Sun received his bachelor’s degree in engineering, majoring in computer science and technology, from Nankai University (南開大學), China, in June 2007.  `,
  "global.yinqiang.name": 'Mr. YIN Qiang',
  "global.yinqiang.position": "Executive Director,CFO",
  "global.yinqiang.brief": "Mr. Yin, aged 49, is an executive Director and our Company’s chief financial officer. He is also a director and chief financial officer of Beijing Xuncheng, since January 2016, director of Zhuhai Chongsheng since July 2019 to November 2020 and director (since April 2020) and general manager (since April 2021) of Xi’an Ruiying, and director and general manager of Hainan Haiyue Dongfang Network Technology Co., Ltd. (海南海悅東方網絡科技有限公司) since October 2020. Mr. Yin received his bachelor’s degree in economics from Capital University of Economics and Business (首都經濟貿易大學), China, in July 1996 and his master’s degree in business management from Peking University, China, in July 2008. Mr. Yin is also a PRC accredited accountant (since October 2001). Aside from our Group, Mr. Yin has been the vice-president of New Oriental China since April 2019. Mr. Yin was the financial controller and assistant vice-president of New Oriental China from June 2005 to May 2016, and senior accountant at PricewaterhouseCoopers from 1996 to 2001.",
  "global.sunchang.name": "Ms. SUN Chang",
  "global.sunchang.position": "Non-executive Director",
  "global.sunchang.brief": "Ms. Sun, aged 55, is a non-executive Director and a member of the Remuneration Committee. She was our Company’s co-chief executive officer until 19 January 2020. Ms. Sun is a director of Beijing Xuncheng, since May 2015 and Dexin Dongfang since March 2018. Ms. Sun received her bachelor’s degree in pre-school education from Beijing Normal University (北京師範大學), China, in July 1990 and her master’s degree in business administration from Renmin University of China (中國人民大學), China, in July 1999. Aside from our Group, Ms. Sun was the assistant vice-president and the vice-president of New Oriental China from 2012 to 2016 and 2016 to 2020, respectively. Ms. Sun was the general manager of the investment division at China Netcom Group Corporation Limited (now China United Network Communications Group Co., Ltd. (中國聯合網絡通訊集團有限公司), or China Unicom (中國聯通) from 2000 to 2004, and the marketing manager at Microsoft (China) Co., Ltd. (微軟(中國)有限公司) from 1997 to 2000.  ",
  "global.wuqiang.name": "Mr. WU Qiang",
  "global.wuqiang.position": "Non-executive Director",
  "global.wuqiang.brief": "Mr. Wu, aged 47, is a non-executive Director and member of the Audit Committee. Mr. Wu is also a director of Leci Internet, since November 2015. Mr. Wu received his master’s degree in engineering from the Institute of Automation at the Chinese Academy of Sciences, China, in July 1997. Aside from our Group, Mr. Wu was the vice-president of New Oriental China from 2016 to April 2019, and is currently its senior vice-president, since April 2019. He was the principal of Beijing New Oriental School from July 2014 to April 2018. Prior to this, Mr. Wu was the vice-principal of Beijing New Oriental School from 2013 to 2014, director of research and development at New Oriental China from 2005 to 2007, vice-principal of other New Oriental schools in Qingdao and Chengdu from 2008 to 2012, and a president of Beijing Mingri Dongfang Technology Co., Ltd. from 2000 to 2005.",
  "global.liangyuhua.name": "Ms. LEUNG Yu Hua Catherine",
  "global.liangyuhua.position": 'Non-executive Director',
  "global.liangyuhua.brief": "Ms. Leung, aged 37, is a non-executive Director. She has also been a director of Beijing Xuncheng, since January 2019. Ms. Leung received her bachelor of science in economics (concentration in ﬁnance) from the Wharton School of the University of Pennsylvania, the United States, in June 2004. Aside from our Group, Ms. Leung has been the vice-general manager of the strategy development department at Sixjoy Hong Kong Limited since December 2013, a subsidiary of Tencent Holdings Limited, and previously served as a senior research analyst at Arete Research Services L.L.P. from November 2012 to June 2013, and was an executive director in the investment research division at Goldman Sachs (Asia) L.L.C. from August 2010 to November 2012.",
  "global.linzheying.name": "Mr. LIN Zheying",
  "global.linzheying.position": "Independent Non-executive Director",
  "global.linzheying.brief": "Mr. Lin, aged 58, is an independent non-executive Director, chairman of the Remuneration Committee, and a member of the Nomination Committee and the Audit Committee. Mr. Lin received his bachelor’s degree majoring in planning statistics from the Shanxi University of Finance and Economics (formerly known as the Shanxi College of Finance and Economics), China, in July 1987, a master’s degree in business administration from the Guanghua School of Management, Peking University in China, in July 2006, and a doctoral degree in business administration from ESC Rennes School of Business, France, in June 2008. Aside from our Group, Mr. Lin served as a director of Shenzhen Fengchao Technology Limited, from November 2016 to December 2017; and has been serving as a vice-chairperson of S.F. Holdings (Group) Co., Ltd., from June 2014 to December 2019, and an executive director of Ancient Jade Capital Management Co., Ltd., since January 2011. Aside from our Company, Mr. Lin also holds, or held, directorships in the following listed companies: (i) executive director and vice-chairperson of S.F. Holding Co., Ltd., a company listed on the Shenzhen Stock Exchange (stock code: 002352), from March 2017 to December 2022; (ii) independent non-executive director of Shanghai Dongzheng Automotive Finance Co., Ltd., a company listed on the Hong Kong Stock Exchange (stock code: 2718), from August 2018 to June 2020 and (iii) director of GCH Technology CO., Ltd. a company listed on the Shanghai Stock Exchange (stock code: 688625), from December 2015 to September 2021.  ",
  "global.dongruibao.name": "Mr. TONG Sui Bau",
  "global.dongruibao.position": "Independent Non-executive Director",
  "global.dongruibao.brief": "Mr. Tong, aged 52, is an independent non-executive Director, chairman of the Audit Committee, and a member of the Remuneration Committee and Nomination Committee. Mr. Tong received his bachelor’s degree in accounting with an additional concentration in computer science from the University of Wisconsin, Madison, U.S., in May 1993. Mr. Tong was previously a member of the American Institute of Certified Professional Accountants (AICPA), from 1995 to 2009, and Chartered Financial Analyst, from 1999 to 2009. Mr. Tong has been a non-executive director of NetEase Inc., a company whose American depository shares are listed on the Nasdaq (Nasdaq: NTES) and whose shares are listed on the Stock Exchange (stock code: 9999), from 2009 to 2021. He was previously an executive director and co-chief operating officer of NetEase Inc., from 2003 to 2009,and from 2004 to 2009, respectively.  ",
  "global.kuangweixin.name": "Mr. KWONG Wai Sun Wilson",
  "global.kuangweixin.position": "Independent Non-executive Director",
  "global.kuangweixin.brief": "Mr. Kwong, aged 57, is an independent non-executive Director and a member of the Audit Committee. Mr. Kwong is an associate member of the Institute of Chartered Accountants in England and Wales. Mr. Kwong received his bachelor of arts degree from the University of Cambridge, United Kingdom, in June 1987. Mr. Kwong is an executive director of China Metal Resources Utilisation Limited, since August 2013, a company listed on the Stock Exchange (stock code: 1636). Mr. Kwong also acts as an independent non-executive director of Shunfeng International Clean Energy Limited, since July 2014, a company listed on the Stock Exchange (stock code: 1165), C.banner International Holdings Limited, since August 2011, a company listed on the Stock Exchange (stock code: 1028), China Outfitters Holdings Limited, since June 2011, a company listed on the Stock Exchange (stock code: 1146), and China New Higher Education Group Limited, since March 2017, a company listed on the Stock Exchange (stock code: 2001). Mr. Kwong was the president of Gushan Environmental Energy Limited, a company listed on the New York Stock Exchange (NYSE: GU) (from December 2007 to October 2012). Prior to this, he was a managing director in the investment banking division and the head of Hong Kong and China equity capital markets at CLSA Equity Capital Markets Limited from March 2004 to July 2006, and a director and the general manager of the corporate finance division of Cazenove Asia Limited, from 2002 to 2003, and from 1997 to 2003, respectively.  ",
  "global.video.publicity": "https://daxueui-cos.koocdn.com/images/fe_upload/2023/2/2023-2-7-1675763146129.mp4"
}

const header = {
  "header.menu.name.home": 'Company Profile',
  "header.menu.name.business": 'Business',
  "header.menu.name.employee": 'Employees',
  "header.menu.name.investor": 'Investor Relations',
  "header.submenu.name.contacts": 'Contact IR',
  "header.submenu.name.brief": 'About Us',
  "header.submenu.name.vision": 'Vision',
  "header.submenu.name.milestone": 'Milestones',
  "header.submenu.name.team": 'Board of Directors',
  "header.submenu.name.culture": 'Culture',
  "header.submenu.name.responsibility": 'Social Responsibility',
  "header.submenu.name.news": 'News',
  "header.submenu.name.events": 'News & Events',
  "header.submenu.name.k-12Education": 'K-12 Education',
  "header.submenu.name.privateLabelProducts": "Private label products and livestreaming e-commerce",
  "header.submenu.name.collegeEducation": 'College Education',
  "header.submenu.name.preSchoolEducation": 'Pre-school Education',
  "header.submenu.name.institutionalCustomers": 'Institutional Customers',
  "header.submenu.name.associatedCompanies": 'Associated Companies',
  "header.submenu.name.relatedBrands": 'Related Brands',
  "header.submenu.name.IRContacts": 'Contact Us',
  "header.submenu.name.cooperation": 'Cooperation',
  "header.submenu.name.talentDevelopment": 'Talent Development',
  "header.submenu.name.workEnvironment": 'Work Environment',
  "header.submenu.name.employeeActivities": 'Employee Activities',
  "header.submenu.name.joinUs": 'Join Us',
  "header.submenu.name.StockInformation": 'Stock Information',
  "header.submenu.name.CorporateGovernance": 'Corporate Governance',
  "header.submenu.name.GovernanceDocuments": 'Governance Documents',
  "header.submenu.name.FinancialReport": 'Financial Reports',
  "header.submenu.name.Announcements": 'Announcements',
  "header.submenu.name.PressRelease": 'Press Releases',
  "header.submenu.name.AnalystCoverage": 'Analyst Coverage',
  "header.submenu.name.E-mailAlert": 'E-mail Alert',
}
const home = {
  "home.banner.title": "Koolearn,Excellent Teacher of All Times",
  "home.brief.title": "About Us",
  "home.brief.desc": "We are positioned as a livestreaming platform that focuses on selecting premium products for customers; an outstanding product and technology company that continuously provides agricultural products under our private label, “East Buy”(東方甄選), as its core product; as well as a cultural communication company that provides customers with pleasant experience. Through the provision of high-quality products and services, the dissemination of traditional Chinese culture and positive values, we hope to provide a better, healthy and quality life to every customer...",
  "home.vision.title": "Vision",
  "home.vision.desc.paragraph.one": "A livestreaming platform that focuses on selecting premium products for customers",
  "home.vision.desc.paragraph.two": "An outstanding product and technology company that continuously provides agricultural products under our private label, “East Buy”, as its core product",
  "home.vision.desc.paragraph.three": "A cultural communication company that provides customers with pleasant experience",
  "home.milestone.title": "Milestones",
  "home.milestone.seg.2023": "English name of our Company has been changed from “Koolearn Technology Holding Limited” to “East Buy Holding Limited” and the dual foreign name of our Company has been changed from “新東方在綫科技控股有限公司” to “東方甄選控股有限公司”.",
  "home.milestone.seg.2021": "Established East Buy with private label products and livestreaming e-commerce businesses.",
  "home.milestone.seg.2020": "New Oriental and Tigerstep subscribed $230 million shares of our company.",
  "home.milestone.seg.2019": "On March 28, we succesfully landed on the HKEX, stock code : 1797.HK.",
  "home.milestone.seg.2018": "Our company was incorporated as an exempted company with limited liability in the Cayman Islands.Dragon Cloud and ChinaCity Capital, our series B Pre-IPO investors, invested in our group.Mr. Yu, New Oriental’s founder, our Director and chairman of the Board, through Tigerstep, invested in our group by subscribing our series C preferred shares.",
  "home.milestone.seg.2017": "We launched our location-based live interactive tutorial service for K-12 students DFUB.",
  "home.milestone.seg.2016": "Linzhi Tencent invested in our group.",
  "home.milestone.seg.2014": "We launched our self-adaptive learning system Zhixin.",
  "home.milestone.seg.2012": "We launched our first English-learning mobile app Donut.",
  "home.milestone.seg.2007": "We gained our first institutional customer.",
  "home.milestone.seg.2006": 'New Oriental (including Beijing Xuncheng) was listed on the NYSE under the symbol "EDU". ',
  "home.milestone.seg.2005": "We launched our first online education platform Koolearn.",
  "home.team.title": "Board of Directors",
  "home.culture.title": "Culture",
  "home.culture.spirit.one.paragraph": "Uphold the strategy of being “customer-focused” and the spirit of “striver-oriented”",
  "home.culture.spirit.two.paragraph": "Become an outstanding product and technology company adhering to the spirit of New Oriental",
  "home.culture.spirit.three.paragraph": "Provide customers with better experience and cultural knowledge",
  "home.responsibility.title": "Social Responsibility",
  "home.responsibility.desc": "Maybe we cannot do something extremely great, but as part of Chinese people, we will also feel a sense of achievement and happiness if we can make something better because of our efforts, and make some people full of hope for their life because of our help.",
  "home.responsibility.footer": "— — Mr. YU Minhong",
  "home.responsibility.5.title": "Koolearn actively promoted educational equity",
  "home.responsibility.5.abstract.0": "“Candlelight Action”,we trained 50000 rural teachers in 11 years",
  "home.responsibility.5.abstract.1": "“Star of Self-improvement”,we made donations of hundreds of millions yuan for supporting tens of thousands of college students",
  "home.responsibility.5.abstract.2": "“Hope Primary Schools ”,we invested RMB 20.2 million to build seven hope primary schools",
  "home.responsibility.5.abstract.3": "“Love for Distant Mountains”,15 education enterprises have been united, which covered over 300 schools",
  "home.responsibility.0.title": "Koolearn donated RMB 1.08 million public welfare classes to Danzhai County",
  "home.responsibility.0.abstract.0": `On 22 April 2021,New Oriental Group,Koolearn and BOBO English,etc donated RMB 2 million of learning resources "Big Gift Pack" to Jinzhong No.1 Primary School in Danzhai County, Guizhou Province`,
  "home.responsibility.0.abstract.1": `Koolearn donated RMB 1.08 million of online course public welfare packages, 300 sets in total`,
  "home.responsibility.1.title": `Koolearn launched "Burning Dream Action" 2020 Public Welfare Student Grant Scheme`,
  "home.responsibility.1.abstract.0": `On 31 October 2020,Koolearn officially lauched the "Burning Dream Action – 2020 Public Welfare Student Grant Scheme", which aimed to distribute charity grants for students from impoverish families, disabled families and critically ill families`,
  "home.responsibility.1.abstract.1": `Charity grants were distributed in the form of vouchers, which were divided into first class, second class, third class, and they worth RMB 800，RMB 600, RMB 400 respectively`,
  "home.responsibility.2.title": "East Buy actively promotes intangible cultural heritage",
  "home.responsibility.2.abstract.0": 'Since June 2021, East Buy has held multiple onsite livestreaming events to promote intangible cultural heritage.',
  "home.responsibility.2.abstract.1": `Over 50 kinds of outstanding local intangible cultural heritages, including Yunnan Peacock Dance, Hainan Puppetry, and Shandong Drumming, have been promoted during these events.`,
  "home.responsibility.3.title": "Promoting local agricultural product sales",
  "home.responsibility.3.abstract.0": "We promote the sales of local agricultural products through deep traceability and cooperation with local agricultural companies, helping these enterprises to open up new markets.",
  "home.responsibility.3.abstract.1": `We leverage our company's advantages by integrating resources, innovating and creating, and supporting the development of agriculture. `,
  "home.responsibility.3.abstract.2": `By selecting high-quality agricultural products, we provide consumers with healthy, delicious, high-quality, and cost-effective products.`,
  "home.responsibility.4.title": "“knowledge with goods” (知識帶貨) advocating the concept of national reading and healthy life",
  "home.responsibility.4.abstract.0": "East Buy focuses on the livestreaming feature of “knowledge with goods”(知識帶貨)，advocating the concept of national reading and healthy life, inviting cultural celebrities from all walks of life.",
  "home.responsibility.4.abstract.1": `The total number of books sales reached 12.4 million copies within six months.`,
  "home.responsibility.4.abstract.2": `the cumulative sales of Mao Dun Literature Award-winning work “The Right Bank of the Erguna River” (額爾古納河的右岸) exceeded 500,000 copies.`,
  "home.news.title": "News",
}
const business = {
  "business.k12.title": "K-12 Education",
  "business.k12.desc": "Our K-12 education services comprise after-school tutoring courses which cover the majority of standard school subjects from primary to high school in China, and we offer preparation courses designed for standardised college and high school entrance exams. Leveraging our expertise in K-12, in 2017, we launched our innovative DFUB courses, a location-based live interactive tutoring service targeting students in lower-tier cities where offline infrastructure may not be as convenient and there is growing demand for an effective online platform accessible anytime, anywhere. In FY2020, we had 1,856 thousand student enrolments in the segment, representing 65.1% of our total student enrolments.",
  "business.more": 'Go to the official website',
  "business.selfOperatedGoods.title": "Private label products and livestreaming e-commerce",
  "business.selfOperatedGoods.desc01": "Since 2021, we established, East Buy, an e-commerce platform for selling high-quality products (mainly focusing on agricultural products, daily necessities and books) through the use of livestreaming marketing, leveraging New Oriental Group’s brand recognition, long operating history, the technology of our live-broadcast classrooms and existing pool of talent. We began piloting livestreaming e-commerce events on different social media platforms such as Douyin and Tencent mall, etc.",
  "business.selfOperatedGoods.desc02": "East Buy has become a well-known product and technology company that provides agricultural products under our private label, “East Buy”, as its core product, and the platform formed part of a tight supply chain management and after sale services system which strictly abides by a series of relevant laws and regulations. We set extremely stringent access standards to screen high-quality suppliers, so that each product has better craftsmanship and simpler ingredient lists. Leveraging the deep understanding of customers’ needs and feedback from customers, we continue to expand our product selection and SKUs through the fast development of private labels. Through innovative content, diversified livestreaming activities and with our extensive knowledge and cultural sharing, we provide a unique and pleasant shopping experience to our customers.",
  "business.selfOperatedGoods.desc03": "In terms of private label products, we will continue to introduce and apply scientific and advanced technologies to strictly control the whole process from product development to sales and after-sales services to improve user experience. In the future, we will capitalise on the development of Internet technology to further enhance the technological superiority of products. As at the end of November 2022, we have 65 private label products, including black pork sausage in different flavors, Wuchang rice, blueberry puree, and other popular products. We have received a lot of valuable customer feedback, and product types and proportion of blockbuster products will continue to increase.",
  "business.selfOperatedGoods.desc04": "In addition, we will conduct unannounced inspections and periodic random checks and visits to factories. We have adopted a sampling frequency much higher than the industry average. East Buy has also upgraded its quality inspection department to a food safety and quality inspection research centre. We cooperate with domestic first-class quality inspection institutions to manage the quality inspection process of each product. Pesticide residues, drug residues, antibiotics, microorganisms and other items are strictly monitored in accordance with our product requirements. All necessary inspection will be performed.",
  "business.selfOperatedGoods.desc05": "We constantly optimize the service experience. During the reporting period, East Buy has entered into deep collaborations with SF Express and JD Group to continuously improve the delivery quality and the scope of cold-chain delivery. In order to give back to our consumers, we set up a membership system. By the end of December 2022, we have accumulated 8 million members. The increased popularity of East Buy brand has encouraged more manufacturers, retailers, and national or international brands to embrace our business mode and initiate cooperation. The continuously optimised service experience for private label products has boosted customer repurchase rate. Because of the advanced production process, high standard selection principle, high-density quality inspection as well as logistics and membership experience, the credibility scores of East Buy- private label products store on Douyin reached 4.99 points, beating all our peers.",
  "business.college.title": "College Education",
  "business.college.desc": "We are a leading online provider of extracurricular education services in China. We have continued to provide courses for college test preparation and overseas test preparation. Our courses are primarily used by college students and working professionals preparing for standardised tests or seeking to improve their English language proficiency. We are well-recognised known for high-quality courses and content, with a core expertise in online after-school tutoring and test preparation. We strive to become a lifelong learning partner, empowering students to achieve their full potential. We provide our courses and products through different online platforms and mobile applications in multiple formats.",
  "business.preSchool.title": "Pre-school Education",
  "business.preSchool.desc": "Our pre-school education segment offers child-friendly online educational content delivered through our Donut English-learning and child education apps. We launched our Donut app series in 2012 and in FY2020, we had 54 thousand student enrolments in the segment, representing 1.9% of our total student enrolments.",
  "business.customers.title": "Institutional Customers",
  "business.customers.desc": "We provide services to institutional customers, which mainly consist of colleges and universities, public libraries, telecom operators and online streaming video providers. We continue to deepen the cooperation with different schools and institution. In terms of vocational education, we have conducted research and communication with a number of universities and colleges on the “Craftsmen in the New Era Institution” (新時代工匠學院) project and the new energy and smart building project, and planned to implement in-depth research and planning with some of the vocational schools and undergraduate colleges on the construction of industrial research institutes and new talent training programmes. In the field of primary and secondary schools and urban libraries, we will further improve the “Oriental Tree” (東方樹) quality education platform, integrate high-quality content in science and technology, programming, aesthetic education, sports and humanities, and cooperate with schools and training institutions.",
  // "business.companies.title": "Associated Companies",
  "business.companies.desc": "We are open to business cooperation to expand our business fields.",
  "business.brands.title": "Related Brands",
  "business.download.title": "Download",
  "business.download.koolearn": "koolearn",
  "business.download.donut": "Donut",
  "business.download.lboc": "Lboc",
  "business.download.college": "koolearn",
  "business.contact.title": "Contact Us",
  "business.contact.company.name": "East Buy Holding Limited",
  "business.contact.company.adress": "Address : 18F, New Oriental South Building, No.2 Haidiandongsanjie, Haidian District, Beijing 100080 PRC",
  "business.contact.company.tel": "Tel : 4000686666",
  "business.contact.company.time": "Service Time : 8:00-24:00",
  "business.cooperation.title": "Cooperation",
  "business.cooperation.business.title": "Business Cooperation",
  "business.cooperation.business.contact": "Contact : Ms. Chen",
  "business.cooperation.business.email": "E-mail : xuanpin@xdfzx.com",
  "business.cooperation.market.title": "Market Promotion and  Public Relations",
  "business.cooperation.market.contact": "Contact : Mr. Huang",
  "business.cooperation.market.email": "E-mail : mkt@xdfzx.com",
  "business.cooperation.online.title": "Internet Cooperation/Online Agency",
  "business.cooperation.online.contact": "Contact : Mr. Hou",
  "business.cooperation.online.email": "E-mail : houjie01@xdfzx.com",
  "business.followus.title": 'East Buy-Follow us',
  "business.followus.gongzhonghao": 'Wechat',
  "business.followus.douyin": 'Douyin',
  "business.followus.kuaishou": 'Kuaishou',
  "business.followus.weibo": 'Weibo',
  "business.followus.xiaohongshu": 'RED',
  "business.followus.tianmao": 'Tmall',
  "business.followus.jingdong": 'JD',
  "business.followus.title1": 'Official Account',
  "business.followus.title2": 'Member Account',
  "business.followus.title3": 'Main Channel',
  "business.followus.title4": 'Book',
  "business.followus.title5": 'Private Label',
  "business.followus.title6": 'Beautiful Life',
  "business.followus.title7": 'See the World',
  "business.followus.title8": 'Good Wine',
}
const employee = {
  "employee.talent.title": "Talent Development",
  "employee.talent.desc": `Our talent development scheme is based on the advanced "Training, Acting, Teaching" theory, which is a combination of implementation, transformation and organization, as well as "Harvard Leadership Theory". The talent development scheme is in line with our core values, with the specific purpose of building an effective training program, a leadership cultivation path, and a comprehensive evaluation system.`,
  "employee.talent.table.title": "Our Talent Development Path",
  "employee.talent.table.col1.1": "HPD",
  "employee.talent.table.col1.2": "Leadership",
  "employee.talent.table.col1.3": "General Force",
  "employee.talent.table.col1.4": "Business",
  "employee.talent.table.col1.5": "New Employee",
  "employee.talent.table.col1.6": "Staff Level",
  "employee.talent.table.col2.1": "needs assessment, goal setting, strategic consensus, competency modeling, talent inventory, high potential department courses, effect evaluation",
  "employee.talent.table.col2.2": "team management, horizontal management, internal management, external impact, negotiation skills, strategic management",
  "employee.talent.table.col2.3": "effective communication, execution, time management, goal management, career planning, energy management",
  "employee.talent.table.col2.4": "sales customer service training project, management Peisheng training project, industrial research training project, learning communication mechanism (internal and external)",
  "employee.talent.table.col2.5": "corporate culture and values, new employee training program, employee welfare",
  "employee.talent.table.col2.6": "lecture hall, reading club, external training, Koo Show",
  "employee.talent.table.col3.1": "Professional Pro+Sales Pro+PM Pro+Technical Pro+Teaching+Management Pro",
  "employee.talent.table.col4.1": "Pro Operation+Koo bar+Wechat community+H5+learning and development center+email+official account+poster",
  "employee.leadership.table.title": "Our Leadership Development Path",
  "employee.leadership.table.col1.1": "Talent Review",
  "employee.leadership.table.col1.2": "Leadership",
  "employee.environment.title": "Work Environment",
  "employee.activities.title": "Employee Activities",
  "employee.activities.party.title": "Annual Party",
  "employee.activities.party.desc": "Annual party is one of the most important activities.",
  "employee.activities.team.title": "Team Building Activities",
  "employee.activities.team.desc": "All departments actively organize Team Building Act in various ways for better team spirit.",
  "employee.activities.sports.title": "Onsite Livestreaming Events",
  "employee.activities.sports.desc": "We arrange onsite livestreaming events from time to time, employee can experience local folk custom characteristic and show their talents.",
  "employee.activities.welfare.title": "Public Welfare",
  "employee.activities.welfare.desc": "We concern public welfare and have conducted various warm-sending activities.",
  "employee.join.title": "Join Us",
  "employee.join.social.title": "Social Recruitment",
  "employee.join.social.desc": `Join us to define the "Xin world"`,
  "employee.join.social.link": "Home page",
  "employee.join.campus.title": "Campus Recruitment",
  "employee.join.campus.desc": `Search for "XIN"`,
  "employee.join.campus.link": "Home page",
}
const investor = {
  "investor.company.desc": `We are positioned as a livestreaming platform that focuses on selecting premium products for customers; an outstanding product and technology company that continuously provides agricultural products under our private label, “East Buy”, as its core product; as well as a cultural communication company that provides customers with pleasant experience. `,
  "investor.stock.title": "Stock Information",
  "investor.corporate.title": "Corporate Governance",
  "investor.chairman.title": "Board of Directors",
  "investor.audit.title": "Audit Committee",
  "investor.remuneration.title": "Remuneration Committee",
  "investor.nomination.title": "Nomination Committee",
  "investor.governance.title": "Governance Documents",
  "investor.financial.title": "Financial Reports",
  "investor.financial.subtitle": "(Financial Reports are available from 16 August 2019)",
  "investor.announcements.title": "Announcements",
  "investor.announcements.subtitle": "(Announcements are available from 01 February 2019)",
  "investor.pressrelease.title": "Press Releases",
  "investor.analyst.title": "Analyst Coverage",
  "investor.email.title": "E-mail Alert",
  "investor.analyst.desc": "54 professional analysts from different institutions",
  "investor.analyst.link": "View list",
  "investor.analyst.disclaimer": "Disclaimer:",
  "investor.analyst.disclaimer.content": "East Buy Holding Limited(“East Buy”) is followed by the analyst(s) listed above. Please note that any opinions, estimates, forecasts, or recommendations regarding East Buy's performance made by these analysts are theirs alone and do not represent the opinions, estimates, or forecasts of East Buy or its management. East Buy does not, by its reference above or distribution, imply its endorsement of or concurrence with such opinions, estimates, forecasts, or recommendations.",
  "investor.audit.dongruibao.position": "Chairman of the Committee",
  "investor.audit.linzheying.position": "Committee Member",
  "investor.audit.kuangweixin.position": "Committee Member",
  "investor.remuneration.linzheying.position": "Chairman of the Committee",
  "investor.remuneration.sunchang.position": "Committee Member",
  "investor.remuneration.dongruibao.position": "Committee Member",
  "investor.nomination.yuminghong.position": "Chairman of the Committee",
  "investor.nomination.linzheying.position": "Committee Member",
  "investor.nomination.dongruibao.position": "Committee Member",

}
const contact = {
  "contact.investor.title": "For Investors/Analysts",
  "contact.investor.name": "Helen Song",
  "contact.investor.position": "Investor Relations Director",
  "contact.investor.company": "East Buy Holding Limited",
  "contact.investor.address": "Address : 18F, New Oriental South Building,No.2 Haidiandongsanjie, Haidian District, Beijing 100080 PRC",
  "contact.investor.tel": "Tel : +8610 6260 9213",
  "contact.investor.fax": "Fax : +8610 6260 9299",
  "contact.investor.email": "E-mail : songjie@eastbuy.com",
  "contact.media.title": "For Media",
  "contact.media.name": "Ivy Lu",
  "contact.media.position": "Vice President",
  "contact.media.company": "Porda Havas International Finance Communications Group",
  "contact.media.address": "Address : Unit 2301, 23F, The Centrium,60 Wyndham Street, Central, Hong Kong",
  "contact.media.tel": "Tel : (852) 3150 6788",
  "contact.media.fax": "Fax : (852) 3150 6728",
  "contact.media.email": "E-mail : eastbuy.hk@pordahavas.com"
}
const brief = {
  "brief.title": "About Us",
  "brief.paragragh1": 'We are positioned as a livestreaming platform that focuses on selecting premium products for customers; an outstanding product and technology company that continuously provides agricultural products under our private label, “East Buy”(東方甄選), as its core product; as well as a cultural communication company that provides customers with pleasant experience. Through the provision of high-quality products and services, the dissemination of traditional Chinese culture and positive values, we hope to provide a better, healthy and quality life to every customer.',
  "brief.paragragh2": "East Buy Holding Limited(formerly known as Koolearn Technology Holding Limited) was established in 2005 by New Oriental, a pioneer in China’s private education industry with nearly 30 years of experience. Adhering to the mission of improving public welfare and creating value to the society, since 2021, we have expanded our businesses in private label products and livestreaming e-commerce, and established East Buy, which has become a well-known platform for selling top-quality and cost effective agricultural and other products. Currently, our business segments include private label products and livestreaming e-commerce business, college education, overseas test-preparation and institutional cooperation.",
  "brief.paragragh3": "Upholding the strategy of being “customer-focused”, we have opened six livestreaming accounts on Douyin, forming a livestreaming matrix, gradually expanding the products categories and SKUs, including agricultural products, food, books and household goods. Our brand has become increasingly prominent in the industry, thereby gaining millions of return and loyal customers and followers. We help people select high quality agricultural and other products through our tight supply chain management and diversified cooperation with different third parties. We also create value for consumers by providing a number of private label products under the East Buy brand, which are designed to be healthy, top-quality and cost-effective, including fresh foods, juice, coffee, tea, bedding, etc. East Buy focuses on developing its cultural content, and has also begun to increase collaboration with local governments, so as to promote special agricultural products and contribute to cultural tourism.",
}

const advertiseMessage={
  // 'advertiseMessage.title':'METHOD OF CORPORATE COMMUNICATIONS TO SHAREHOLDERS',
  // 'advertiseMessage.href':'https://s1.eastbuy.com/ir/HKGSR01A-%23689349-v3-East_Buy_-_Paperless_regime_-_Website_notice.pdf',
  // 'advertiseMessage.desc':'Website Notice',
  // 'advertiseMessage.date':'2023-12-29 '
  'advertiseMessage.title':'WEBSITE NOTICE ',
  'advertiseMessage.desc':'METHOD OF CORPORATE COMMUNICATIONS TO SHAREHOLDERS',
  'advertiseMessage.content':[
    {
      desc:'The Company will transition to electronic communications with shareholders in 2024',
      info:[
       'The Company currently sends printed copies of corporate communications to its shareholders. As part of the Company’s initiatives to be more environmentally conscious, the Company will transition into electronic communications in the first quarter of 2024. Under this updated communication method, we will not distribute printed copies of corporate communications to shareholders by default.',
       'Before the adoption of this updated electronic communications method, the Company will send to the registered address of each shareholder kept with the Hong Kong Share Registrar an initial notification letter. This letter will detail the updated electronic communications method and include a method by which shareholders can notify/update the Company with its nominated functional email address and/or make a specific request for certain corporate communications to be delivered to them in printed form for a limited of time.',
      ]
    },
    {
      desc: 'Where can I find the Company’s corporate communications?',
      info:[
        'All shareholders can access and download digital copies of a corporate communication, including any initial notifications we issue, on the websites of the Company (ir.eastbuy.com) and the Hong Kong Exchanges and Clearing Limited (<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>). Both English and Chinese (where available) language versions of corporate communications will be made available on these websites, with the English language version being the official version of the document. '
      ]
    },
    {
      desc:'What are corporate communications?',
      info:[
        'Corporate communications” are documents issued or to be issued by the Company for the specific information or action of shareholders, and include, but is not limited to, interim and annual reports, notice of general meetings, proxy forms, circulars, and listing documents.'
      ]
    }
  ],
  'advertiseMessage20240118.desc':'ARRANGEMENT OF CORPORATE COMMUNICATIONS TO SHAREHOLDERS',
  'advertiseMessage20240118.content':[
    {
      desc:'',
      info:[
        '<div>As part of the Company’s efforts to be more environmentally responsible, we strongly encourage all shareholders to elect for electronic receipt of corporate communications from the Company going forward. </div>'
      ]
    },
    {
      desc:'Where can I find the Company’s corporate communications?',
      info:['<div>Consistent with the Company’s existing practice, we will not distribute printed copies of corporate communications to shareholders by default. </div>',
      '<div>All shareholders can access and download digital copies of a corporate communication on the websites of the Company (ir.eastbuy.com) and the Hong Kong Exchanges and Clearing Limited (<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>). Both English and Chinese (where available) language versions of corporate communications will be made available on these websites, with the English language version being the official version of the document. </div>'
      ]
    },
    {
      desc:'Can I still request printed copies of corporate communications?',
      info:[`<div>Whilst the Company does not encourage physical distribution of corporate communications, the Company acknowledges that certain shareholders may need a printed copy for specific reasons. The Company will accommodate, free of charge, specific written requests from individual shareholders for one printed copy of corporate communications.</div>`,
      `
      <table style='border-collapse: collapse;border: 1px solid #aaa;width:100%;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 1px 1px 1px 1px;vertical-align: text-top;'>For shareholders who wish to request a printed copy of corporate communications</td>
          <td style="padding: 1px 1px 1px 1pxx;vertical-align: text-top;">Once your request has been received and processed, corporate communications published during the requested duration will be mailed to your registered address kept with the Hong Kong Share Registrar (or a nominated mailing address where one is provided).</td>
          <td  style='border: 1px solid #3F3F3F;'>
            <div style='color:#000000;'>For registered holders:</div>
            <div>You may make this request by completing <span style="text-decoration: underline;">Option 3 of Reply Form for Registered Holders (accessed below).</span></div>
            <div>Each request will be valid for only <strong style="text-decoration: underline;">one year</strong> starting from the receipt date of your instructions.</div>
            <div style='color:#000000;'>For non-registered holders:</div>
            <div>You may make this request by completing <span style="text-decoration: underline;">Reply Form for Non-Registered Holders (accessed below).</span></div>
            <div>We ask that shareholders be conscious of the environmental impact of preparing and delivering printed corporate communications to you when making this request.</div>
          </td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'Where can I find the actionable corporate communications?',
      info:[`<div>Actionable corporate communications will also be made available on the websites of the Company (ir.eastbuy.com) and the Hong Kong Exchanges and Clearing Limited (<a style="color:#EB5B03;text-decoration: underline;" target="_blank" href="https://www.hkexnews.hk/">www.hkexnews.hk</a>). </div>`,
      `<div>Additionally, the Company will also send the actionable corporate communications to you individually, the Company  recommends you to provide your email address by scanning your personalized QR code printed on the Reply Form or you may sign and return the Reply Form to the Company’s Hong Kong Share Registrar, Computershare Hong Kong Investor Services Limited, at 17M Floor, Hopewell Centre, 183 Queen’s Road East, Wan Chai, Hong Kong.  </div>`,
      `<div>If the Company does not receive a functional email address in your reply, until such time that the functional email address is provided to the Hong Kong Share Registrar, the Company will send the actionable corporate communications in printed form in the future. </div>`
      ]
    },
    {
      desc:'How can I submit the Reply Form?',
      info:['<div> Shareholders should send their completed and signed “Reply Form for Registered Holders” or “Reply Form for Non-Registered Holders” (if you hold your shares in the Company through an Intermediary) to the Hong Kong Share Registrar by:</div>',
      `<table style='border-collapse: collapse;border: 1px solid #aaa;'>
        <tr style='border: 1px solid #3F3F3F;'>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>Hong Kong Share Registrar</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">Computershare Hong Kong Investor Services Limited</td>
        </tr>
        <tr>
          <td style='border: 1px solid #3F3F3F;padding: 9px 9px 9px 9px;vertical-align: text-top;'>Registered mail:</td>
          <td style="padding: 9px 9px 9px 9px;vertical-align: text-top;">17M Floor, Hopewell Centre, 183 Queen's Road East, Wan Chai, Hong Kong</td>
        </tr>
      </table>`
      ]
    },
    {
      desc:'Links to the Reply Form',
      info:[`<ul> 
            <li><a target="_blank" href='https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(RH)%20(combined)_ESS_%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Registered%20Holders.pdf'>Reply Form for Registered Holders</a></li>
            <li><a target="_blank" href="https://s1.eastbuy.com/ir/One%20time%20notification%20letter%20(NRH)%20(combined)_ESS_%E9%9D%9E%E7%99%BB%E8%AE%B0%E6%8C%81%E6%9C%89%E4%BA%BA%E5%9B%9E%E6%9D%A1_Reply%20Form%20for%20Non%20Registered%20Holders.pdf">Reply Form for Non-Registered Holders</a></li>
      </div>`
      ]
    },
    {
      desc:'Key terminology  ',
      info:[`<ul> 
        <li>“Corporate communications” are documents issued or to be issued by the Company for the specific information or action of shareholders, and include, but is not limited to, interim and annual reports, notice of general meetings, proxy forms, circulars, and listing documents.</li>
        <li>“Actionable corporate communications” are corporate communications that require a specific action from shareholders, and include, but is not limited to, shareholder election forms, application forms, and acceptance forms.</li>
      </ul>`
      ]
    }
  ]
}
const en_US = { ...global, ...header, ...home, ...business, ...employee, ...investor, ...contact, ...brief ,...advertiseMessage}
export default en_US